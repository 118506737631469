import { Navigate, useOutlet, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

import Header from '../components/Header'
import SideMenu from '../components/SideMenu'

export const ProtectedLayout = () => {
  const outlet = useOutlet()
  const location = useLocation()

  const { userData } = useAuth()

  if (!userData) {
    return <Navigate to="/SignIn" />
  }

  if (
    location.pathname === '/WelcomePage' ||
    location.pathname === '/StepsPage' ||
    location.pathname === '/LoginCompletion' ||
    location.pathname === '/FastPublishing1'
  ) {
    return <div style={{ height: '100%' }}>{outlet}</div>
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        boxSizing: 'border-box',
        padding: 0,
        margin: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          maxHeight: 'calc(100% - 70px)',
          boxSizing: 'border-box',
          padding: 0,
          margin: 0,
          flex: 1,
        }}
      >
        <SideMenu />

        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: 0,
            margin: 0,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              flexDirection: 'column',
              flex: 1,
              padding: 0,
              margin: 0,
            }}
          >
            <Header pathname={location.pathname} />

            <style>
              {`
                  ::-webkit-scrollbar {
                      width: 6px;
                  }

                  ::-webkit-scrollbar-thumb {
                      background-color: #bdbcbc; 
                      border-radius: 6px;

                  }
              `}
            </style>

            <div
              style={{
                display: 'flex',
                boxSizing: 'border-box',
                flexDirection: 'column',
                flex: 1,
                padding: 0,
                margin: 0,

                overflow: 'auto',
                maxHeight: 'calc(100vh - 80px)',
                marginRight: 2,
                // background: 'red',
              }}
            >
              {outlet}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
