import styles from './styles.module.css'
import { useState, useRef, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { useClient } from '../../hooks/useClient'

import StepFirstPass from './StepfirstPass'
import NewPostFormSecond from './StepSecondPass'
import StepThirdPass from './StepThirdPass'

import StepImageBank from './StepSecondPass/StepImageBank'
import StepFourthPass from './StepFourthPass'
import StepFinish from './StepFinish'

import StepPublicationSuccess from './StepPublicationSuccess'
import StepMyPublication from './StepMyPublication'
import StepNinche from './StepNinche'
import { motion } from 'framer-motion'
import { getBusinessByClientId } from '../../services/business'
import { BusinessService, SubscriptionService } from '../../services'

const STEPS = {
  STEP_FIRST_PASS: 'stepFirstPass',
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_THIRD_PASS_PUBLICATION: 'stepArts',
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',

  STEP_FINISH: 'stepFinish',
  STEP_PUBLICATION_SUCCESS: 'stepPublicationSuccess',

  STEP_IMAGE_BANK: 'stepImageBank',
  STEP_MY_PUBLICATION: 'stepMyPublication',
  STEP_NINCHE: 'stepNinche',
}

export default function NewPublicationPage() {
  const { clientData } = useClient()
  const location = useLocation()

  const [step, setStep] = useState(STEPS.STEP_FIRST_PASS)

  const editRef = useRef(false)
  const editDataRef = useRef(undefined)

  const productRef = useRef(undefined)
  const targetAudienceRef = useRef(undefined)
  const publicationDescriptionRef = useRef('')
  const funelOfSaleRef = useRef(undefined)
  const detailRef = useRef(undefined)

  const [imageGaleryArray, setImageGaleryArray] = useState([])
  const [imageArray, setImageArray] = useState([])
  const [imageAIArray, setImageAIArray] = useState([])
  const [imageUploadArray, setImageUploadArray] = useState([])
  const [imageProductsArray, setImageProductsArray] = useState([])
  const [hasSignature, setHasSignature] = useState(false)
  const [generateArtIa, setGenerateArtIa] = useState(false)

  const [iaData, setIaData] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ])
  const [qtdLoadTemplates, setQtdLoadTemplates] = useState(0)
  // const qtdTemplatesLimit = 6
  const qtdTemplatesLimit = 11

  const [imageUrl, setImageUrl] = useState('')
  const [imageLayers, setImageLayers] = useState([])
  const [headlineText, setHeadlineText] = useState('')

  const [headlineList, setHeadlineList] = useState([])
  const [legendList, setLegendList] = useState([])

  const [legend, setLegend] = useState(false)

  const [publicationDraft, setPublicationDraft] = useState({})
  const [draft, setDraft] = useState(false)

  const [repost, setRepost] = useState(false)
  const [schedule, setSchedule] = useState(false)
  const [nincheData, setNincheData] = useState({})

  const [templateId, setTemplateId] = useState('')

  const [userNiche, setUserNiche] = useState('')
  const [selectFunnel, setselectFunnel] = useState({})
  const [headlineGpt, setHeadlineGpt] = useState({})

  const publicationIdRef = useRef(undefined)
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (location?.state?.dashboad) {
      const gptData = JSON.parse(location?.state?.gptData)

      setLegend(gptData?.data?.data?.legend[location.state.indexModalSelected])

      setImageUrl(location?.state?.img)
      setImageLayers(location?.state?.layers)

      setStep(STEPS.STEP_FINISH)

      return
    }

    if (location?.state?.edit) {
      editRef.current = true
      editDataRef.current = location?.state?.dataPublication

      setImageUrl(
        'data:image/png;base64,' +
          location?.state?.dataPublication?.generateArtJson.srcComTratativa
      )

      setLegend(location?.state?.dataPublication?.legend)

      setStep(STEPS.STEP_FINISH)
      return
    }

    getUserNiche()
    productRef.current = undefined
    targetAudienceRef.current = undefined
    publicationDescriptionRef.current = ''
    funelOfSaleRef.current = undefined
    detailRef.current = undefined

    setStep(STEPS.STEP_FIRST_PASS)
  }, [clientData])

  useEffect(() => {
    handleCheckSubscription()
  }, [])

  const handleCheckSubscription = async () => {
    const subscription = await SubscriptionService.getSubscriptionByUser()

    if (subscription.data.length <= 0) {
      setHasSignature(false)
    } else {
      setHasSignature(true)
    }
  }
  const getUserNiche = async () => {
    let businessId

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.BusinessNiche?.name
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.BusinessNiche?.name
    }
    setUserNiche(businessId)
  }

  return (
    <div className={styles.page}>
      {step === STEPS.STEP_FIRST_PASS && (
        <motion.div
          // key={expanded}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%' }}
        >
          <StepFirstPass
            setStep={setStep}
            clientData={clientData}
            productRef={productRef}
            targetAudienceRef={targetAudienceRef}
            publicationDescriptionRef={publicationDescriptionRef}
            funelOfSaleRef={funelOfSaleRef}
            detailRef={detailRef}
            setselectFunnel={setselectFunnel}
          />
        </motion.div>
      )}

      {step === STEPS.STEP_IMAGE_BANK && (
        <motion.div
          // key={expanded}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%' }}
        >
          <StepImageBank setStep={setStep} setImageArray={setImageArray} />
        </motion.div>
      )}

      {step === STEPS.STEP_SECOND_PASS_IMAGE && (
        <motion.div
          // key={expanded}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%' }}
        >
          <NewPostFormSecond
            setStep={setStep}
            imageArray={imageArray}
            setImageArray={setImageArray}
            imageGaleryArray={imageGaleryArray}
            setImageGaleryArray={setImageGaleryArray}
            imageAIArray={imageAIArray}
            setImageAIArray={setImageAIArray}
            imageUploadArray={imageUploadArray}
            setImageUploadArray={setImageUploadArray}
            imageProductsArray={imageProductsArray}
            setImageProductsArray={setImageProductsArray}
            productRef={productRef}
            targetAudienceRef={targetAudienceRef}
            generateArtIa={generateArtIa}
            setGenerateArtIa={setGenerateArtIa}
          />
        </motion.div>
      )}

      {step === STEPS.STEP_THIRD_PASS_PUBLICATION && (
        <motion.div
          // key={expanded}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%' }}
        >
          <StepThirdPass
            setStep={setStep}
            iaData={iaData}
            setIaData={setIaData}
            qtdLoadTemplates={qtdLoadTemplates}
            setQtdLoadTemplates={setQtdLoadTemplates}
            qtdTemplatesLimit={qtdTemplatesLimit}
            clientData={clientData}
            productRef={productRef}
            targetAudienceRef={targetAudienceRef}
            detailRef={detailRef}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            imageLayers={imageLayers}
            setImageLayers={setImageLayers}
            setHeadlineText={setHeadlineText}
            headlineText={headlineText}
            imageArray={imageArray}
            imageGaleryArray={imageGaleryArray}
            imageAIArray={imageAIArray}
            imageUploadArray={imageUploadArray}
            imageProductsArray={imageProductsArray}
            setHeadlineList={setHeadlineList}
            headlineList={headlineList}
            setLegendList={setLegendList}
            templateId={templateId}
            //legendList={legendList}
            legendList={legendList}
            selectFunnel={selectFunnel}
            setHeadlineGpt={setHeadlineGpt}
            headlineGpt={headlineGpt}
            nincheData={nincheData}
            publicationDescriptionRef={publicationDescriptionRef}
            hasSignature={hasSignature}
            generateArtIa={generateArtIa}
            setGenerateArtIa={setGenerateArtIa}
          />
        </motion.div>
      )}

      {step === STEPS.STEP_FOURTH_PASS_SUBTITLE && (
        <motion.div
          // key={expanded}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%' }}
        >
          <StepFourthPass
            setStep={setStep}
            productRef={productRef}
            targetAudienceRef={targetAudienceRef}
            imageUrl={imageUrl}
            imageLayers={imageLayers}
            setImageUrl={setImageUrl}
            setHeadlineText={setHeadlineText}
            headlineText={headlineText}
            legend={legend}
            setLegend={setLegend}
            // dados da tela anterior
            draftData={publicationDraft}
            publicationRet={location.state?.dataPublication}
            draft={draft}
            repost={repost}
            schedule={schedule}
            setHeadlineList={setHeadlineList}
            headlineList={headlineList}
            setLegendList={setLegendList}
            legendList={legendList}
            selectFunnel={selectFunnel}
            headlineGpt={headlineGpt}
            setCurrentIndex={setCurrentIndex}
            publicationDescriptionRef={publicationDescriptionRef}
          />
        </motion.div>
      )}

      {step === STEPS.STEP_FINISH && (
        <StepFinish
          setStep={setStep}
          productRef={productRef}
          targetAudienceRef={targetAudienceRef}
          imageUrl={imageUrl}
          imageLayers={imageLayers}
          setImageUrl={setImageUrl}
          headlineText={headlineText}
          setHeadlineText={setHeadlineText}
          legend={legend}
          setLegend={setLegend}
          setHeadlineList={setHeadlineList}
          headlineList={headlineList}
          setLegendList={setLegendList}
          legendList={legendList}
          //dados da tela anterior
          draftModalData={publicationDraft}
          publicationIdRef={publicationIdRef}
          //
          editRef={editRef}
          editDataRef={editDataRef}
          currentIndex={currentIndex}
          dashParam={location?.state?.dashboad}
        />
      )}

      {step === STEPS.STEP_MY_PUBLICATION && (
        <StepMyPublication
          setStep={setStep}
          publicationIdRef={publicationIdRef}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
        />
      )}

      {step === STEPS.STEP_PUBLICATION_SUCCESS && (
        <StepPublicationSuccess
          imageUrl={imageUrl}
          publicationIdRef={publicationIdRef}
        />
      )}

      {step === STEPS.STEP_NINCHE && (
        <StepNinche
          setStep={setStep}
          setNincheData={setNincheData}
          userNiche={userNiche}
          productRef={productRef}
          setTemplateId={setTemplateId}
        />
      )}
    </div>
  )
}
