/* eslint-disable react/prop-types */
import { useState, useRef, useEffect } from 'react'
import styles from './styles.module.css'
import { AnimatePresence, motion } from 'framer-motion'

import { MultipleFontUploadDrag } from '../../components/MultipleFontUploadDrag'
import { HexColorPicker } from 'react-colorful'
import {
  More,
  InterrogationCircleIcon,
  Cross,
  PasteIcon,
  BoxChecket,
  BoxNotChecket,
  Trash,
  NavigateArrowLeft,
  NavigateArrowRigth2,
  Pen2,
  AttentionIcon,
} from '../../assets/svgs/icons'
import {
  BrandingService,
  FontService,
  BusinessService,
  BucketUploadService,
} from '../../services'
import { isEmpty } from '../../utils/verifyObject'
import { notifyErrorCustom } from '../../utils/notify'
import { CustomSelect } from '../../components/Select/CustomSelect'
import { ButtonNavigateStep } from '../../components/ButtonNavigateStep'
import { getAllExternalFonts } from '../../services/fontsService'
import { Loading } from '../../components/Loading'
import WebFont from 'webfontloader' 
import { useTranslation } from 'react-i18next'
const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepBranding({ setStep, clientData }) {
  const { t } = useTranslation()
  const [primaryColor, setPrimaryColor] = useState(undefined)
  const [secondaryColor, setSecondaryColor] = useState(undefined)
  const [tertiaryColor, setTertiaryColor] = useState(undefined)
  const [complementaryColor1, setComplementaryColor1] = useState(undefined)
  const [complementaryColor2, setComplementaryColor2] = useState(undefined)

  const [colorOrderEnabled, setColorOrderEnabled] = useState(undefined)
  const [fontUpload, setFontUpload] = useState(false)
  // const [colorsToCompare, setColorsToCompare] = useState({})
  const [fontTypeOptions, setFontTypeOptions] = useState([])
  const [fontsOptions, setFontsOptions] = useState([])
  const [filesSelectedArray, setFilesSelectedArray] = useState([])
  const [selectedFontType, setSelectedFontType] = useState({})
  const [selectedFontItem, setSelectedFontItem] = useState({})
  const [selectedFont, setSelectedFont] = useState(undefined)
  const [fontsDataReference, setFontsDataReference] = useState([])
  // const [fontsToUploadOnBranding, setFontsToUploadOnBranding] = useState({})
  const [dataToCompare, setDataToCompare] = useState([])
  const [allUploaddFonts, setAllUploaddFonts] = useState([])
  const [loading, setLoading] = useState(false)

  const temColorRef = useRef(null)
  const colorPickerRef = useRef(null)
  const textRef = useRef(null)
  const variant = 'column'

  const businessId = useRef(undefined)

  // const controls = useAnimationControls()

  const renderSelectedFontOnDocumentLoad = (e) => {
    //crie uma instancia da fonte
    const fontInstance = new FontFace(
      e.name
        .replace(/\.ttf$/, '')
        .replace(/\.otf$/, '')
        .replace(/\.woff$/, ''),
      `url(data:font/woff;base64,${e.fileDataBase64})`
    )

    if (textRef.current) {
      const fontText = textRef.current
      fontText.style.fontFamily = fontInstance.family // Use o nome da família da fonte
    }

    // Carregue a fonte
    // fontInstance.load().then(function (loadedFont) {
    //   // Adicione a fonte ao documento
    //   document.fonts.add(loadedFont)

    //   if (textRef.current) {
    //     const fontText = textRef.current
    //     fontText.style.fontFamily = loadedFont.family // Use o nome da família da fonte

    //   }

    // })
  }

  const renderSelectedFontOnDocument = () => {
    //crie uma instancia da fonte
    const fontInstance = new FontFace(
      selectedFont.name
        .replace(/\.ttf$/, '')
        .replace(/\.otf$/, '')
        .replace(/\.woff$/, ''),
      `url(data:font/woff;base64,${selectedFont.fileDataBase64})`
    )

    if (textRef.current) {
      const fontText = textRef.current
      fontText.style.fontFamily = fontInstance.family // Use o nome da família da fonte
    }

    // Carregue a fonte
    // fontInstance.load().then(function (loadedFont) {
    //   // Adicione a fonte ao documento
    //   document.fonts.add(loadedFont)

    //   if (textRef.current) {
    //     const fontText = textRef.current
    //     fontText.style.fontFamily = loadedFont.family // Use o nome da família da fonte

    //   }

    // })
  }

  const handleClickSetFont = () => {
    const aux = filesSelectedArray
    if (aux.length >= 3 || !selectedFont) return
    aux.push({ ...selectedFont })

    const fontFamilies = aux
      // .filter((font) => !font.isSystemFont)
      .map((font) => font.name)

    if (fontFamilies.length > 0) {
      WebFont.load({
        google: {
          families: fontFamilies,
        },
      })
    }
    setFilesSelectedArray([...aux])

    renderSelectedFontOnDocument()

    const filteredTypeOptions = fontTypeOptions.filter(
      (e) => e.value != selectedFontType.value
    )
    setFontTypeOptions(filteredTypeOptions)

    setSelectedFont(undefined)
    setSelectedFontType(undefined)
    setSelectedFontItem(undefined)
    setFontUpload(false)
  }

  const handleChangeSelectFontType = (e) => {
    setSelectedFontType(e)
  }

  const handleChangeSelectFont = (e) => {
    setSelectedFontItem(e)
    const { label, value } = e
    setSelectedFont({
      fileDataBase64: value,
      isSystemFont: true,
      name: label,
      selectedFontType,
    })
  }

  useEffect(() => {
    const fontFamilies = filesSelectedArray
      // .filter((font) => !font.isSystemFont)
      .map((font) => font.name)

    if (fontFamilies.length > 0) {
      WebFont.load({
        google: {
          families: fontFamilies,
        },
      })
    }
  }, [filesSelectedArray])

  const onFileChange = (e) => {
    Array.from(e.target.files).forEach((file) => {
      const validFontFormats = ['font/ttf', 'font/otf', 'font/woff']
      let fileType = ''

      if (file.name.endsWith('.ttf')) {
        fileType = 'font/ttf'
      } else if (file.name.endsWith('.otf')) {
        fileType = 'font/otf'
      } else if (file.name.endsWith('.woff')) {
        fileType = 'font/woff'
      }

      if (validFontFormats.includes(fileType)) {
        const reader = new FileReader()
        reader.onload = () => {
          // Remove o cabeçalho "data:application/font-xxx;base64,"
          const fileDataBase64 = reader.result.split(',')[1]
          const { name } = file
          setSelectedFont({
            fileDataBase64,
            isSystemFont: false,
            selectedFontType,
            name,
          })
        }
        reader.readAsDataURL(file)
      } else {
        notifyErrorCustom(`Arquivo ${file.name} não é uma fonte válida.`)
      }
    })
  }

  const handleRemoveFile = (font, index) => {
    //devolve a opção de tipo ao array de opções de tipo
    // setFontTypeOptions([...fontTypeOptions, filesSelectedArray[index].selectedFontType])
    const aux = fontTypeOptions
    aux.push(font.selectedFontType)
    setFontTypeOptions(aux)

    // if (filesSelectedArray[index].isSystemFont) {
    //   const fontObjToRecovery = {
    //     label: filesSelectedArray[index].name,
    //     value: filesSelectedArray[index].fileDataBase64
    //   }

    //   setFontsOptions([...fontsOptions, fontObjToRecovery])
    // }

    filesSelectedArray.splice(index, 1)
    setFilesSelectedArray((current) => [...current])
  }

  const formatFonts = async (fonts) => {
    let fontsUploadObj = {}

    fontsUploadObj['fontSubtitleId'] = ''
    fontsUploadObj['fontSubtitleByUploaded'] = false
    fontsUploadObj['fontTitleId'] = ''
    fontsUploadObj['fontTitleByUploaded'] = false
    fontsUploadObj['fontTextId'] = ''
    fontsUploadObj['fontTextByUploaded'] = false

    for (const fonte of fonts) {
      const objToGetId = {
        name: fonte.name,
        data: fonte.fileDataBase64,
      }
      const { data } = await FontService.postExternalFontsGoogle(objToGetId)

      if (fonte.selectedFontType.value == 2) {
        fontsUploadObj['fontSubtitleId'] = data.uuid
        fontsUploadObj['fontSubtitleByUploaded'] = false
      }

      if (fonte.selectedFontType.value == 1) {
        fontsUploadObj['fontTitleId'] = data.uuid
        fontsUploadObj['fontTitleByUploaded'] = false
      }

      if (fonte.selectedFontType.value == 3) {
        fontsUploadObj['fontTextId'] = data.uuid
        fontsUploadObj['fontTextByUploaded'] = false
      }
    }

    // if (selectedFontItem) {

    //   const objToGetId = {
    //     name: selectedFontItem.value,
    //     data: selectedFontItem.value,
    //   }
    //   const { data } = await FontService.postExternalFontsGoogle(objToGetId)

    //   if (selectedFontType.value == 2) {
    //     fontsUploadObj['fontSubtitleId'] = data.uuid
    //     fontsUploadObj['fontSubtitleByUploaded'] = false
    //   }

    //   if (selectedFontType.value == 1) {
    //     fontsUploadObj['fontTitleId'] = data.uuid
    //     fontsUploadObj['fontTitleByUploaded'] = false
    //   }

    //   if (selectedFontType.value == 3) {
    //     fontsUploadObj['fontTextId'] = data.uuid
    //     fontsUploadObj['fontTextByUploaded'] = false
    //   }

    // }
    // await Promise.all(
    //   fonts.map(async (font) => {
    //     if (!font.isSystemFont) {
    //       const objToGetId = {
    //         name: font.name,
    //         data: font.fileDataBase64,
    //       }

    //       if (dataToCompare.length > 0) {
    //         if (font.selectedFontType.label === 'Título') {
    //           if (
    //             allUploaddFonts.some(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //           ) {
    //             const existentFontToUpload = allUploaddFonts.find(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //             fontsUploadObj['fontTitleId'] = existentFontToUpload.uuid
    //             fontsUploadObj['fontTitleByUploaded'] = true
    //           } else {
    //             const { data } = await FontService.postExternalFonts(objToGetId)
    //             fontsUploadObj['fontTitleId'] = data.uuid
    //             fontsUploadObj['fontTitleByUploaded'] = true
    //           }
    //         }

    //         if (font.selectedFontType.label === 'Subtítulo') {
    //           if (
    //             allUploaddFonts.some(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //           ) {
    //             const existentFontToUpload = allUploaddFonts.find(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //             fontsUploadObj['fontSubtitleId'] = existentFontToUpload.uuid
    //             fontsUploadObj['fontSubtitleByUploaded'] = true
    //           } else {
    //             const { data } = await FontService.postExternalFonts(objToGetId)
    //             fontsUploadObj['fontSubtitleId'] = data.uuid
    //             fontsUploadObj['fontSubtitleByUploaded'] = true
    //           }
    //         }

    //         if (font.selectedFontType.label === 'Textos') {
    //           if (
    //             allUploaddFonts.some(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //           ) {
    //             const existentFontToUpload = allUploaddFonts.find(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //             fontsUploadObj['fontTextId'] = existentFontToUpload.uuid
    //             fontsUploadObj['fontTextByUploaded'] = true
    //           } else {
    //             const { data } = await FontService.postExternalFonts(objToGetId)
    //             fontsUploadObj['fontTextId'] = data.uuid
    //             fontsUploadObj['fontTextByUploaded'] = true
    //           }
    //         }
    //       } else {
    //         if (font.selectedFontType.label === 'Título') {
    //           if (
    //             allUploaddFonts.some(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //           ) {
    //             const existentFontToUpload = allUploaddFonts.find(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //             fontsUploadObj['fontTitleId'] = existentFontToUpload.uuid
    //             fontsUploadObj['fontTitleByUploaded'] = true
    //           } else {
    //             const { data } = await FontService.postExternalFonts(objToGetId)
    //             fontsUploadObj['fontTitleId'] = data?.uuid
    //             fontsUploadObj['fontTitleByUploaded'] = true
    //           }
    //         }
    //         if (font.selectedFontType.label === 'Subtítulo') {
    //           if (
    //             allUploaddFonts.some(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //           ) {
    //             const existentFontToUpload = allUploaddFonts.find(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //             fontsUploadObj['fontSubtitleId'] = existentFontToUpload.uuid
    //             fontsUploadObj['fontSubtitleByUploaded'] = true
    //           } else {
    //             const { data } = await FontService.postExternalFonts(objToGetId)
    //             fontsUploadObj['fontSubtitleId'] = data?.uuid
    //             fontsUploadObj['fontSubtitleByUploaded'] = true
    //           }
    //         }
    //         if (font.selectedFontType.label === 'Textos') {
    //           if (
    //             allUploaddFonts.some(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //           ) {
    //             const existentFontToUpload = allUploaddFonts.find(
    //               (e) =>
    //                 e.data === objToGetId.data && e.name === objToGetId.name
    //             )
    //             fontsUploadObj['fontTextId'] = existentFontToUpload.uuid
    //             fontsUploadObj['fontTextByUploaded'] = true
    //           } else {
    //             const { data } = await FontService.postExternalFonts(objToGetId)
    //             fontsUploadObj['fontTextId'] = data?.uuid
    //             fontsUploadObj['fontTextByUploaded'] = true
    //           }
    //         }
    //       }
    //     } else {
    //       const fontReference = fontsDataReference.find(
    //         (e) => e.data == font.fileDataBase64
    //       )
    //       if (font.selectedFontType.label === 'Título') {
    //         fontsUploadObj['fontTitleId'] = fontReference.uuid
    //         fontsUploadObj['fontTitleByUploaded'] = false
    //       }
    //       if (font.selectedFontType.label === 'Subtítulo') {
    //         fontsUploadObj['fontSubtitleId'] = fontReference.uuid
    //         fontsUploadObj['fontSubtitleByUploaded'] = false
    //       }
    //       if (font.selectedFontType.label === 'Textos') {
    //         fontsUploadObj['fontTextId'] = fontReference.uuid
    //         fontsUploadObj['fontTextByUploaded'] = false
    //       }
    //     }
    //   })
    // )

    return fontsUploadObj
  }

  const handleSubmit = async () => {
    if (filesSelectedArray.length < 1) {
      notifyErrorCustom(t("step_branding.add_font_error"))
      return
    }
    let colors = {
      primaryColor,
      secondaryColor,
      tertiaryColor,
      complementaryColor1,
      complementaryColor2,
    }
    // const fontsToUpdate = await formatFonts(filesSelectedArray)
    const fontsToUpdate = await formatFonts(filesSelectedArray)

    // Verifique se existe valores vazios e salve como string caso nao exista
    if (!complementaryColor1) colors.complementaryColor1 = ''

    if (!complementaryColor2) colors.complementaryColor2 = ''

    // if (!fontsToUpdate.fontSubtitleId) {
    //   fontsToUpdate.fontSubtitleId = ''
    //   fontsToUpdate.fontSubtitleByUploaded = false
    // }

    // if (!fontsToUpdate.fontTitleId) {
    //   fontsToUpdate.fontTitleId = ''
    //   fontsToUpdate.fontTitleByUploaded = false
    // }

    // if (!fontsToUpdate.fontSubtitleId) {
    //   fontsToUpdate.fontSubtitleId = ''
    //   fontsToUpdate.fontSubtitleByUploaded = false
    // }

    // if (!fontsToUpdate.fontTextId) {
    //   fontsToUpdate.fontTextId = ''
    //   fontsToUpdate.fontTextByUploaded = false
    // }

    const dataToSubmit = {
      ...colors,
      ...fontsToUpdate,
    }

    let canSubmit = primaryColor && secondaryColor && tertiaryColor

    // verifique se as cores obrigatorias não estão preenchidas e retorne
    if (!canSubmit) {
      notifyErrorCustom(t("step_branding.choose_main_colors"))
      return
    }

    if (dataToCompare && dataToCompare[0]?.uuid) {
      const { responseStatus } = await BrandingService.putBranding(
        dataToCompare[0].uuid,
        dataToSubmit
      )
      if (responseStatus !== 204 && responseStatus !== 200) {
        notifyErrorCustom('erro ao editar')
      }
    } else {
      if (clientData?.uuid) {
        await BrandingService.postBrandingByBusinessId({
          ...dataToSubmit,
          businessId: businessId.current,
        })
      } else {
        await BrandingService.postBranding(dataToSubmit)
      }
    }

    setStep(STEPS.STEP_LOGOS)
  }

  useEffect(() => {
    loadBrandingData()
    //loadFonts()
  }, [])

  useEffect(() => {
    if (dataToCompare) loadFonts(dataToCompare[0])
  }, [dataToCompare])

  const loadFontsSelectedBefore = (data, typeOptions) => {
    const auxArray = []
    if (typeOptions.length != 0) {
      if (
        data?.fontsBase64?.fontTextBase64 &&
        typeOptions.find((e) => e.label === 'Textos')
      ) {
        const fontObj = {
          name: data?.fontsBase64?.fontTextBase64?.name,
          fileDataBase64: data?.fontsBase64?.fontTextBase64?.data,
          isSystemFont: !data?.fontsBase64?.fontTextBase64?.uploaded,
          selectedFontType: typeOptions.find((e) => e.label === 'Textos'),
        }

        auxArray.push(fontObj)
      }
      if (
        data?.fontsBase64?.fontTitleBase64 &&
        typeOptions.find((e) => e.label === 'Título')
      ) {
        const fontObj = {
          name: data?.fontsBase64?.fontTitleBase64?.name,
          fileDataBase64: data?.fontsBase64?.fontTitleBase64?.data,
          isSystemFont: !data?.fontsBase64?.fontTitleBase64?.uploaded,
          selectedFontType: typeOptions.find((e) => e.label === 'Título'),
        }
        auxArray.push(fontObj)
      }
      if (
        data?.fontsBase64?.fontSubtitleBase64 &&
        typeOptions.find((e) => e.label === 'Subtítulo')
      ) {
        const fontObj = {
          name: data?.fontsBase64?.fontSubtitleBase64?.name,
          fileDataBase64: data?.fontsBase64?.fontSubtitleBase64?.data,
          isSystemFont: !data?.fontsBase64?.fontSubtitleBase64?.uploaded,
          selectedFontType: typeOptions.find((e) => e.label === 'Subtítulo'),
        }

        auxArray.push(fontObj)
      }
    }
    setLoading(false)
    return auxArray
  }

  const loadFonts = async (brandingData) => {
    if (brandingData) {
      setLoading(true)
    }

    let googleFontsBucket = await BucketUploadService.getGoogleFontsBucket()

    setFontsOptions(
      googleFontsBucket.data.map((e) => ({ label: e.name, value: e.name }))
    )

    const { data: typefontsData, responseStatus: typeFontsResponseStatus } =
      await FontService.getFontTypes()

    const { data: fontsData, responseStatus: fontsResponseStatus } =
      await FontService.getAllFonts()

    if (typeFontsResponseStatus === 200) {
      const options = typefontsData.map((e) => ({ label: e.type, value: e.id }))
      if (dataToCompare.length > 0) {
        const fonstsSelectedUpdatedsArray = loadFontsSelectedBefore(
          brandingData,
          options
        )

        // Carregue todas as fontes ja upadas anteriormente
        const { data } = await getAllExternalFonts()
        if (data.length > 0) setAllUploaddFonts(data)

        setFilesSelectedArray(fonstsSelectedUpdatedsArray)

        const usedTypeFonts = fonstsSelectedUpdatedsArray.map(
          (e) => e.selectedFontType
        )

        await fonstsSelectedUpdatedsArray.map((e) => {
          //(e) => e.selectedFontType

          // e.selectedFontType
          renderSelectedFontOnDocumentLoad(e)
        })

        // Verifique quais tipos de fonte foram selecionadas
        usedTypeFonts.forEach((objetoA) => {
          options.forEach((objetoB, indexObjB) => {
            if (objetoA.label === objetoB.label) {
              options.splice(indexObjB, 1)
            }
          })
        })

        setFontTypeOptions(options)
      } else {
        setFontTypeOptions(options)
      }
    }

    if (fontsResponseStatus === 200) {
      // setFontsOptions(fontsData.map((e) => ({ label: e.name, value: e.data })))
      setFontsDataReference(fontsData)
    }
    //setLoading(false)
  }

  const loadBrandingData = async () => {
    //setLoading(true)
    if (clientData?.uuid) {
      let businessByCampaignReturn =
        await BusinessService.getBusinessByClientId(clientData?.uuid)

      businessByCampaignReturn = businessByCampaignReturn?.data[0]

      businessId.current = businessByCampaignReturn?.uuid

      const { data } = await BrandingService.getAllBrandingByBusinessId(
        businessByCampaignReturn?.uuid
      )
      setDataToCompare(data)

      if (data.length > 0) {
        setPrimaryColor(data[0].primaryColor)
        setSecondaryColor(data[0].secondaryColor)
        setTertiaryColor(data[0].tertiaryColor)
        setComplementaryColor1(data[0].complementaryColor1)
        setComplementaryColor2(data[0].complementaryColor2)
      }
    } else {
      const { data } = await BrandingService.getAllBrandingByBusiness()

      setDataToCompare(data)

      if (data) {
        setPrimaryColor(data[0].primaryColor)
        setSecondaryColor(data[0].secondaryColor)
        setTertiaryColor(data[0].tertiaryColor)
        setComplementaryColor1(data[0].complementaryColor1)
        setComplementaryColor2(data[0].complementaryColor2)
      }
    }
  }

  useEffect(() => {
    function handleOutsideClick(event) {
      // Se o dropdown estiver aberto E o clique foi fora do dropdown
      if (
        colorOrderEnabled &&
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        handleColor()
        setColorOrderEnabled(undefined)
      }
    }

    // Adicionando o event listener
    document.addEventListener('mousedown', handleOutsideClick)

    // Limpeza: removendo o event listener quando o componente é desmontado
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [colorOrderEnabled])

  const handleColor = () => {
    if (temColorRef?.current?.order === 1)
      setPrimaryColor(temColorRef?.current?.color)
    if (temColorRef?.current?.order === 2)
      setSecondaryColor(temColorRef?.current?.color)
    if (temColorRef?.current?.order === 3)
      setTertiaryColor(temColorRef?.current?.color)
    if (temColorRef?.current?.order === 4)
      setComplementaryColor1(temColorRef?.current?.color)
    if (temColorRef?.current?.order === 5)
      setComplementaryColor2(temColorRef?.current?.color)
  }

  // eslint-disable-next-line no-unused-vars
  const ColorUnselectedComponent = ({
    fieldName,
    fieldDescription,
    color,
    setColor,
    left,
    right,
    order,
  }) => {
    let verifyEnablePickColor = false
    if (order === colorOrderEnabled) verifyEnablePickColor = true
    const [enablePickColor, setEnablePickColor] = useState(
      verifyEnablePickColor
    )
    const [tempColor, setTempColor] = useState(color)

    const [isHovered, setIsHovered] = useState(false)

    const [visibleTextCopy, setVisibleTextCopy] = useState(false)

    let borderRadius = undefined
    if (left) borderRadius = '10px 0 0 10px'
    if (right) borderRadius = '0 10px 10px 0'

    function isValidHexColor(hex) {
      return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)
    }

    function hexToRgb(hex) {
      let bigint = parseInt(hex.substring(1), 16)
      let r = (bigint >> 16) & 255
      let g = (bigint >> 8) & 255
      let b = bigint & 255

      return [r, g, b]
    }

    function getLuminance(r, g, b) {
      return (0.299 * r + 0.587 * g + 0.114 * b) / 255
    }

    function getTextColor(hexColor) {
      const [r, g, b] = hexToRgb(hexColor)
      return getLuminance(r, g, b) > 0.5 ? 'black' : 'white'
    }

    const MoveColorRight = (selectedItem) => {
      if (selectedItem === 1) {
        setPrimaryColor(secondaryColor)
        setSecondaryColor(primaryColor)
      }

      if (selectedItem === 2) {
        setSecondaryColor(tertiaryColor)
        setTertiaryColor(secondaryColor)
      }

      if (selectedItem === 3) {
        setTertiaryColor(complementaryColor1)
        setComplementaryColor1(tertiaryColor)
      }

      if (selectedItem === 4) {
        setComplementaryColor1(complementaryColor2)
        setComplementaryColor2(complementaryColor1)
      }
    }

    const MoveColorLeft = (selectedItem) => {
      if (selectedItem === 2) {
        setPrimaryColor(secondaryColor)
        setSecondaryColor(primaryColor)
      }

      if (selectedItem === 3) {
        setSecondaryColor(tertiaryColor)
        setTertiaryColor(secondaryColor)
      }

      if (selectedItem === 4) {
        setTertiaryColor(complementaryColor1)
        setComplementaryColor1(tertiaryColor)
      }

      if (selectedItem === 5) {
        setComplementaryColor1(complementaryColor2)
        setComplementaryColor2(complementaryColor1)
      }
    }

    const textCopy = (tempColor) => {
      navigator.clipboard
        .writeText(tempColor)
        .then(() => {
          // Mostrar mensagem
          setVisibleTextCopy(true)

          // Esconder mensagem após 2 segundos
          setTimeout(() => {
            setVisibleTextCopy(false)
          }, 1000)
        })
        .catch((err) => {
          console.error('Erro ao copiar texto: ', err)
        })
    }

    if (!enablePickColor && color)
      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: tempColor,
            borderRadius: borderRadius,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                color: getTextColor(tempColor ? tempColor : '#fff'),
                fontSize: 10,
                marginTop: 10,
                fontWeight: isHovered ? 700 : 300,
              }}
            >
              {fieldName}
            </p>
            {isHovered && (
              <p
                style={{
                  color: getTextColor(tempColor ? tempColor : '#fff'),
                  fontSize: 10,
                  textAlign: 'center',
                }}
              >
                {fieldDescription}
              </p>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {isHovered && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginBottom: 8,
                }}
                onClick={() => {
                  setColor(undefined)
                  setColorOrderEnabled(undefined)
                }}
              >
                <Trash
                  color={getTextColor(tempColor ? tempColor : '#fff')}
                  width={18}
                  height={18}
                />
              </div>
            )}

            <div style={{ display: 'flex', gap: 14 }}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  MoveColorLeft(order)
                }}
              >
                <NavigateArrowLeft
                  color={getTextColor(tempColor ? tempColor : '#fff')}
                  width={12}
                  height={12}
                />
              </div>

              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  MoveColorRight(order)
                }}
              >
                <NavigateArrowRigth2
                  color={getTextColor(tempColor ? tempColor : '#fff')}
                  width={12}
                  height={12}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <p
                style={{
                  color: getTextColor(tempColor ? tempColor : '#fff'),
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {color}
              </p>

              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleColor()
                  setColorOrderEnabled(order)
                }}
              >
                <Pen2
                  width={12}
                  height={12}
                  color={getTextColor(tempColor ? tempColor : '#fff')}
                />
              </div>
            </div>
          </div>
        </div>
      )

    return (
      <div
        style={{
          height: '100%',
          borderRadius: borderRadius,
          background: tempColor,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                color: getTextColor(tempColor ? tempColor : '#fff'),
                fontSize: 10,
                marginTop: 10,
              }}
            >
              {fieldName}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            {enablePickColor && (
              <>
                <div
                  style={{
                    padding: '0px 14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 8,
                      color: getTextColor(tempColor ? tempColor : '#fff'),
                      fontWeight: 700,
                    }}
                  >
                    Selecione sua cor
                  </p>

                  <div
                    style={{
                      cursor: 'pointer',
                      paddingBottom: 4,
                    }}
                    onClick={() => {
                      if (isValidHexColor(tempColor)) {
                        setColor(tempColor)
                        setEnablePickColor((current) => !current)
                        setColorOrderEnabled(undefined)
                      }

                      setEnablePickColor((current) => !current)
                      setColorOrderEnabled(undefined)
                    }}
                  >
                    <Cross
                      color={getTextColor(tempColor ? tempColor : '#fff')}
                      width={6}
                      height={6}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', flex: 1 }}>
                  <div className="color_picker">
                    <HexColorPicker
                      color={tempColor ? tempColor : '#FFF'}
                      onChange={(event) => {
                        setTempColor(event)
                        temColorRef.current = { color: event, order }
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    padding: '0px 14px',
                    marginTop: 4,
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 8,
                      color: getTextColor(tempColor ? tempColor : '#fff'),
                      fontWeight: 700,
                    }}
                  >
                    Hex
                  </p>

                  <div
                    style={{
                      height: 10,
                      width: 10,
                      background: tempColor ? tempColor : '#FFF',
                      borderRadius: 1,
                    }}
                  />

                  <p
                    style={{
                      margin: 0,
                      fontSize: 8,
                      color: getTextColor(tempColor ? tempColor : '#fff'),
                    }}
                  >
                    {tempColor ? tempColor : '#FFF'}
                  </p>

                  <div
                    style={{
                      border: `1px solid ${getTextColor(
                        tempColor ? tempColor : '#fff'
                      )}`,
                      height: 14,
                      width: 14,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 1,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      textCopy(tempColor)
                    }}
                  >
                    <PasteIcon
                      color={getTextColor(tempColor ? tempColor : '#fff')}
                      width={12}
                      height={12}
                    />
                  </div>
                </div>
              </>
            )}

            {visibleTextCopy && (
              <div style={{ height: 0 }}>
                <p
                  style={{
                    fontSize: 12,
                    color: getTextColor(tempColor ? tempColor : '#fff'),
                    margin: 0,
                    textAlign: 'center',
                  }}
                >
                  Copiado!
                </p>
              </div>
            )}

            {enablePickColor && (
              <div
                style={{
                  display: 'flex',
                  marginTop: 24,
                  marginBottom: 20,
                  justifyContent: 'center',
                }}
              >
                <input
                  style={{
                    background: tempColor ? tempColor : '#190027',
                    color: getTextColor(tempColor ? tempColor : '#fff'),
                    border: `1px solid ${getTextColor(
                      tempColor ? tempColor : '#fff'
                    )}`,
                    borderRadius: 2,
                    width: 80,
                    fontSize: 14,
                    fontWeight: 600,
                    textAlign: 'center',
                  }}
                  value={tempColor ? tempColor : '#'}
                  onChange={(event) => {
                    if (event.target.value === '') return
                    return setTempColor(event.target.value)
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      if (isValidHexColor(tempColor)) {
                        setColor(tempColor)
                        setEnablePickColor((current) => !current)
                        setColorOrderEnabled(undefined)
                      }

                      setEnablePickColor((current) => !current)
                      setColorOrderEnabled(undefined)
                    }
                  }}
                />
              </div>
            )}

            {!enablePickColor && !color && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 44,
                    height: 44,
                    borderRadius: 8,
                    background: '#470063',
                    cursor: 'pointer',
                    marginBottom: 30,
                  }}
                  onClick={() => {
                    temColorRef.current = undefined
                    handleColor()
                    setColorOrderEnabled(order)
                  }}
                >
                  <More />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const RenderColorsPicker = () => {
    return (
      <div
        ref={colorPickerRef}
        style={{
          display: 'flex',
          height: 300,
          width: '100%',
          borderRadius: 10,
        }}
      >
        <Loading enable={loading} />
        <div
          style={{
            width: '100%',
            height: '100%',
            minWidth: 140,
            border: primaryColor ? '1px solid #B900FE' : '1px solid #B900FE',
            borderStyle: primaryColor ? 'solid' : 'dashed',
            borderRadius: '10px 0 0 10px',
          }}
        >
          <ColorUnselectedComponent
            fieldName={'COR PRIMÁRIA'}
            fieldDescription={
              'AS CORES PRIMÁRIAS SÃO TONALIDADES PURAS, O QUE QUER DIZER QUE ELAS EXISTEM SEM A NECESSIDADE DE MISTURAR OUTRAS CORES.'
            }
            color={primaryColor}
            setColor={setPrimaryColor}
            left={true}
            order={1}
          />
        </div>

        <div
          style={{
            width: '100%',
            height: '100%',
            border: secondaryColor ? '1px solid #B900FE' : '1px solid #B900FE',
            borderStyle: secondaryColor ? 'solid' : 'dashed',
            minWidth: 140,
          }}
        >
          <ColorUnselectedComponent
            fieldName={'COR SECUNDÁRIA'}
            fieldDescription={
              'SÃO COMBINAÇÕES DE CORES CRIADAS PELA MISTURA IGUAL DE DUAS CORES PRIMÁRIAS'
            }
            color={secondaryColor}
            setColor={setSecondaryColor}
            order={2}
          />
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            border: tertiaryColor ? '1px solid #B900FE' : '1px solid #B900FE',
            borderStyle: tertiaryColor ? 'solid' : 'dashed',
            minWidth: 140,
          }}
        >
          <ColorUnselectedComponent
            fieldName={'COR TERCIÁRIA'}
            fieldDescription={
              'A COMBINAÇÃO DE CORES PRIMÁRIAS E SECUNDÁRIAS É CONHECIDA COMO CORES TERCIÁRIAS OU INTERMEDIÁRIAS, EM RAZÃO DE SUA NATUREZA COMPOSTA'
            }
            color={tertiaryColor}
            setColor={setTertiaryColor}
            order={3}
          />
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            border: complementaryColor1
              ? '1px solid #B900FE'
              : '1px solid #B900FE',
            borderStyle: complementaryColor1 ? 'solid' : 'dashed',
            minWidth: 140,
          }}
        >
          <ColorUnselectedComponent
            fieldName={'COR COMPLEMENTAR'}
            fieldDescription={
              'AS CORES COMPLEMENTARES SÃO PARES DE CORES QUE ESTÃO POSICIONADAS EM LADOS OPOSTOS DO CIRCULO CROMÁTICO, OU SEJA, SÃO CORES QUE SE CONTRASTAM E SE COMPLETAM, GERANDO UM EFEITO VISUAL INTENSO E VIBRANTE'
            }
            color={complementaryColor1}
            setColor={setComplementaryColor1}
            order={4}
          />
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            border: complementaryColor2
              ? '1px solid #B900FE'
              : '1px solid #B900FE',
            borderStyle: complementaryColor2 ? 'solid' : 'dashed',
            minWidth: 140,
            borderRadius: '0 10px 10px 0',
          }}
        >
          <ColorUnselectedComponent
            fieldName={'COR COMPLEMENTAR'}
            fieldDescription={
              'AS CORES COMPLEMENTARES SÃO PARES DE CORES QUE ESTÃO POSICIONADAS EM LADOS OPOSTOS DO CIRCULO CROMÁTICO, OU SEJA, SÃO CORES QUE SE CONTRASTAM E SE COMPLETAM, GERANDO UM EFEITO VISUAL INTENSO E VIBRANTE'
            }
            color={complementaryColor2}
            setColor={setComplementaryColor2}
            right={true}
            order={5}
          />
        </div>
      </div>
    )
  }

  const handleChecked = () => {
    setFontUpload(!fontUpload)
  }

  return (
    <div className={styles.page}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>{t("step_branding.title")}</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>

      <p className={styles.subtitle_text}>
        {t("step_branding.subtitle")}
      </p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          color: '#fff',
          margin: '20px 0px 0px 0px',
        }}
      >
        <p
          style={{
            margin: '0px',
          }}
        >
          {t("step_branding.choose_color_or_insert_code")}
        </p>
        <AttentionIcon />
      </div>

      <div className={styles.step_data_container}>{RenderColorsPicker()}</div>

      <div
        style={{ display: 'flex', alignItems: 'center', gap: 6, marginTop: 20 }}
      >
        <p className={styles.title_text}>{t("step_branding.font")}</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>

      <p className={styles.subtitle_text} style={{ fontSize: 12 }}>
        {t("step_branding.choose_font_or_upload")}
      </p>

      <div style={{ display: 'flex', gap: 20, marginTop: 20 }}>
        <CustomSelect
          placeholder={t("step_branding.select_font_type")}
          arrowDirection="down"
          options={fontTypeOptions}
          defaultSelected={selectedFontType}
          onClickItem={handleChangeSelectFontType}
        />
        <CustomSelect
          placeholder={t("step_branding.select_font")}
          arrowDirection="down"
          options={fontsOptions}
          defaultSelected={selectedFontItem}
          disabled={!selectedFontType || isEmpty(selectedFontType)}
          onClickItem={handleChangeSelectFont}
        />

        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 8,
          }}
        >
          <div
            style={{
              cursor: 'pointer',
              marginTop: 4,
            }}
            onClick={() =>
              !isEmpty(selectedFontType) ? handleChecked() : null
            }
          >
            {fontUpload ? (
              <BoxChecket width={20} height={20} />
            ) : (
              <BoxNotChecket width={20} height={20} />
            )}
          </div>

          <label
            style={{
              fontSize: 10,
              minWidth: 240,
              color: '#ffff',
            }}
          >
            Desejo fazer o upload de uma fonte customizada
          </label>
        </div> */}
      </div>

      <AnimatePresence initial={false}>
        {fontUpload && (
          <motion.div
            initial={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ position: 'relative' }}
          >
            <MultipleFontUploadDrag
              variant={'column'}
              textButton={t("step_branding.add_font")}
              filesSelectedArray={filesSelectedArray}
              onFileChange={onFileChange}
            />
            {selectedFont && (
              <div className={styles.selected_font}>
                <p>{selectedFont?.name}</p>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {filesSelectedArray.length !== 0 && (
        <div
          className={
            variant === 'column'
              ? styles.content_list_image_column
              : styles.content_list_image
          }
        >
          <div className={styles.container_box_list_image}>
            {filesSelectedArray.map((font, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className={styles.item_list_image}>
                    <div className={styles.box_data_info}>
                      <p
                        style={{ fontFamily: font.name }}
                        className={styles.box_data_info_title}
                      >
                        Lorem ipsum dolor sit
                      </p>
                      <p className={styles.box_data_info_title_fonte}>
                        - {font.name}
                      </p>
                    </div>
                  </div>
                  {!font.isSystemFont && (
                    <div className={styles.box_data_info}>
                      <p
                        style={{
                          fontSize: '10px',
                          fontWeight: 300,
                          display: 'flex',
                          flex: 1,
                        }}
                        className={styles.box_data_info_title_fonte}
                      >
                        {t("step_branding.custom_font")}
                      </p>
                    </div>
                  )}
                  <div className={styles.item_list_image_buttons}>
                    <p className={styles.definition_title}>
                      {font.selectedFontType.label}
                    </p>
                    <div
                      className={styles.button_cancel}
                      onClick={() => handleRemoveFile(font, index)}
                    >
                      <Trash color={'red'} width={30} height={30} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}

      <div className={styles.modal_button_save}>
        <button onClick={handleClickSetFont} className={styles.button_add}>
          {t("step_branding.add_font")}
        </button>
      </div>
      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_BUSINESS_INFORMATION)}
          variant={true}
        >
          {t("step_branding.button_back")}
        </ButtonNavigateStep>

        <ButtonNavigateStep onClick={() => handleSubmit()} variant={'next'}>
          {t("step_branding.button_next")}
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepBranding
