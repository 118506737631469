import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'
import { motion } from 'framer-motion'
import {
  UserService,
  Login,
  UserAccountBusinessUserService,
} from '../../../../services'
import {
  notifyErrorCustom,
  notifySuccessCustom,
} from '../../../../utils/notify'

import { Loading } from '../../../../components/Loading'

export const AccessData = ({ setStep }) => {
  const { t } = useTranslation()
  const [passwordExpanded, setPasswordExpanded] = useState(false)
  const [userPass, setUserPass] = useState('')
  const [newUserPass, setNewUserPass] = useState('')
  const [confirmUserPass, setConfirmUserPass] = useState('')

  const [loading, setLoading] = useState(false)

  const handleExpandPassword = async () => {
    if (
      window.confirm(
        'Ao realizar a alteração de senha, você estará modificando a senha para todos os acessos relacionados ao seu e-mail logado'
      )
    ) {
      if (passwordExpanded === false) {
        setLoading(true)
        const guestUserId = JSON.parse(
          window.localStorage.getItem('userData')
        ).guestUserId

        const { responseStatus } = await Login.postComparePassword({
          password: userPass,
          guestUserId: guestUserId,
        })

        setLoading(false)

        if (responseStatus === 200 || responseStatus === 204) {
          setPasswordExpanded(!passwordExpanded)
        } else {
          notifyErrorCustom('A senha não é compativel')
        }
      } else {
        setPasswordExpanded(!passwordExpanded)
      }
      return
    } else {
      return
    }
  }

  const UpdateUserPass = async () => {
    // const isValid = await validateFields()

    // if (!isValid) return

    if (newUserPass === confirmUserPass) {
      if (newUserPass.length >= 8) {
        const guestUserId = JSON.parse(
          window.localStorage.getItem('userData')
        ).guestUserId
        if (guestUserId) {
          const { data } =
            await UserAccountBusinessUserService.getUserBusinessUserById(
              guestUserId
            )
          setLoading(true)

          const { responseStatus } =
            await UserAccountBusinessUserService.updateUserById(guestUserId, {
              name: data.name,
              surname: data.surname,
              email: data.email,
              phone: data.phone,
              password: newUserPass,
              userPhotoName: data.userPhotoName,
              userPhotoUrl: data.userPhotoUrl,
              userAccountId: data.userAccountId,
            })

          setLoading(false)

          if (responseStatus === 200 || responseStatus === 204) {
            notifySuccessCustom('Senha alterada com sucesso')
            setStep(0)
          } else {
            notifyErrorCustom('Não foi possível alterar os dados do usuario!')
          }
        } else {
          const { data } = await UserService.getUserByLogin()

          setLoading(true)
          const { responseStatus } = await UserService.updateUserById({
            name: data.name,
            surname: data.surname,
            email: data.email,
            phone: data.phone,
            password: newUserPass,
            knowledgeChannelId: data.knowledgeChannelId,
          })

          setLoading(false)

          if (responseStatus === 200 || responseStatus === 204) {
            notifySuccessCustom('Senha alterada com sucesso')
            setStep(1)
          } else {
            notifyErrorCustom('Não foi possível alterar os dados do usuario!')
          }
        }
      } else {
        notifyErrorCustom('A senha tem que conter mais de 8 caracteres!')
      }
    } else {
      notifyErrorCustom('As senhas não são compativeis!')
    }
  }

  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={styles.page}
      >
        <div className={styles.container_access_data}>
          <div className={styles.container_input_form}>
            <div className={styles.access_data_module}>
              <div className={styles.module}>
                <h3 className={styles.module_title}>{t("my_account.steps.access_data")}</h3>
              </div>
              <div className={styles.container_line_input_form}>
                <div className={styles.box_input_form}>
                  <label className={styles.label_form}>{t("access_data.form.current_password")}</label>
                  <input
                    className={styles.input_form}
                    type="password"
                    placeholder={t("access_data.form.current_password")}
                    id="surname"
                    value={userPass}
                    onChange={(e) => setUserPass(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                className={styles.button_save}
                onClick={() => handleExpandPassword()}
              >
                {t("access_data.button.reset_password")}
              </button>
            </div>

            {passwordExpanded && (
              <div className={styles.access_data_module}>
                <div>
                  <h3 className={styles.module_title}>{t("access_data.title")}</h3>
                </div>
                <div className={styles.container_line_input_form}>
                  <div className={styles.box_input_form}>
                    <label className={styles.label_form}>{t("access_data.form.new_password")}</label>
                    <input
                      className={styles.input_form}
                      type="password"
                      placeholder={t("access_data.form.new_password_placeholder")}
                      value={newUserPass}
                      onChange={(e) => setNewUserPass(e.target.value)}
                    />
                  </div>

                  <div className={styles.box_input_form}>
                    <label className={styles.label_form}>
                      {t("access_data.form.confirm_new_password")}
                    </label>
                    <input
                      className={styles.input_form}
                      type="password"
                      placeholder={t("access_data.form.confirm_new_password_placeholder")}
                      value={confirmUserPass}
                      onChange={(e) => setConfirmUserPass(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.footer}>
                  <button
                    className={styles.button_save}
                    onClick={() => UpdateUserPass()}
                  >
                    {t("access_data.button.save_password")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </>
  )
}
