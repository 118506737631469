import PlanCard from '../../components/PlanCard'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import {
  PagarMeService,
  PlanService,
  UserAccountsService,
} from '../../services/'
import React, { useEffect, useState } from 'react'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import { Accept, TextBubble } from '../../assets/svgs/icons'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'


function PlansPage() {
  const { t } = useTranslation()
  const [userAcount, setUserAccount] = useState()
  const [userCard, setCard] = useState()

  useEffect(() => {
    loadPlans()
  }, [])

  const [plansData, setPlansData] = useState([])
  const PLANS_PROPS = {
    TITLE: 'Basic',
    PREVIOUS_PRICE: '00,00',
    PRICE: '00,00',
    DESCRIPTION: t('plansPage.description'),
    FEATURE_TITLE: 'Feature',
    FEATURE_DESCRIPTION: t('plansPage.featureDescription'),
    FEATURE_ITEM: [
      {
        ID_ITEM: '01',
        STATUS: true,
        ITEM_TITLE: 'Feature 1',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '02',
        STATUS: false,
        ITEM_TITLE: 'Feature 2',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '01',
        STATUS: true,
        ITEM_TITLE: 'Feature 3',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '02',
        STATUS: false,
        ITEM_TITLE: 'Feature 4',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '01',
        STATUS: true,
        ITEM_TITLE: 'Feature 5',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '02',
        STATUS: false,
        ITEM_TITLE: 'Feature 6',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
    ],
  }
  const TIPO_SUPORTE = [
    {
      id: '1',
      value: 'Blog',
    },
    {
      id: '2',
      value: 'Blog',
    },
    {
      id: '3',
      value: 'E-mail',
    },
    {
      id: '4',
      value: 'Chat',
    },
    {
      id: '05',
      value: 'Chat e Whatsapp',
    },
  ]

  const TIPO_DESCRIPTION = [
    {
      id: 0,
      value: t('plansPage.freeVersionDescription'),
    },
    {
      id: 1,
      value: t('plansPage.studentPlanDescription'),
    },
    {
      id: 2,
      value: t('plansPage.entrepreneurPlanDescription'),
    },
    {
      id: 3,
      value: t('plansPage.professionalPlanDescription'),
    },
    {
      id: 4,
      value: t('plansPage.corporatePlanDescription'),
    },
  ]

  const navigationPage = () => {
    navigate('/Settings')
  }
  const loadPlans = async () => {
    setUserAccount(await UserAccountsService.getUserAccountsByLogin())

    setCard(await PagarMeService.getCardByUser())
    const { data } = await PlanService.getPlansBaseValues()

    // este body é para tentar mandar plano free junto com os planos do pagarme, primeira tentativa deu erro mas estou mantendo
    // o objeto por falta de tempo para poder implementar, se no futuro nao for usar pode apagar.
    const dataFree = [
      {
        ...data,
        4: {
          uuid: '48343d43-e207-4ca6-80a5-d07fd9349bda',
          name: '1.plano Free',
          price: '27.9',
          periodicity: 'month',
          description: 'plano free',
          trial: false,
          trialPeriod: null,
          pagarmePlanId: 'plan_g7dDow9fMfvN4OxL',
          tier: 1,
          createdAt: '2024-05-07T13:23:56.918Z',
          updatedAt: '2024-05-07T13:23:56.918Z',
          deletedAt: null,
          PlanItems: [
            {
              uuid: 'aa11967e-43c0-4dbf-b6ff-d2326fcaa0e2',
              displayName: 'Quantidade de Postagens',
              type: 'post_quantity',
              quantity: 60,
              pagarmePlanItemId: 'pi_mEVJW70IdINL9Y7a',
              plansId: 'd210e9a8-242a-4dcc-b987-1c51955a9fa9',
              price: '97',
              createdAt: '2024-05-07T13:29:06.504Z',
              updatedAt: '2024-05-07T13:29:06.504Z',
              deletedAt: null,
            },
            {
              uuid: '14e2f2e7-6a33-4440-91cb-50f45de3900c',
              displayName: 'Quantidade de Usuarios',
              type: 'user_quantity',
              quantity: 3,
              pagarmePlanItemId: 'pi_1bgxa6BumubaE4Zo',
              plansId: 'd210e9a8-242a-4dcc-b987-1c51955a9fa9',
              price: '0',
              createdAt: '2024-05-07T13:29:06.504Z',
              updatedAt: '2024-05-07T13:29:06.504Z',
              deletedAt: null,
            },
            {
              uuid: 'c5409e4a-5d0f-46f2-809c-1dfa978ec532',
              displayName: 'Agendamentos',
              type: 'scheduling_quantity',
              quantity: 360,
              pagarmePlanItemId: 'pi_1Wbk7n4UgUdeOGxD',
              plansId: 'd210e9a8-242a-4dcc-b987-1c51955a9fa9',
              price: '0',
              createdAt: '2024-05-07T13:29:06.504Z',
              updatedAt: '2024-05-07T13:29:06.504Z',
              deletedAt: null,
            },
            {
              uuid: 'cd9e3dc0-5993-4b03-a54c-4168f2be89eb',
              displayName: 'Clientes',
              type: 'client_quantity',
              quantity: 3,
              pagarmePlanItemId: 'pi_bJ7VL9rUXUVo5rYN',
              plansId: 'd210e9a8-242a-4dcc-b987-1c51955a9fa9',
              price: '0',
              createdAt: '2024-05-07T13:29:06.504Z',
              updatedAt: '2024-05-07T13:29:06.504Z',
              deletedAt: null,
            },
            {
              uuid: 'ed4813e1-f6ae-40e3-b050-46d38da4f935',
              displayName: 'Quantidade de Integrações',
              type: 'integration_quantity',
              quantity: 3,
              pagarmePlanItemId: 'pi_VDpXkZ9iLiwBarN5',
              plansId: 'd210e9a8-242a-4dcc-b987-1c51955a9fa9',
              price: '0',
              createdAt: '2024-05-07T13:29:06.504Z',
              updatedAt: '2024-05-07T13:29:06.504Z',
              deletedAt: null,
            },
            {
              uuid: '2dbb98b9-b23c-4b6d-ae1e-0f5166bafe81',
              displayName: 'Tipo de Suporte',
              type: 'support',
              quantity: 2,
              pagarmePlanItemId: 'pi_3Zp7EJnSGSdEoYJK',
              plansId: 'd210e9a8-242a-4dcc-b987-1c51955a9fa9',
              price: '0',
              createdAt: '2024-05-07T13:29:06.504Z',
              updatedAt: '2024-05-07T13:29:06.504Z',
              deletedAt: null,
            },
            {
              uuid: '4b5460d8-ac80-4e32-b2d6-5291c5c4bda3',
              displayName: 'Storage Cloud',
              type: 'storage_cloud_quantity',
              quantity: 5,
              pagarmePlanItemId: 'pi_KN8rGXBFdFgA34zW',
              plansId: 'd210e9a8-242a-4dcc-b987-1c51955a9fa9',
              price: '0',
              createdAt: '2024-05-07T13:29:06.504Z',
              updatedAt: '2024-05-07T13:29:06.504Z',
              deletedAt: null,
            },
            {
              uuid: '0623486d-a500-4105-bc37-4f1df38bf7e6',
              displayName: 'Storage Image',
              type: 'storage_image_quantity',
              quantity: 2500,
              pagarmePlanItemId: 'pi_qN3v1MKf2f3pP48V',
              plansId: 'd210e9a8-242a-4dcc-b987-1c51955a9fa9',
              price: '0',
              createdAt: '2024-05-07T13:29:06.504Z',
              updatedAt: '2024-05-07T13:29:06.504Z',
              deletedAt: null,
            },
          ],
          priceReal: 'R$ 27,90',
          resume: 'Definir Descrição Plano Free aqui',
        },
      },
    ]

    await data.forEach(async (plan, index) => {
      plan.priceReal = formattedPrice(plan.price)
      plan.resume = TIPO_DESCRIPTION[index].value
      await plan.PlanItems.forEach(async (item) => {
        if (
          !item.quantity &&
          (item.displayName == 'Agendamentos' ||
            item.displayName == 'Quantidade de Postagens' ||
            item.displayName == 'Clientes')
        ) {
          item.quantity = 'Ilimitado'
        }
        if (item.type == 'storage_cloud_quantity') {
          item.quantity = item.quantity > 0 ? item.quantity + ' GB' : ''
        }

        if (item.type == 'support') {
          item.quantity = TIPO_SUPORTE[item.quantity].value
        }
      })
    })
    setPlansData(data)

    return data
  }
  const formattedPrice = (price) => {
    // Converte o preço para float e arredonda para duas casas decimais
    const formatted = parseFloat(price).toFixed(2)

    // Transforma o preço em uma string com formato de moeda
    return parseFloat(formatted).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }
  const navigate = useNavigate()
  return (
    <motion.div
      // key={expanded}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%', height: '100%' }}
    >
      <div className={styles.page}>
        <div className={styles.title_my_data}>
          <div>
            <p className={styles.title_my_data_label}>{t('plansPage.settings')}</p>
            <p className={styles.title_my_data_title}>{t('plansPage.plans')}</p>
          </div>

          <button className={styles.button_back} onClick={() => navigationPage()}>
            Voltar
          </button>
        </div>
        <div className={styles.container_field}>
          <div className={styles.container}>
            <div className={styles.logo}>
              <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
              <img
                src={posteiLogoText}
                alt="posteiLogoText"
                style={{ height: 34 }}
              />
            </div>

            <div className={styles.header_container}>
              <h1>{t('plansPage.selectAPlan')}</h1>
              <p>{t('plansPage.chooseAPlanThatBestFitsYourBusiness')}</p>
            </div>

            <div className={styles.plans_content}>
              {plansData.map((plan) => (
                <PlanCard
                  firstLogin={false}
                  userAcount={userAcount}
                  userCard={userCard}
                  key={plan.id}
                  uuid={plan.uuid}
                  title={plan.description}
                  previusPrice={plan.priceMonth}
                  price={plan.priceReal}
                  description={plan.resume}
                  featureTitle={PLANS_PROPS.FEATURE_TITLE}
                  featureDescription={PLANS_PROPS.FEATURE_DESCRIPTION}
                  featureItem={plan.PlanItems}
                  premium={plan.tier == 4 ? true : false}
                  tier={plan.tier}
                />
              ))}
            </div>
            <div className={styles.free_container}>
              <div className={styles.features_item}>
                <h2>{t('plansPage.freeVersion')}</h2>
              </div>
              <div className={styles.features_item}>
                <Accept color={'#00B828'} />
                <p>{t('plansPage.postQuantity')}</p>
              </div>
              <div className={styles.features_item}>
                <Accept color={'#00B828'} />
                <p>{t('plansPage.userQuantity')}</p>
              </div>
              <div className={styles.features_item}>
                <Accept color={'#00B828'} />
                <p>{t('plansPage.scheduling')}</p>
              </div>
              <div className={styles.features_item}>
                <Accept color={'#00B828'} />
                <p>{t('plansPage.clients')}</p>
              </div>
              <div className={styles.features_item}>
                <Accept color={'#00B828'} />
                <p>{t('plansPage.integrationQuantity')}</p>
              </div>

              <div className={styles.footer_card}>
                <button
                  className={styles.button_select}
                  // onClick={() => selectPlan(tier)}
                >
                  {t('plansPage.select')} <Accept color={'#00B828'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default PlansPage
