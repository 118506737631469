import styles from './styles.module.css'
import usaFlag from '../../assets/imgs/usa.png'
import brasilFlag from '../../assets/imgs/brazil.png'
import { useTranslation } from 'react-i18next'


const languageOptions = [
    {
        name: "Português",
        value: "ptBR",
        flag: brasilFlag
    },
    {
        name: "English",
        value: "en",
        flag: usaFlag
    }
]


function LanguageSwitcher() {


    const {t, i18n} = useTranslation()

    return(
        <div className={styles.box_country_flag}>
            {languageOptions.map((languageOption, index) => (
                <div key={index} className={styles.country_flag} onClick={() => {
                    i18n.changeLanguage(languageOption.value)
                    localStorage.setItem('i18nextLng', languageOption.value)
                }}>
                    <img src={languageOption.flag} alt={languageOption.name} />
                </div>
            ))}
        </div>
    )
}

export default LanguageSwitcher