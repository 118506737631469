import { useEffect, useRef, useState } from 'react'
import { PosteiLogo } from '../../assets/svgs/icons'
import StepName from './stepName'
import styles from './styles.module.css'
import StepEmail from './stepEmail'
import StepPhone from './stepPhone'
import StepPassword from './stepPassword'
import StepImage from './stepImage'
import StepVoiceTone from './stepVoiceTone'
import StepLogo from './stepLogo'
import StepBPS from './stepBPS'
import StepTypography from './stepTypography'

import StepNIcheAndObjective from './stepNicheAndObjective'
import StepPublication from './stepPublication'
import StepPublicationDownload from './stepPublicationDownload'
import Password from 'antd/es/input/Password'
import { BusinessUser, UserAccountsService, UserService } from '../../services'

import { notifyErrorCustom } from '../../utils/notify'
import StepCPF from './stepCPF'
import StepAddress from './stepAddress'
import StepColorPalette from './stepColorPalette'
import { useAuth } from '../../hooks/useAuth'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import StepSubject from './stepSubject'
import StepBusinessInfo from './stepBusnessInfo'
import StepPublicationInfo from './stepPublicationInfo'

import xamaLogoazul from '../../assets/logos/xama-logo-azul.png'


const STEPS = {
  STEP_PASS_NAME: 'stepName',
  STEP_PASS_EMAIL: 'stepEmail',
  STEP_PASS_PHONE: 'stepPhone',
  STEP_PASS_PASSWORD: 'stepPassword',
  STEP_PASS_CPF: 'stepCPF',
  STEP_PASS_ADDRESS: 'stepAddress',
  STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
  STEP_PASS_SUBJECT: 'StepSubject',
  STEP_PASS_LOGO: 'stepLogo',
  STEP_PASS_IMAGE: 'stepImage',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_PASS_BPS: 'stepBPS',
  STEP_PASS_TYPOGRAPHY: 'stepTypography',
  STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',
  STEP_PASS_PUBLICATION: 'stepPublication',
  STEP_PASS_PUBLICATION_DOWNLOAD: 'stepPublicationDownload',
  STEP_PASS_BUSINESS_INFO: 'stepBusinessInfo',
  STEP_PASS_PUBLICATION_INFO: 'stepPublicationInfo',
}

function FastPublishing() {
  const [step, setStep] = useState(STEPS.STEP_FIRST_PASS_NAME)
  const [numberStep, setNumberStep] = useState(0)
  const { setQuickRegistration, quickRegistration } = useAuth()
  const location = useLocation()

  const [name, setName] = useState('')
  const [arrayName, setArrayName] = useState([])
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [pass, setPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')

  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [fantasyName, setFantasyName] = useState('')
  const [corporateReason, setCorporateReason] = useState('')
  const [subject, setSubject] = useState('')

  const [userData, setUserData] = useState('')
  const [userAccountData, setUserAccountData] = useState('')
  const [businessAccountData, setBusinessAccountData] = useState('')

  const wordsArray = name.trim().split(/\s+/)

  const [filesSelectedArray, setFilesSelectedArray] = useState([])
  const [logoSelectedArray, setLogoSelectedArray] = useState([])

  const [objectiveSelected, setObjectiveSelected] = useState('')

  const [brandingArchetypeData, setBrandingArchetypeData] = useState({})

  const [colors, setColors] = useState([])

  const [font, setFont] = useState('')


  const [headlineList, setHeadlineList] = useState([])
  const [legendList, setLegendList] = useState([])
  const [headlineText, setHeadlineText] = useState('')
  const [postSelected, setPostSelected] = useState({})

  const [iaData, setIaData] = useState([
    {},
    {},
    {},
    {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
  ])

  const [headlineGpt, setHeadlineGpt] = useState(null)

  const [legend, setLegend] = useState(false)

  const navigation = useNavigate()

  const [qtdLoadTemplates, setQtdLoadTemplates] = useState(0)
  const qtdTemplatesLimit = 3
  useEffect(() => {
    handleStep()
    const [first, ...rest] = wordsArray;
    
  }, [numberStep])

  useEffect(() => {
    
    handleFirstLogin()

    if (location.state?.loginRedirect) {
      setStep(STEPS.STEP_PASS_BUSINESS_INFO)
    }
  }, [])

  const handleFirstLogin = async () =>{
    const { data: dataFirstLogin } = await UserAccountsService.getUserAccountsByLogin()

    console.log('dataFirstLogin',dataFirstLogin)

    if(!dataFirstLogin.firstLogin){
      navigation('/HomePage')
      
    }
  }
  

  const isValidEmail = (email) => {
    // Expressão regular para validar e-mail
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    // Testa o e-mail recebido com o regex
    return emailRegex.test(email)
  }

  const verifyEmailExist = async (email) => {
    let response = false
    const checkEmailExists = await UserService.getUserCheckEmailExists(email)
    if (checkEmailExists?.data?.exists) {
      response = checkEmailExists?.data?.exists
    } else {
      response = checkEmailExists?.data?.exists
    }

    return response
  }

 

  const handleStep = () => {
    let newStep = ''

    switch (numberStep) {
      case 0:
        newStep = STEPS.STEP_PASS_NAME
        break
      case 1:
        newStep = STEPS.STEP_PASS_EMAIL
        break
      case 2:
        newStep = STEPS.STEP_PASS_PHONE
        break
      case 3:
        newStep = STEPS.STEP_PASS_PASSWORD
        break
      case 4:
        newStep = STEPS.STEP_PASS_CPF
        break
      case 5:
        newStep = STEPS.STEP_PASS_ADDRESS
        break
      case 6:
        newStep = STEPS.STEP_PASS_NICHE_AND_OBJECTIVE
        break
      case 7:
        newStep = STEPS.STEP_PASS_IMAGE
        break
      case 8:
        newStep = STEPS.STEP_PASS_LOGO
        break
      case 9:
        newStep = STEPS.STEP_PASS_BPS
        break
      case 10:
        newStep = STEPS.STEP_PASS_TYPOGRAPHY
        break
      case 11:
        newStep = STEPS.STEP_PASS_PUBLICATION
        break
      case 12:
        newStep = STEPS.STEP_PASS_PUBLICATION_DOWNLOAD
        break
      default:
        break
    }

    setStep(newStep)
  }

  return (
    <div className={styles.container_page}>
      <div className={styles.box_logo}>
        {/* <PosteiLogo /> */}
        <img src={xamaLogoazul} alt="logo"  style={{ height: 40 }}  />
      </div>

      {step === STEPS.STEP_PASS_NAME && (
        <StepName
          setName={setName}
          name={name}
          setArrayName={setArrayName}
          setStep={setStep}
        />
      )}
      {step === STEPS.STEP_PASS_EMAIL && (
        <StepEmail setEmail={setEmail} email={email} setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_PHONE && (
        <StepPhone phone={phone} setPhone={setPhone} setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_PASSWORD && (
        <StepPassword
          setPass={setPass}
          setConfirmPass={setConfirmPass}
          pass={pass}
          confirmPass={confirmPass}
          setStep={setStep}
          wordsArray={wordsArray}
          phone={phone}
          email={email}
          setUserData={setUserData}
        />
      )}
      {step === STEPS.STEP_PASS_CPF && (
        <StepCPF
          cpf={cpf}
          setCpf={setCpf}
          setCnpj={setCnpj}
          cnpj={cnpj}
          setFantasyName={setFantasyName}
          fantasyName={fantasyName}
          setCorporateReason={setCorporateReason}
          corporateReason={corporateReason}
          setNumberStep={setNumberStep}
          numberStep={numberStep}
          setStep={setStep}
        />
      )}
      {step === STEPS.STEP_PASS_ADDRESS && (
        <StepAddress
          setStep={setStep}
          cpf={cpf}
          corporateReason={corporateReason}
          userData={userData}
          fantasyName={fantasyName}
          cnpj={cnpj}
          setUserAccountData={setUserAccountData}
          pass={pass}
          email={email}
          wordsArray={wordsArray}
          phone={phone}
          setUserData={setUserData}
          setQuickRegistration={setQuickRegistration}
        />
      )}
      {step === STEPS.STEP_PASS_BUSINESS_INFO && (
        <StepBusinessInfo  setStep={setStep}/>
      )}
      {step === STEPS.STEP_PASS_PUBLICATION_INFO && (
        <StepPublicationInfo  setStep={setStep}/>
      )}
      {step === STEPS.STEP_PASS_SUBJECT && (
        <StepSubject
          setStep={setStep}
          subject={subject}
          setSubject={setSubject}
        />
      )}
      {step === STEPS.STEP_PASS_COLOR_PALETTE && (
        <StepColorPalette
          setStep={setStep}
          colors={colors}
          setColors={setColors}
          businessAccountData={businessAccountData}
        />
      )}
      {step === STEPS.STEP_PASS_LOGO && (
        <StepLogo
          setStep={setStep}
          logoSelectedArray={logoSelectedArray}
          setLogoSelectedArray={setLogoSelectedArray}
        />
      )}
      {step === STEPS.STEP_PASS_IMAGE && (
        <StepImage
          setStep={setStep}
          filesSelectedArray={filesSelectedArray}
          setFilesSelectedArray={setFilesSelectedArray}
        />
      )}
      {step === STEPS.STEP_VOICE_TONE && (
        <StepVoiceTone
          setStep={setStep}
          
        />
      )}
      {step === STEPS.STEP_PASS_NICHE_AND_OBJECTIVE && (
        <StepNIcheAndObjective
          setStep={setStep}
          setBusinessAccountData={setBusinessAccountData}
          objectiveSelected={objectiveSelected}
          setObjectiveSelected={setObjectiveSelected}
        />
      )}
      {step === STEPS.STEP_PASS_BPS && (
        <StepBPS
          setStep={setStep}
          setBrandingArchetypeData={setBrandingArchetypeData}
          brandingArchetypeData={brandingArchetypeData}
        />
      )}
      {step === STEPS.STEP_PASS_TYPOGRAPHY && (
        <StepTypography setStep={setStep} 
        businessAccountData={businessAccountData}
        font={font} 
        setFont={setFont}
        />
      )}

      {step === STEPS.STEP_PASS_PUBLICATION && (
        <StepPublication
          setStep={setStep}
          filesSelectedArray={filesSelectedArray}
          setHeadlineList={setHeadlineList}
          headlineList={headlineList}
          setLegendList={setLegendList}
          legend={legend}
          subject={subject}
          setLegend={setLegend}
          legendList={legendList}
          setHeadlineText={setHeadlineText}
          headlineText={headlineText}
          iaData={iaData}
          setIaData={setIaData}
          qtdLoadTemplates={qtdLoadTemplates}
          setQtdLoadTemplates={setQtdLoadTemplates}
          qtdTemplatesLimit={qtdTemplatesLimit}
          setPostSelected={setPostSelected}
          brandingArchetypeData={brandingArchetypeData}
          businessAccountData={businessAccountData}
          font={font} 
        />
      )}
      {step === STEPS.STEP_PASS_PUBLICATION_DOWNLOAD && (
        <StepPublicationDownload
          setStep={setStep}
          postSelected={postSelected}
          setQuickRegistration={setQuickRegistration}
          quickRegistration={quickRegistration}

        />
      )}

      {/* <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div> */}
    </div>
  )
}

export default FastPublishing
