import { Comment, Globe, Like, Share } from '../../../../assets/svgs/icons'
import styles from './styles.module.css'
import { object } from 'prop-types'
import { useTranslation } from 'react-i18next'

export const LayoutFacebook = ({ image, legend, userData }) => {
  const { t } = useTranslation()

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str
    }
    return str?.slice(0, num) + '...'
  }

  return (
    <div className={styles.box_image_render}>
      <div className={styles.header_image_render}>
        <div className={styles.box_company_info}>
          <div className={styles.box_logo_company}>
            <img
              className={styles.image_company}
              src={userData?.photoUrl}
              alt=""
            />
          </div>
          <div>
            <p className={styles.name_company}>Minha conta</p>
            <p className={styles.status_publication}>agora mesmo</p>
          </div>
        </div>
        <p className={styles.legend_publication}>
          {truncateString(legend, 60)}
        </p>
      </div>

      <div className={styles.body_image_render}>
        <img
          src={image}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            borderRadius: 0,
          }}
        />
      </div>
      <div className={styles.footer_image_render}>
        <div className={styles.footer_options}>
          <Like />
          {t("new_publication_page_finish_pass_like")}
        </div>
        <div className={styles.footer_options}>
          <Comment />
          {t("new_publication_page_finish_pass_comment")}
        </div>
        <div className={styles.footer_options}>
          <Share />
          {t("new_publication_page_finish_pass_share")}
        </div>
      </div>
    </div>
  )
}

LayoutFacebook.propTypes = {
  image: object,
}
