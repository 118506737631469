import {
  CircleLike,
  Comment,
  CommentBubble,
  HeartLike,
  Like,
  PlaneShare,
  SaveFlag,
  Share,
} from '../../../../assets/svgs/icons'
import styles from './styles.module.css'
import { object } from 'prop-types'

export const LayoutTikTok = ({
  image,
  legend,
  userData,
  setValueItem,
  type,
  selectedValues,
}) => {
  function nextImage(type) {
    if (type === 4) {
      setValueItem(1)
    } else {
      setValueItem(type + 1)
    }
  }

  function previewImage(type) {
    if (type === 1) {
      setValueItem(4)
    } else {
      setValueItem(type - 1)
    }
  }
  return (
    <div className={styles.box_image_render}>
      {/* <div className={styles.header_image_render}>
        <div className={styles.box_company_info}>
          <div className={styles.box_logo_company}>
            <img
              className={styles.image_company}
              src={userData?.photoUrl}
              alt=""
            />
          </div>
          <div className={styles.box_name_company}>
            <p className={styles.name_company}>Minha conta</p>
          </div>
        </div>
      </div> */}

      <p className={styles.previewText}>Prévia TikTok</p>
      <div className={styles.body_image_render}>
        <div className={styles.carouselContainer}>
          <button
            className={styles.carouselButtonLeft}
            onClick={() => nextImage(type)}
          >
            &#60;
          </button>
          <img src={image} className={styles.carouselImage} alt="Imagem" />
          <button
            className={styles.carouselButtonRight}
            onClick={() => previewImage(type)}
          >
            &#62;
          </button>
        </div>
      </div>

      <div className={styles.footer_image_render}>
        <div className={styles.box_icons}>
          <div>
            {/* <HeartLike />
            <PlaneShare /> */}
            {/* <CommentBubble />
            <PlaneShare /> */}
          </div>
          <div>
            {/* <HeartLike /> */}
            <PlaneShare />
            {/* <SaveFlag /> */}
          </div>
        </div>
        <p className={styles.legend_publication}>
          {/* {truncateString(legend, 60)} */}
        </p>
      </div>
    </div>
  )
}

LayoutTikTok.propTypes = {
  image: object,
}
