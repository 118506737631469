import { useState } from 'react'
import styles from './styles.module.css'
import {
  CloundArrowUp,
  Cross,
  Interrogation,
  Stars,
  Trash,
} from '../../../assets/svgs/icons'
import { PythonService } from '../../../services'
import Loading from '../../../components/Loading' 
import { useTranslation } from 'react-i18next'
const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
}

const ModalLogoUp = ({ setImagesArray, closeModal, imagesArray }) => {
  const { t } = useTranslation()
  // const [dropzoneHover, setDropzoneHover] = useState(false)
  const [loading, setLoading] = useState(false)

  const [filesSelectedArray, setFilesSelectedArray] = useState(imagesArray)

  const handleRemoveBackground = async (index, base64) => {
    // setLoading(true)
    
    const { data } = await PythonService.postRemoveBackground({ image: base64 })
    const noBgImage = data.data.images

    filesSelectedArray[index].fileDataBase64 = noBgImage[1]?.image
    filesSelectedArray[index].isOriginal = noBgImage[1]?.isOriginal

    setFilesSelectedArray([...filesSelectedArray])
    setLoading(false)
  }

  const onDragOver = (event) => {
    event.preventDefault()
    // setDropzoneHover(true)
  }

  const onDragLeave = () => {
    // setDropzoneHover(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    // setDropzoneHover(false)

    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
        }

        setFilesSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    const fileReader = new FileReader()

    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
        }

        setFilesSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function formatBytes(bytes, decimals = 0) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const handleRemoveFile = (index) => {
    const updatedFilesArray = filesSelectedArray.filter((_, i) => i !== index)

    setFilesSelectedArray(updatedFilesArray)
    setImagesArray(updatedFilesArray)
  }

  const addImages = async () => {
    setImagesArray(filesSelectedArray)

    // nextButton()
    closeModal(false)
  }

  return (
    // <div className={styles.modal_overlay} style={{ background: '#0006' }}>
    <div className={styles.modal}>
      <Loading enable={loading} />
      <div className={styles.header_close}>
        <div className={styles.close_modal} onClick={() => closeModal(false)}>
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>
      <div className={styles.modal_title}>
        <h2 className={styles.modal_title_text}>
          {t('modal_logo_up.title')} <Interrogation color={'#FF8B00'} />
        </h2>
      </div>
      <div className={styles.modal_sub_title}>
        <p className={styles.modal_sub_title_text}>
          {t('modal_logo_up.subtitle')}
        </p>
      </div>
      <div className={styles.container_modal}>
        <div className={styles.content_up_image}>
          <div className={styles.modal_container_drag}>
            <div className={styles.modal_drag}>
              {/* <label htmlFor="arquivo">
                <h3>Arrase & Solte o arquivo</h3>
                <p>Ou selecione o arquivo no explorador</p>
              </label> */}

              <div
                className={styles.modal_drag_drop}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                // onClick={handleShowListImages}

                // style={{
                //   height: '100px',
                //   width: '100%',
                //   border: '2px dashed black',
                //   backgroundColor: dropzoneHover ? 'blue' : 'red',
                // }}
                // className={styles.modal_drag}
              >
                <label htmlFor="arquivo">
                  <CloundArrowUp color={'#B900FE'} />
                  <h2
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_title_short
                        : styles.modal_drag_drop_title
                    }
                  >
                    {t('modal_logo_up.drag_drop')}
                  </h2>
                  <p
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_sub_title_short
                        : styles.modal_drag_drop_sub_title
                    }
                  >
                    {t('modal_logo_up.select_file')}
                  </p>
                </label>
                <input
                  type="file"
                  name="arquivo"
                  id="arquivo"
                  onChange={(e) => onFileChange(e)}
                ></input>
                {/* {fileData && (
                      <img
                        style={{ width: 50, height: 50 }}
                        src={fileData}
                        alt="file-preview"
                      />
                    )} */}
              </div>
            </div>
          </div>
          <div className={styles.modal_button_save}>
            <button className={styles.button_add}>
              <label className={styles.button_add_label} htmlFor="arquivo">
                {t('modal_logo_up.add_image')}
              </label>
            </button>
          </div>
        </div>
        {filesSelectedArray.length !== 0 && (
          <div className={styles.content_list_image}>
            <div className={styles.container_box_list_image}>
              {filesSelectedArray?.map((image, index) => (
                <div
                  key={index}
                  className={styles.container_list_image}
                  // style={{ border: '1px solid red' }}
                >
                  <div className={styles.item_list_image}>
                    <img
                      src={image.fileDataBase64}
                      alt="file-preview"
                      style={{ width: 40, height: 40 }}
                    />
                    <div className={styles.box_data_info}>
                      <p className={styles.box_data_info_title}>{image.name}</p>
                      <p className={styles.box_data_info_size}>
                        {t('modal_logo_up.size')} &nbsp;<b> {formatBytes(image.size)} </b>
                      </p>
                    </div>
                  </div>
                  <div className={styles.item_list_image_buttons}>
                    <button
                      className={styles.button_cancel}
                      onClick={() => handleRemoveFile(index)}
                    >
                      <p className={styles.button_cancel_title}>
                        {t('modal_logo_up.delete')}
                      </p>
                      <Trash />
                    </button>
                    <button
                      className={styles.button_remove_background}
                      onClick={() => {
                        setLoading(true)
                        handleRemoveBackground(index, image.fileDataBase64)
                      }}
                    >
                      <p className={styles.button_remove_background_title}>
                        {t('modal_logo_up.remove_background')}
                      </p>{' '}
                      <Stars />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {filesSelectedArray.length !== 0 && (
        <button
          className={styles.button_save}
          onClick={() => {
            addImages()
          }}
        >
          {t('modal_logo_up.add_images')}
        </button>
      )}
    </div>
    // </div>
  )
}

export default ModalLogoUp
