/* eslint-disable react/prop-types */
import styles from './styles.module.css'

import Masonry from 'react-responsive-masonry'
import { useRef, useState } from 'react'
import {
  CircleX,
  Interrogation,
  LeftBackArrow,
} from '../../../../assets/svgs/icons'

import InputSearch from '../../../../components/InputSearch'
import Loading from '../../../../components/Loading'

import {
  FreepikService,
  PexelsService,
  SubscriptionService,
} from '../../../../services/'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
}

function StepImageBank({ setStep, setImageArray }) {
  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')

  const [allImages, setAllImages] = useState([])

  const {t} = useTranslation();

  const handleLoadImages = async () => {
    const subscription = await SubscriptionService.getSubscriptionByUser()
    let response

    if (subscription.data.length > 0) {
      const body = {
        search: search,
        limit: 10,
      }
      response = await FreepikService.getFreepikImages(body)
    } else {
      const body = {
        search: search,
        limit: 10,
      }

      response = await PexelsService.postPexelsImages(body)
    }

    if (response.data) {
      let newResponseData = response.data.map((image, index) => {
        return {
          id: index,
          url: image.imageUri ? image.imageUr : image.imageUrl,
          base64: image.imageBase64 ?? null,
          idFreepik: image.id ?? '',
        }
      })

      setAllImages(newResponseData)
    }

    setLoading(false)
  }

  const handleRemoveImage = (imageArray) => {
    setSelectedArrayImage(
      selectedArrayImage.filter(
        (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
      )
    )

    if (selectedImages.includes(imageArray.id)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageArray.id)
      )
    } else {
      setSelectedImages([...selectedImages, imageArray.id])
    }
  }

  const [selectedImages, setSelectedImages] = useState([])
  const [selectedArrayImage, setSelectedArrayImage] = useState([])

  const handleImageClick = (imageUrl) => {
    const imageArray = allImages.find((image) => image.id === imageUrl.id)

    if (imageArray) {
      const arrayExists = selectedArrayImage.some(
        (arr) => JSON.stringify(arr) === JSON.stringify(imageArray)
      )

      if (arrayExists) {
        setSelectedArrayImage(
          selectedArrayImage.filter(
            (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
          )
        )
      } else {
        setSelectedArrayImage([...selectedArrayImage, imageArray])
      }
    }

    if (selectedImages.includes(imageUrl.id)) {
      setSelectedImages(selectedImages.filter((image) => image !== imageUrl.id))
    } else {
      setSelectedImages([...selectedImages, imageUrl.id])
    }
  }

  const nextButton = async () => {
    setStep(STEPS.STEP_SECOND_PASS_IMAGE)
  }

  const addImages = async () => {
    setImageArray(selectedArrayImage)

    nextButton()
  }

  const handleMouseDown = (event) => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    setIsDragging(true)
    setStartX(event.clientX - carouselRef.current.offsetLeft)
    setScrollLeft(carouselRef.current.scrollLeft)
    carouselRef.current.style.scrollBehavior = 'unset'
    // }
  }

  const handleMouseMove = (event) => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    if (!isDragging) return
    const x = event.clientX - carouselRef.current.offsetLeft
    const scrollOffset = (x - startX) * 1.0 // Ajuste a velocidade de rolagem conforme necessário
    carouselRef.current.scrollLeft = scrollLeft - scrollOffset
    // }
  }

  const handleMouseUp = () => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    setIsDragging(false)
    carouselRef.current.style.scrollBehavior = 'smooth'
    // }
  }

  return (
    <div className={styles.container_new_posts}>
      <Loading enable={loading} />

      <div className={styles.header}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            nextButton()
          }}
        >
          <LeftBackArrow height={20} width={20} />
        </div>
        <p className={styles.arrow_back}>{t("back_buttom")}</p>
      </div>
      <div className={styles.image_bank_title}>
        <h2 className={styles.image_bank_text}>
         {t("new_publication_page_second_pass_bank_of_image_page_text")}
          <Interrogation color={'#FF8B00'} width={18} height={18} />
        </h2>
        <p className={styles.image_bank_subtitle_text}>
         {t("new_publication_page_second_pass_bank_of_image_page_subtitle")}
        </p>
      </div>
      <div className={styles.filters}>
        <div className={styles.search_images}>
          <InputSearch
            placeholder={'Pesquise...'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={() => {
              setLoading(true)
              handleLoadImages()
            }}
            style={{
              color: '#190027',
            }}
          />
        </div>
      </div>

      <div className={styles.box_container_galery_of_image}>
        <div className={styles.container_galery_of_image}>
          <div style={{ marginBottom: 160 }}>
            <Masonry columnsCount={3} gutter="10px">
              {allImages.map((imageUrl, i) => (
                <img
                  key={i}
                  src={
                    imageUrl.base64
                      ? 'data:image/png;base64,' + imageUrl.base64
                      : imageUrl.url
                  }
                  onClick={() => handleImageClick(imageUrl)}
                  className={
                    selectedImages.includes(imageUrl.id)
                      ? styles.selectedImage
                      : styles.image
                  }
                />
              ))}
            </Masonry>
          </div>
        </div>
      </div>
      {selectedArrayImage.length !== 0 && (
        <div className={styles.container_list_selected_image}>
          <p>Imagens Adicionadas</p>
          <div
            className={styles.content_list_selected_image}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <div
              className={styles.caroucel_list_selected_image}
              ref={carouselRef}
            >
              {selectedArrayImage.map((imageArray) => (
                <div
                  className={styles.card_lis_selected_image}
                  key={imageArray.id}
                >
                  <div className={styles.container_card_lis_selected_image}>
                    <div
                      className={styles.delete_card_lis_selected_image}
                      onClick={() => handleRemoveImage(imageArray)}
                    >
                      <div style={{ cursor: 'pointer' }}>
                        <CircleX
                          color={'#FF2F00'}
                          width={'15px'}
                          height={'15px'}
                        />
                      </div>
                    </div>
                    <img
                      className={styles.item_selected_image}
                      src={
                        imageArray.base64
                          ? 'data:image/png;base64,' + imageArray.base64
                          : imageArray.url
                      }
                      alt={imageArray.title}
                    />
                  </div>
                  {/* <div className={styles.item_selected_image_description}>
                    <p className={styles.item_selected_image_description_title}>
                      {imageArray.title}
                    </p>
                    <p
                      className={
                        styles.item_selected_image_description_category
                      }
                    >
                      Nome da categoria
                    </p>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
          <button
            className={styles.button_add_image}
            onClick={() => {
              addImages()
            }}
          >
            {t("new_publication_page_second_pass_bank_of_image_page_add_image")}
          </button>
        </div>
      )}
    </div>
  )
}

export default StepImageBank
