import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const getTiktokcallback = async (businessId, code, state) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(
      `${BASE_URL}auth/tiktok/callback/${businessId}/${code}/${state}`,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getLinkedinCallback', error.response.data)
      return error
    })
}

export const postProduct = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}linkedin/share`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postProduct', error.response.data)
      return error
    })
}

export const getTiktokLoginByBusiness = async (businessId) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}tiktok/v2/${businessId}`, config)
    .then(async (response) => {
      return {
        dataT: response.data,
        responseStatusT: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getTiktokLoginByBusiness', error.response.data)
      return error
    })
}

export const getTiktokCreatorInfo = async (businessId) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}auth/tiktok/creator_info/${businessId}`, config)
    .then(async (response) => {
      return {
        dataT: response.data,
        responseStatusT: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getTiktokCreatorInfo', error.response.data)
      return error
    })
}

export const postTiktokPublication = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}tiktok-publications`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error postTiktokPublication', error.response.data)
      return error
    })
}
