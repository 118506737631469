import PlanCard from '../../components/PlanCard'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import {
  PagarMeService,
  PlanService,
  UserAccountsService,
} from '../../services/'
import React, { useEffect, useState } from 'react'
import { Accept } from '../../assets/svgs/icons'
import { useTranslation } from 'react-i18next'

// function PlansCatalog(setShowPlans) {
export const PlansCatalog = ({ setShowPlans }) => {
  const { t } = useTranslation()
  const [userAcount, setUserAccount] = useState()
  const [userCard, setCard] = useState()

  const [planPay, setPlanPay] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (planPay) {
      setShowPlans(false)
    }
  }, [planPay])

  useEffect(() => {
    loadPlans()
  }, [])

  const [plansData, setPlansData] = useState([])
  const PLANS_PROPS = {
    TITLE: 'Basic',
    PREVIOUS_PRICE: '00,00',
    PRICE: '00,00',
    DESCRIPTION:
      'Funcionalidades adicionais para expandir sua presença online.',
    FEATURE_TITLE: 'Feature',
    FEATURE_DESCRIPTION: 'Escolha de acordo com as necessidades do seu negócio',
    FEATURE_ITEM: [
      {
        ID_ITEM: '01',
        STATUS: true,
        ITEM_TITLE: 'Feature 1',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '02',
        STATUS: false,
        ITEM_TITLE: 'Feature 2',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '01',
        STATUS: true,
        ITEM_TITLE: 'Feature 3',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '02',
        STATUS: false,
        ITEM_TITLE: 'Feature 4',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '01',
        STATUS: true,
        ITEM_TITLE: 'Feature 5',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
      {
        ID_ITEM: '02',
        STATUS: false,
        ITEM_TITLE: 'Feature 6',
        ITEM_DESCRIPTION:
          'Descrição de exemplo para a feature expandida. Esta descrição é condicional ao plano selecionado',
      },
    ],
  }
  const TIPO_SUPORTE = [
    {
      id: '1',
      value: 'Blog',
    },
    {
      id: '2',
      value: 'Blog',
    },
    {
      id: '3',
      value: 'E-mail',
    },
    {
      id: '4',
      value: 'Chat',
    },
    {
      id: '05',
      value: 'Chat e Whatsapp',
    },
  ]

  const TIPO_DESCRIPTION = [
    {
      id: 0,
      value: t('plansPage.freeVersionDescription'),
    },
    {
      id: 1,
      value: t('plansPage.studentPlanDescription'),
    },
    {
      id: 2,
      value: t('plansPage.entrepreneurPlanDescription'),
    },
    {
      id: 3,
      value: t('plansPage.professionalPlanDescription'),
    },
    {
      id: 4,
      value: t('plansPage.corporatePlanDescription'),
    },
  ]

  // const navigationPage = () => {
  //   navigate('/Settings')
  // }
  const loadPlans = async () => {
    setUserAccount(await UserAccountsService.getUserAccountsByLogin())

    setCard(await PagarMeService.getCardByUser())
    const { data } = await PlanService.getPlansBaseValues()

    await data.forEach(async (plan, index) => {
      plan.priceReal = formattedPrice(plan.price)
      plan.resume = TIPO_DESCRIPTION[index].value
      await plan.PlanItems.forEach(async (item) => {
        if (
          !item.quantity &&
          (item.displayName == 'Agendamentos' ||
            item.displayName == 'Quantidade de Postagens' ||
            item.displayName == 'Clientes')
        ) {
          item.quantity = 'Ilimitado'
        }
        if (item.type == 'storage_cloud_quantity') {
          item.quantity = item.quantity > 0 ? item.quantity + ' GB' : ''
        }

        if (item.type == 'support') {
          item.quantity = TIPO_SUPORTE[item.quantity].value
        }
      })
    })
    setPlansData(data)

    return data
  }

  const selectPlanFree = async () => {
    setShowPlans(false)

    navigate('/StepsPage', {
      state: { fastPublication: true, },
    })
  }

  const formattedPrice = (price) => {
    // Converte o preço para float e arredonda para duas casas decimais
    const formatted = parseFloat(price).toFixed(2)

    // Transforma o preço em uma string com formato de moeda
    return parseFloat(formatted).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }
  // const navigate = useNavigate()
  return (
    <div className={styles.container_field}>
      <div className={styles.container}>
        <div className={styles.plans_content}>
          {plansData.map((plan) => (
            <PlanCard
              setPlanPay={setPlanPay}
              firstLogin={true}
              userAcount={userAcount}
              userCard={userCard}
              key={plan.id}
              uuid={plan.uuid}
              title={plan.description}
              previusPrice={plan.priceMonth}
              price={plan.priceReal}
              description={plan.resume}
              featureTitle={PLANS_PROPS.FEATURE_TITLE}
              featureDescription={PLANS_PROPS.FEATURE_DESCRIPTION}
              featureItem={plan.PlanItems}
              premium={plan.tier == 4 ? true : false}
              tier={plan.tier}
            />
          ))}
        </div>
        <div className={styles.free_container}>
          <div className={styles.features_item}>
            <h2>{t('plansPage.freeVersion')}</h2>
          </div>
          <div className={styles.features_item}>
            <Accept color={'#00B828'} />
            <p>{t('plansPage.postQuantity')}: 8</p>
          </div>
          <div className={styles.features_item}>
            <Accept color={'#00B828'} />
            <p>{t('plansPage.userQuantity')}: 1</p>
          </div>
          <div className={styles.features_item}>
            <Accept color={'#00B828'} />
            <p>{t('plansPage.scheduling')}: 60</p>
          </div>
          <div className={styles.features_item}>
            <Accept color={'#00B828'} />
            <p>{t('plansPage.clients')}: 2</p>
          </div>
          <div className={styles.features_item}>
            <Accept color={'#00B828'} />
            <p>{t('plansPage.integrationQuantity')}: 2</p>
          </div>

          <div className={styles.footer_card}>
            <button
              className={styles.button_select}
              onClick={() => selectPlanFree()}
            >
              {t('plansPage.select')} <Accept color={'#00B828'} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlansCatalog
