import styles from './styles.module.css'
// import { useNavigate } from 'react-router-dom'
// import { ArrowDown, CloundUp, NumberOne } from '../../assets/svgs/icons'
import { useState, useEffect } from 'react'

import CampaingList from './CampaignList'
import NewCampaignStep from './NewCampaignStep'
import StepNewCampaignSuccess from './StepNewCampaignSuccess'
import StepPageParameters from './StepsPageCampaign'
//import NewCampaignStepForm from '../NewCampaignFormPage'
import { useClient } from '../../hooks/useClient'

import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_CAMPAIGN_LIST: 'CampaignList',
  STEP_NEW_CAMPAIGN: 'NewCampaign',
  STEP_NEW_CAMPAIGN_SUCCESS: 'StepNewCampaignSuccess',
  STEP_PAGE_PARAMETERS: 'StepPageParameters',
}

function Campaign() {
  const { t } = useTranslation()
  const location = useLocation()

  const { clientData } = useClient()

  const [step, setStep] = useState(STEPS.STEP_CAMPAIGN_LIST)

  const [registrationData, setRegistrationData] = useState({})
  const [campaignItem, setCampaignItem] = useState({})
  const [uncompletedSteps, setUncompletedSteps] = useState([])

  useEffect(() => {
    const step2 = location.state?.step

    if (step2) {
      setRegistrationData({})
      setCampaignItem(undefined)
      setUncompletedSteps([])
      return setStep(STEPS.STEP_NEW_CAMPAIGN)
    }

    setStep(STEPS.STEP_CAMPAIGN_LIST)
    setRegistrationData({})
    setCampaignItem({})
    setUncompletedSteps([])
  }, [clientData])

  useEffect(() => {
    if (step === STEPS.STEP_CAMPAIGN_LIST) {
      setRegistrationData({})
      setCampaignItem(undefined)
      setUncompletedSteps([])
    }
  }, [step])

  return (
    <div className={styles.page}>
      {step === STEPS.STEP_CAMPAIGN_LIST && (
        <motion.div
          // key={expanded}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <CampaingList
            step={step}
            setStep={setStep}
            setCampaignItem={setCampaignItem}
            clientData={clientData}
          />
        </motion.div>
      )}

      {step === STEPS.STEP_NEW_CAMPAIGN && (
        <NewCampaignStep
          setStep={setStep}
          state={campaignItem}
          setCampaignItem={setCampaignItem}
          registrationData={registrationData}
          setRegistrationData={setRegistrationData}
          clientData={clientData}
        />
      )}

      {step === STEPS.STEP_NEW_CAMPAIGN_SUCCESS && (
        <StepNewCampaignSuccess
          setStep={setStep}
          state={campaignItem}
          setCampaignItem={setCampaignItem}
          campaignItem={campaignItem}
          registrationData={registrationData}
          setUncompletedSteps={setUncompletedSteps}
        />
      )}

      {step === STEPS.STEP_PAGE_PARAMETERS && (
        <StepPageParameters
          setSteps={setStep}
          campaignItem={campaignItem}
          setCampaignItem={setCampaignItem}
          registrationData={registrationData}
          clientData={clientData}
          uncompletedSteps={uncompletedSteps}
        />
      )}
    </div>
  )
}

export default Campaign
