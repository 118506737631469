import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { ListTable } from '../../../../components/ListTable'
import { TableItem } from '../../../../components/ListTable/TableItem'
import { Row } from '../../../../components/ListTable/Row'
import { Checkbox, Switch } from 'antd'
import {
  CheckBadge,
  Engine,
  Clock,
  Eye,
  Reload,
  Trash,
} from '../../../../assets/svgs/icons'
import { motion } from 'framer-motion'
import {
  BusinessClient,
  UserAccountsService,
  PlanService,
  SubscriptionService,
} from '../../../../services'
import {
  notifySuccessCustom,
  notifyErrorCustom,
  notifyWarningCustom,
} from '../../../../utils/notify'

import enterprise from '../../../../assets/imgs/enterprise.png'

import { Loading } from '../../../../components/Loading'
import { useTranslation } from 'react-i18next'

export const ListClientStep = ({ setStep, data, setData }) => {
  const { t } = useTranslation()
  const [dataList, setDataList] = useState([])
  const headers = [
    t('client_list.headers.logo'),
    t('client_list.headers.name'),
    t('client_list.headers.document'),
    t('client_list.headers.email'),
    t('client_list.headers.responsible_name'),
    t('client_list.headers.phone'),
    t('client_list.headers.actions')
  ]

  const [loading, setLoading] = useState(false)

  const handleOpenModal = () => {}

  const handleChange = (index) => {
    // Faça a mudança apenas no estado local dataList

    const updatedDataList = [...dataList]
    updatedDataList[index] = {
      ...updatedDataList[index],
      status: !updatedDataList[index].status,
    }
    setDataList(updatedDataList)
  }

  useEffect(() => {
    loadBusinessClient()
  }, [])

  const loadBusinessClient = async () => {
    try {
      setLoading(true)
      const { data, responseStatus } =
        await BusinessClient.getAllBusinessClient()
      // const localDataList = data;

      if (responseStatus === 200) {
        setDataList(data)
      }
      setLoading(false)
    } catch (error) {
      console.error('Erro ao carregar clientes', error)
    }
  }

  const handleSingleCustomer = async (clients) => {
    if (!clients) {
      const businessClientCount =
        await BusinessClient.getAllBusinessClientCount()
      const userSubscription = await SubscriptionService.getSubscriptionByUser()

      console.log('businessClientCount.data.count', businessClientCount)

      if (
        userSubscription.data.length <= 0 &&
        businessClientCount.data.count >= 2
      ) {
        notifyWarningCustom(t('client_list.notifications.client_limit'))
        return
      } else if (userSubscription.data.length > 0) {
        const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
          (item) => item.type === 'client_quantity'
        )
        const quantityClient =
          userSubscription.data[0].Plan.PlanItems[index].quantity

        if (
          businessClientCount.data.count >= quantityClient &&
          quantityClient != null
        ) {
          console.log('plano pago')
          console.log(
            'businessClientCount.data.count',
            businessClientCount.data.count
          )
          console.log('quantityClient', quantityClient)
          notifyWarningCustom(t('client_list.notifications.client_limit'))
          return
        }
      }
    }
    setData({ ...clients })

    setStep(1)
  }

  const handleDeleteCustomer = async (id) => {
    try {
      setLoading(true)
      const { responseStatus } = await BusinessClient.deleteBusinessClient(id)
      setLoading(false)

      if (responseStatus === 200 || responseStatus === 204) {
        notifySuccessCustom(t('client_list.notifications.client_removed'))
        // Atualize dataList após a exclusão
        setDataList((prevDataList) =>
          prevDataList.filter((item) => item.id !== id)
        )
        loadBusinessClient()
      } else {
        notifyErrorCustom(t('client_list.notifications.client_remove_error'))
      }
    } catch (error) {
      console.error('Erro ao excluir cliente', error)
    }
  }

  const handleChecked = async (dataClient) => {
    const guestId = JSON.parse(
      window.localStorage.getItem('userData')
    ).guestUserId
    let uuid = dataClient?.uuid
    let newData = dataClient

    delete newData.uuid
    delete newData.createdAt
    delete newData.updatedAt
    delete newData.deletedAt

    delete newData.userAccountId
    delete newData.educationLevelValue
    delete newData.socialClassValue
    delete newData.genderValue
    delete newData.stateValue
    delete newData.businessId
    delete newData.businessClientPhotoName
    delete newData.BusinessClientPhotoUrl
    delete newData.isParameterized

    if (guestId) {
      newData.guestId = guestId
    } else {
      delete newData.guestId
    }

    // rest.enabled = !data?.enabled

    setLoading(true)
    const { data, responseStatus } = await BusinessClient.putBusinessClient(
      uuid,
      {
        ...newData,
        enabled: !newData.enabled,
      }
    )

    setLoading(false)

    if (responseStatus === 200 || responseStatus === 204) {
      loadBusinessClient()
      notifySuccessCustom(t('client_list.notifications.status_changed'))
    } else {
      notifyErrorCustom(t('client_list.notifications.status_change_error'))
    }
  }

  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <ListTable
          headers={headers}
          label={t('client_list.title')}
          title={t('client_list.subtitle')}
          buttonText={t('client_list.new_client')}
          onClickButton={() => handleSingleCustomer()}
          //onClick={() => handleSingleCustomer(client)}
        >
          {dataList.map((client, index) => (
            <Row key={index}>
              <TableItem>
                <div className={`${styles.table_item_gap} ${styles.code}`}>
                  <div>
                    {client?.BusinessClientPhotoUrl ? (
                      <img
                        src={client?.BusinessClientPhotoUrl}
                        alt={''}
                        className={styles.client_logo}
                      />
                    ) : (
                      <img
                        src={enterprise}
                        alt={''}
                        className={styles.client_logo}
                      />
                    )}
                  </div>
                </div>
              </TableItem>
              <TableItem>
                <div
                  className={`${styles.table_item_gap} ${styles.secondColumn}`}
                >
                  <p>{client.companyName}</p>
                </div>
              </TableItem>

              <TableItem>
                {client?.cnpj}
                {/* {client?.cnpj && client?.cnpj?.length <= 11
                  ? client.cnpj.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      '$1.$2.$3-$4'
                    )
                  : client.cnpj.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      '$1.$2.$3/$4-$5'
                    )} */}
              </TableItem>
              <TableItem>{client.email}</TableItem>
              <TableItem>{client.responsibleName}</TableItem>
              <TableItem>{client.phone}</TableItem>
              <TableItem style={{ maxWidth: 170 }}>
                <div className={styles.last_column}>
                  <div className={styles.icons_container}>
                    <div onClick={handleOpenModal} className={styles.icon}>
                      <Switch
                        onChange={() => handleChange(index)}
                        size="small"
                        style={{
                          backgroundColor: client?.enabled ? 'black' : '',
                        }}
                        checked={client?.enabled}
                        onClick={() => handleChecked(client)}
                      />
                    </div>

                    {client?.isParameterized ? (
                      <div onClick={handleOpenModal} className={styles.icon}>
                        <CheckBadge color="black" />
                      </div>
                    ) : (
                      <div onClick={handleOpenModal} className={styles.icon}>
                        <Clock color={'black'} />
                      </div>
                    )}

                    <div
                      onClick={() => handleSingleCustomer(client)}
                      className={styles.icon}
                    >
                      <Engine color="black" />
                    </div>
                    <div
                      className={styles.icon}
                      onClick={() => handleDeleteCustomer(client.uuid)}
                    >
                      <Trash color="black" />
                    </div>
                  </div>
                </div>
              </TableItem>
            </Row>
          ))}
        </ListTable>
      </motion.div>
    </>
  )
}
