/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'

import {
  BrandingArchetypeService,
  BrandingService,
  BusinessClient,
  BusinessService,
  ProductService,
  PythonService,
  TargetAudienceService,
} from '../../../services/'

import { useEffect, useState } from 'react'

import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'

import {
  NumberOne,
  ArrowDown,
  CloundUp,
  Pen2,
} from '../../../assets/svgs/icons'
import { notifyErrorCustom } from '../../../utils/notify'
import { ProductFruits } from 'react-product-fruits'
import { useTranslation } from 'react-i18next'

const funnelOfSaleOptions = [
  {
    label: 'Topo',
    value: 1,
  },
  {
    label: 'Meio',
    value: 2,
  },
  {
    label: 'Fim',
    value: 3,
  },
]

function StepFirstPass({
  setStep,
  clientData,
  productRef,
  targetAudienceRef,
  publicationDescriptionRef,
  detailRef,
  funelOfSaleRef,
  setselectFunnel,
}) {
  const navigation = useNavigate()

  const [expand, setexpand] = useState(false)

  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [productValue, setProductValue] = useState(undefined)

  const [productTargetAudienceOptions, setProductTargetAudienceOptions] =
    useState([])
  const [targetAudienceValue, setTargetAudienceValue] = useState(undefined)
  const [funnelOfSale, setFunnelOfSale] = useState(undefined)

  const [detailsValue, setDetailsValue] = useState(undefined)
  const [detailsOptions, setDetailsOptions] = useState([])

  const [publicationDescription, setPublicationDescription] = useState('')

  const {t} = useTranslation();

  const validateForm = () => {
    if (!publicationDescription) {
      notifyErrorCustom(t('new_publication_page_first_pass_description_required'))
      return false
    }
    return true
  }

  useEffect(() => {
    loadProductServiceData()
    loadtargetAudienceData()
    loadDetailsData()

    if (productRef.current) {
      setProductValue(productRef.current)
    }

    if (targetAudienceRef.current) {
      setTargetAudienceValue(targetAudienceRef.current)
    }

    if (funelOfSaleRef.current) {
      setselectFunnel(funelOfSaleRef.current.value)
      setFunnelOfSale(funelOfSaleRef.current.value)
    }

    if (detailRef.current) {
      setDetailsValue(detailRef.current)
    }

    if (publicationDescriptionRef.current) {
      setPublicationDescription(publicationDescriptionRef.current)
    }
  }, [clientData])

  const loadDetailsData = async () => {
    setDetailsOptions([
      {
        value: 'Simples',
        label: 'Simples',
      },
      {
        value: 'Mediana',
        label: 'Mediana',
      },
      {
        value: 'Avançado',
        label: 'Avançado',
      },
    ])
  }

  useEffect(() => {}, [])

  const loadProductServiceData = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await ProductService.getProductByNotBusinessClientList()

      const nothingProduct = {
        label: 'Nenhum',
        value: null,
      }

      if (responseStatus === 200) {
        const options = data.map((item) => ({
          ...item,
          price: item.value,
          label: item.name,
          value: item.uuid,
        }))

        const productTypeOptions = [nothingProduct, ...options]

        setProductTypeOptions(productTypeOptions)
      }
    } else {
      const { data, responseStatus } =
        await ProductService.getProductByBusinessClientIdList(clientData?.uuid)
      if (responseStatus === 200)
        setProductTypeOptions(
          data.map((item) => ({
            ...item,
            price: item.value,
            label: item.name,
            value: item.uuid,
          }))
        )
    }

    // setProductValue(productRef.current?.value)
  }

  const loadtargetAudienceData = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByNotClientId()

      const nothingTargetAudience = {
        label: 'Nenhum',
        value: null,
      }

      if (responseStatus === 200) {
        const options = data.map((item) => ({
          ...item,
          label: item.name,
          value: item.uuid,
        }))

        // Adicione nothingProduct como o primeiro objeto na matriz de opções
        const productTypeOptions = [nothingTargetAudience, ...options]

        setProductTargetAudienceOptions(productTypeOptions)
      }
    } else {
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByClientId(
          clientData?.uuid
        )

      if (responseStatus === 200)
        setProductTargetAudienceOptions(
          data.map((item) => ({
            ...item,
            label: item.name,
            value: item.uuid,
          }))
        )
    }

    setTargetAudienceValue(targetAudienceRef.current?.value)
  }

  const HeaderStepRender = () => {
    return (
      <div>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>Nova</p>
          <p className={styles.title_new_posts_title}>Publicação</p>
        </div>

        <div className={styles.title_new_posts_pass}>
          <div>
            <NumberOne width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.label_pass}>{t("new_publication_page_first_pass_pass")}</p>
            <p className={styles.title_pass}>{t("new_publication_page_first_pass_title")}</p>
          </div>
        </div>
      </div>
    )
  }

  const generateMenu = async () => {
    const imgBase64 = []
    let business
    let branding

    if (clientData?.uuid) {
      business = await BusinessService.getBusinessByClientId(clientData?.uuid)

      branding = await BrandingService.getBrandingByBusinessId(business?.uuid)
    } else {
      business = await BusinessService.getBusinessByLogin()
      if (business.data.length > 0) {
        branding = await BrandingService.getBrandingByBusinessId(
          business.data[0].uuid
        )
      }
    }

    const funnel = funnelOfSaleOptions.find(
      (option) => option.value === funnelOfSale
    )

    const { data, responseStatus } = await ProductService.getProductImage64(
      productValue
    )

    data.forEach((image) => {
      imgBase64.push({
        base64: image.fileBase64,
        imageCategory: 'Principal',
        productId: image.productId,
        productName: image.productName,
        productDescription: image.description,
        productValue: image.value,
        productPromotionValue: image.promotionalValue,
      })
    })

    if (responseStatus == 200) {
      let bodyGenerateMenu = {
        complexity: detailsValue,
        funil: funnel.label,
        art_width: 1080,
        art_height: 1080,
        fonte_1: branding.data[0].fontsBase64.fontTitleBase64.name, //'montserrat',
        fonte_2: branding.data[0].fontsBase64.fontSubtitleBase64.name, //'roboto',
        fonte_3: branding.data[0].fontsBase64.fontTextBase64.name, //'protestriot',
        color_palette_1: branding.data[0].primaryColor, //'(183,55,121)',
        color_palette_2: branding.data[0].secondaryColor, //'(252,137,97)',
        color_palette_3: branding.data[0].tertiaryColor, //'(242,243,191)',
        num_imgs: [1],
        img_base64: imgBase64,
        // <<<<<<< HEAD
        //         template_numbers: 1,
        // =======
        //         template_numbers:1,

        //         specificTemplate: editorialLineGenerate.data.data.calendary[qtdLoadTemplates].id+'.json',
        //         niche: businessData.businessInfos.data[qtdLoadTemplates].BusinessNiche.name,
        //         cta : editorialLineGenerate.data.data.calendary[qtdLoadTemplates].cta,
        //         titulo: editorialLineGenerate.data.data.calendary[qtdLoadTemplates].titulo,
        //         subtitulo: editorialLineGenerate.data.data.calendary[qtdLoadTemplates].subtitulo,
        //         corpo_de_texto_1: '',
        //         corpo_de_texto_2: '',
        //         corpo_de_texto_3: '',
        //         linha_conteudo: '',
        //         funil: editorialLineGenerate.data.data.calendary[qtdLoadTemplates].funil,
        //         palavras_destacadas: editorialLineGenerate.data.data.calendary[qtdLoadTemplates].palavras_destacadas,

        // >>>>>>> 7757117ea58a2cd31626f914b18acd058fa23e36
      }

      const generateArtReturn = await PythonService.postGenerateMenu(
        bodyGenerateMenu
      )

      return generateArtReturn
    }
  }
  const storage = JSON.parse(window.localStorage.getItem('userData')).userEmail
  const userInfo = {
    username: storage, // REQUIRED, must be unique
  }

  const ContentStepRender = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          alignItems: 'center',

          padding: '20px',
          paddingBottom: '0px',

          marginRight: '22px',
          height: 'calc(100vh - 246px)',

          overflowY: 'auto',
        }}
      >
        <ProductFruits
          workspaceCode="FzMKHdjfiHGoWeMW"
          language="en"
          user={userInfo}
        />
        <div className={styles.form_container_input}>
          <label className={styles.title_select}>{t("new_publication_page_first_pass_product")}:</label>
          <div className={styles.box_select}>
            <SelectSquare
              htmlFor="select"
              placeholder="Selecione"
              defaultSelected={{
                value: productRef.current?.value,
                label: productRef.current?.label,
              }}
              value={productValue?.value}
              options={productTypeOptions}
              onClickItem={({ value }) => {
                productRef.current = productTypeOptions.find(
                  (e) => e.value === value
                )

                setProductValue(value)
              }}
            />
          </div>
        </div>

        <div
          className={styles.form_container_input}
          style={{
            marginTop: '20px',
          }}
        >
          <label className={styles.title_select}>{t("new_publication_page_first_pass_targuet_audience")}</label>
          <div className={styles.box_select}>
            <SelectSquare
              htmlFor="select"
              placeholder="Selecione"
              defaultSelected={{
                value: targetAudienceRef.current?.value,
                label: targetAudienceRef.current?.label,
              }}
              options={productTargetAudienceOptions}
              onClickItem={({ value }) => {
                targetAudienceRef.current = productTargetAudienceOptions.find(
                  (e) => e.value === value
                )
                setTargetAudienceValue(value)
              }}
            />
          </div>
        </div>

        <div
          className={styles.form_container_input}
          style={{
            marginTop: '20px',
          }}
        >
          <label className={styles.title_select}>
            {t("new_publication_page_first_pass_complexity")}
          </label>
          <div className={styles.box_select}>
            <SelectSquare
              htmlFor="select"
              placeholder="Selecione"
              defaultSelected={{
                value: detailRef.current?.value,
                label: detailRef.current?.label,
              }}
              options={detailsOptions}
              onClickItem={({ value }) => {
                setDetailsValue(value)

                detailRef.current = detailsOptions.find(
                  (e) => e.value === value
                )
              }}
            />
          </div>
        </div>

        <div
          className={styles.form_container_input}
          style={{
            marginTop: '20px',
          }}
        >
          <label className={styles.title_select}>{t("new_publication_page_first_pass_sales_funnel")}</label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
            }}
          >
            {funnelOfSaleOptions.map((item) => (
              <div
                key={item.value}
                className={styles.button_funnel}
                style={{
                  cursor: 'pointer',

                  border: '1px solid #1900271A',
                  padding: '4px 8px',
                  borderRadius: '100px',

                  backgroundColor:
                    funnelOfSale === item.value ? '#1900271A' : '',
                  userSelect: 'none',
                }}
                onClick={() => {
                  if (item.value === funnelOfSale) {
                    funelOfSaleRef.current = undefined
                    return setFunnelOfSale(undefined)
                  }

                  let returnFunnelOfSale = funnelOfSaleOptions.find(
                    (e) => e.value === item.value
                  )

                  funelOfSaleRef.current = returnFunnelOfSale

                  setFunnelOfSale(item.value)
                  setselectFunnel(item.label)
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>

        <div
          className={styles.form_container_input}
          style={{
            marginTop: '20px',
          }}
        >
          <label className={styles.title_select}>{t("new_publication_page_first_pass_description")}</label>
          <textarea
            className={styles.textarea}
            placeholder="Digite aqui a descrição da publicação..."
            value={publicationDescription}
            onChange={(e) => {
              publicationDescriptionRef.current = e.target.value

              setPublicationDescription(e.target.value)
            }}
          ></textarea>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            cursor: 'pointer',

            marginTop: '10px',
          }}
          onClick={() => {
            setexpand(!expand)
          }}
        >
          {expand ? (
            <p className={styles.more_options_text}> Menos Opções</p>
          ) : (
            <p className={styles.more_options_text}>{t("new_publication_page_first_pass_more_options")}</p>
          )}
          <div className={expand ? styles.box_rotate : styles.box_arrow}>
            <ArrowDown color={'#190027'} />
          </div>
        </div>

        <div
          className={styles.form_more_options}
          style={{ display: expand ? '' : 'none', marginBottom: '40px' }}
        >
          {/* <div
            className={styles.button_new_publication}
            onClick={() => {
              setStep('stepMyPublication')
            }}
          >
            <div className={styles.button_new_publication_icon}>
              <CloundUp color={'#E6E6E6'} size={36} />
            </div>
            <div className={styles.button_new_publication_info}>
              <div className={styles.button_new_publication_info_title}>
                <h2
                  style={{
                    fontSize: '18px',
                  }}
                >
                  Nova publicação
                </h2>
              </div>
              <div className={styles.button_new_publication_info_description}>
                <p
                  style={{
                    fontSize: '13px',
                    margin: '0px',
                    marginTop: '4px',
                  }}
                >
                  Opção destinada caso você já tenha sua publicação pronta e
                  deseja realizar o agendamento
                </p>
              </div>
            </div>
          </div> */}

          <div
            className={styles.button_new_publication}
            onClick={() => {
              if (!validateForm()) return
              setStep('stepNinche')
            }}
          >
            <div className={styles.button_new_publication_icon}>
              <div>
                <Pen2 color={'#E6E6E6'} />
              </div>
            </div>
            <div className={styles.button_new_publication_info}>
              <div className={styles.button_new_publication_info_title}>
                <h2
                  style={{
                    fontSize: '18px',
                  }}
                >
                  {t('new_publication_page_first_pass_menu')}
                </h2>
              </div>
              <div className={styles.button_new_publication_info_description}>
                <p
                  style={{
                    fontSize: '13px',
                    margin: '0px',
                    marginTop: '4px',
                  }}
                >
                  {t('new_publication_page_first_pass_menu_description')}
                </p>
              </div>
            </div>
          </div>
          {/* <div className={styles.button_advanced_editor}>
            <div className={styles.button_advanced_editor_icon}>
              <Pen2 width={50} height={50} color={'#292929'} />
            </div>
            <div className={styles.button_advanced_editor_info}>
              <div className={styles.button_advanced_editor_info_title}>
                <h2>Editor avançado</h2>
              </div>
              <div className={styles.button_advanced_editor_info_description}>
                <p>
                  Crie uma nova publicação com suas próprias habilidades de
                  design
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    )
  }

  const FooterStepRender = () => {
    return (
      <div
        style={{
          marginRight: '22px',
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              navigation('/NewPostPage')
            }}
          >
            <p className={styles.button_title}>{t("cancel_buttom")}</p>
          </button>
          <button
            className={styles.button_next}
            onClick={() => {
              if (!validateForm()) return
              setStep('stepImage')
            }}
          >
            {t("proceed_buttom")}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container_header}>
      {HeaderStepRender()}
      {ContentStepRender()}
      {FooterStepRender()}
    </div>
  )
}

export default StepFirstPass
