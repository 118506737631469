import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const fetchGoogleFonts = async () => {
  const apiKey = 'AIzaSyCfUpy5OtYOl6JXhnjUsZye4Ifs-1x3q1k';
  const url = `https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`;
  
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data.items; // Retorna as fontes
  } catch (error) {
    console.error('Error fetching Google Fonts:', error);
    return []; // Retorna um array vazio no caso de erro
  }
};
