/* eslint-disable react/prop-types */
/* global FB */
import { useState, useEffect } from 'react'
import styles from './styles.module.css'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import xamaLogoBranco from '../../assets/logos/xama-logo-branco.png'
import AccountSelectionPage from '../AccountSelectionPage'

import 'react-toastify/dist/ReactToastify.css'

import {
  FavoriteStarsPress,
  BoxNotChecket,
  BoxChecket,
  NavigateArrowLeft,
  NavigateArrowRigth2,
  Cross,
} from '../../assets/svgs/icons'

import { Login } from '../../services/index'

import { useAuth } from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

import Loading from '../../components/Loading'
import { Apple, Facebook, GoogleIconLigin } from '../../assets/svgs/icons'

import { notifyErrorCustom } from '../../utils/notify'
import { ModalComponent } from '../../components'
import DefaulterPage from '../DefaulterPage'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../../components/languageSwitcher'

function SignIn() {
  const { login } = useAuth()
  const navigation = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // const [email, setEmail] = useState(
  //   'auditoria@marlieclaricecontabilltda.com.br'
  // )
  // const [password, setPassword] = useState('123321123')

  const [loading, setLoading] = useState(false)

  const [currentIndex, setCurrentIndex] = useState(0)

  const [rememberMe, setRememberMe] = useState(false)

  const [usersTologin, setUsersToLogin] = useState(false)

  const [bodyLoginUser, setBodyLoginUser] = useState(false)

  const [userDefaulter, setUserDefaulter] = useState([])

  const [guestUser, setGuestUser] = useState(false)

  const initFacebookSDK = () => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: '387543906967686',
        cookie: true,
        xfbml: true,
        version: 'v18.0',
      })

      FB.AppEvents.logPageView()
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  useEffect(() => {
    initFacebookSDK()
  }, [])

  useEffect(() => {}, [userDefaulter])

  const items = [
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQXee_rryoDjgcKEVedms5RLsOHsMLkOR4hUHZ_W9j3PTme-yS_Nm8bp5H-ZSurIFngfI&usqp=CAU',
      user: 'Nome Usuário',
      text: 'Lorem ipsum dolor sit amet consectetur. Amet lorem fringilla ullamcorper in. Facilisis proin sit egestas elit nunc enim integer.',
      avaliacao: 5,
    },
    {
      image:
        'https://i.pinimg.com/736x/4b/3f/b0/4b3fb0a060d2a02d84e184ea6051794d--rocket-raccoon-raccoons.jpg',
      user: 'Nome Usuário',
      text: 'Lorem ipsum dolor sit amet consectetur. Amet lorem fringilla ullamcorper in. Facilisis proin sit egestas elit nunc enim integer.',
      avaliacao: 4,
    },
  ]

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleCreateAccount = () => {
    navigation('/FastPublishing')
  }

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    )
  }

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    )
  }

  const handleChecked = () => {
    setRememberMe((current) => !current)
  }

  const checkFields = () => {
    if (email === '') {
      notifyErrorCustom('O campo de e-mail não pode estar vazio')
      return false
    }

    if (password === '') {
      notifyErrorCustom('O campo de senha não pode estar vazio')
      return false
    }

    if (password.length < 8) {
      notifyErrorCustom('Usuário ou senha incorretos')
      return false
    }

    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    handleLogin()
  }

  const handleLogin = async () => {
    if (!checkFields()) {
      return setLoading(false)
    }

    let body = {
      email: email,
      password: password,
    }
    const checkUsers = await Login.postCheckUser(body)

    console.log('checkUsers', checkUsers)

    if (
      checkUsers.data.isDefaulter &&
      checkUsers.data.userBusiness.length <= 0
    ) {
      const userDefaulter = {
        userDefaulter: checkUsers.data.user,
        guestUser: false,
      }
      setUserDefaulter(userDefaulter)
      setGuestUser(false)
      setLoading(false)

      return
    }

    if (
      ((checkUsers.data.user != null &&
        checkUsers.data.userBusiness.length > 0) ||
        checkUsers.data.userBusiness.length > 1) &&
      checkUsers.data.passwordOk
    ) {
      await setUsersToLogin(checkUsers)
      await setBodyLoginUser(body)
    } else if (
      !checkUsers.data.user &&
      checkUsers.data.userBusiness.length == 1 &&
      checkUsers.data.passwordOk
    ) {
      const uuid = checkUsers.data.userBusiness[0].uuid
      const bodyToLogin = { ...body, uuid }
      let loginGuestData = await Login.postGuestComparePassword(bodyToLogin)
      if (loginGuestData?.responseStatus === 200) {
        const guestUserId = uuid
        const loginDataRet = { ...loginGuestData.data, guestUserId }
        setLoading(false)
        login(loginDataRet)
      }
    } else if (!checkUsers.data.passwordOk) {
      notifyErrorCustom('Usuário ou senha incorretos')
      setLoading(false)
      return
    } else {
      let loginData = await Login.postAuth(body)

      if (loginData?.responseStatus === 200) {
        setLoading(false)
        const loginDataRet = { ...loginData.data }
        login(loginDataRet)
      }

      if (loginData?.response?.status === 401) {
        notifyErrorCustom('Usuário ou senha incorretos')
      }
    }

    setLoading(false)
  }

  const SocialMediaLogin = async (item) => {
    if (item === 'facebook') {
      FB.getLoginStatus(function (response) {
        // statusChangeCallback(response);
      })

      FB.login(
        function (response) {
          // handle the response
        },
        { scope: 'public_profile,email' }
      )
    }
  }

  const [isOpen, setIsOpen] = useState(false)

  const handlePopUp = () => {
    setIsOpen(!isOpen)
  }

  const {t} = useTranslation();

  return (
    <motion.div
      // key={expanded}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%', height: '100%' }}
    >
      <div>
        {userDefaulter.userDefaulter ? (
          <DefaulterPage userDefaulter={userDefaulter} guestUser={guestUser} />
        ) : (
          <div className={!usersTologin ? styles.page : ''}>
            <Loading enable={loading} />

            {usersTologin && (
              <AccountSelectionPage
                usersTologin={usersTologin}
                bodyLoginUser={bodyLoginUser}
              />
            )}
            {!usersTologin && (
              <div className={styles.sign_in_field}>
                <div className={styles.sign_box_field}>
                  <div className={styles.sign_box}>
                    <div className={styles.postei_logo_field}>
                      <img
                        src={posteiLogo}
                        alt="posteiLogo"
                        style={{ height: 40 }}
                      />
                      <img
                        src={xamaLogoBranco}
                        alt="posteiLogoText"
                        style={{ height: 40 }}
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        marginTop: 40,
                      }}
                    >
                      <div className={styles.sign_box_header}>
                        <p style={{ fontSize: 22, fontWeight: 500, margin: 0 }}>
                          {/* Crie publicações incríveis com auxílio de */}
                          {t("signin_description")}
                        </p>
                        <p style={{ fontSize: 25, fontWeight: 700, margin: 0 }}>
                          {/* Inteligência Artificial */}
                          {t("signin_description_bold")}
                          
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',

                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <div className={styles.sign_box_body}>
                          <div className={styles.container_caroussel_users}>
                            <div
                              className={styles.carousel_button}
                              onClick={goToPrevious}
                            >
                              <NavigateArrowLeft color={'#FFFFFF'} />
                            </div>
                            <div className={styles.content_caroussel_users}>
                              <img
                                className={styles.sign_box_body_image}
                                src={items[currentIndex].image}
                                alt={items[currentIndex].user}
                              />
                              <h2>{items[currentIndex].user}</h2>
                              <p>{items[currentIndex].text}</p>
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 8,
                                  marginTop: 18,
                                }}
                              >
                                {[...Array(items[currentIndex].avaliacao)].map(
                                  (_, index) => (
                                    <FavoriteStarsPress
                                      key={index}
                                      color={'#fff'}
                                    />
                                  )
                                )}
                              </div>
                            </div>

                            <div
                              className={styles.carousel_button}
                              onClick={goToNext}
                            >
                              <NavigateArrowRigth2 color={'#FFFFFF'} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.sign_in_img_field}>
                  <div className={styles.sign_box_login}>
                    <div className={styles.box_langageSwitcher}>
                      <LanguageSwitcher/>
                    </div>

                    <div className={styles.container_content}>

                      <div className={styles.sign_in_form_field}>
                        <p
                          style={{
                            fontSize: 35,
                            color: '#440063',
                            marginBottom: 20,
                            fontWeight: 500,
                          }}
                        >
                          Login
                        </p>
                        <form
                          className={styles.input_field}
                          onSubmit={handleSubmit}
                        >
                          <input
                            className={styles.input}
                            type="text"
                            placeholder="E-mail"
                            value={email}
                            onChange={handleEmailChange}
                          />
                          <input
                            className={styles.input}
                            type="password"
                            placeholder="Senha"
                            value={password}
                            onChange={handlePasswordChange}
                          />

                          <div className={styles.control_password}>
                            <div className={''}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: 8,
                                }}
                              >
                                <div
                                  style={{
                                    cursor: 'pointer',
                                    marginTop: 4,
                                  }}
                                  onClick={handleChecked}
                                >
                                  {rememberMe ? (
                                    <BoxChecket
                                      width={20}
                                      height={20}
                                      borderColor={'#190027'}
                                    />
                                  ) : (
                                    <BoxNotChecket
                                      width={20}
                                      height={20}
                                      borderColor={'#190027'}
                                    />
                                  )}
                                </div>
                                <label style={{ fontSize: 12 }}>
                                
                                  {t("signin_remember_me")}
                                </label>
                              </div>
                            </div>
                            {/* <p
                          style={{
                            color: '#FF2F00',
                            fontSize: 12,
                            margin: 0,
                            cursor: 'pointer',
                          }}
                          onClick={handlePopUp}
                        >
                          Perdeu sua senha?
                        </p> */}
                          </div>

                          <ModalComponent
                            isOpen={isOpen}
                            onClose={() => setIsOpen(false)}
                            className={styles.teste}
                          >
                            <div className={styles.container_pop_up}>
                              <div className={styles.header_pop_up}>
                                <div
                                  onClick={() => setIsOpen(false)}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  <Cross />
                                </div>
                              </div>

                              <div>
                                <h1 className={styles.title_pop_up}>Atenção</h1>
                                <p className={styles.text_pop_up}>
                                  Será enviado um e-mail para a conta informada,
                                  com as instruções para a recuperação de sua
                                  senha.
                                </p>
                              </div>

                              <div className={styles.box_input_pop_up}>
                                <input
                                  className={styles.input_password_recovery}
                                  type="text"
                                  placeholder="Digite seu e-mail"
                                />
                              </div>

                              <div className={styles.button_pop_up}>
                                <button className={styles.button_pop_up_cancel}>
                                  Cancelar
                                </button>
                                <button className={styles.button_pop_up_send}>
                                  Enviar
                                </button>
                              </div>
                            </div>
                          </ModalComponent>

                          <button className={styles.button_submit} type="submit">
                            {t("signin_login")}
                          </button>
                        </form>
                      </div>

                      <div className={styles.sign_box_footer}>
                        <p
                          style={{
                            margin: '0px',
                            color: '#440063',
                            fontWeight: 500,
                            fontSize: 16,
                          }}
                        >
                          {t("signin_dont_have_an_account")}
                        </p>
                        <a
                          onClick={handleCreateAccount}
                          style={{
                            color: '#0FBE00',
                            textDecoration: 'underline',
                            fontSize: 16,
                            fontFamily: 'Poppins',
                            fontWeight: 500,
                            cursor: 'pointer',
                          }}
                        >
                          {t("signin_register")}
                        </a>
                      </div>
                      <div className={styles.info_login}>
                        <p
                          style={{
                            fontSize: 16,
                            textAlign: 'center',
    
                            color: '#440063',
                            marginBottom: 10,
                          }}
                        >
                          {t("signin_terms_1")} &nbsp;
                          <a
                            // href="https://app.xama.ai/TermsOfUse"
                            href="/TermsOfUse"
                            target="_blank" // Abre o link em uma nova aba
                            rel="noopener noreferrer" // Melhora a segurança para links externos
                            style={{
                              color: '#440063',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {t("signin_terms_2")}
                          </a>
                          &nbsp;{t("signin_terms_3")} &nbsp;
                          <a
                            href="/PrivacyPolicies"
                            target="_blank" // Abre o link em uma nova aba
                            rel="noopener noreferrer" // Melhora a segurança para links externos
                            style={{
                              color: '#440063',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {t("signin_terms_4")}
                          </a>
                        </p>
                      </div>
                    </div>



                    {/* <div className={styles.field_other_login}>
                  <div className={styles.other_login_box}>
                    <GoogleIconLigin width={20} height={20} />
                  </div>

                  <div
                    className={styles.other_login_box}
                    onClick={() => SocialMediaLogin('facebook')}
                  >
                    <Facebook width={20} height={20} />
                  </div>

                  <div className={styles.other_login_box}>
                    <Apple width={20} height={20} />
                  </div>
                </div> */}

                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default SignIn
