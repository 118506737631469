import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { NavigateArrowLeft } from '../../assets/svgs/icons'
import { useTranslation } from 'react-i18next'

const Calendar = ({
  setSelectedDate,
  selectedDate,
  scheduledDays,
  dateSchedule,

  currentYear,
  setCurrentYear,
  currentMonth,
  setCurrentMonth,
}) => {
  const { t } = useTranslation()

  const monthNames = [
    t('calendar.january'),
    t('calendar.february'),
    t('calendar.march'),
    t('calendar.april'),
    t('calendar.may'),
    t('calendar.june'),
    t('calendar.july'),
    t('calendar.august'),
    t('calendar.september'),
    t('calendar.october'),
    t('calendar.november'),
    t('calendar.december'),
  ]

  // const [currentDate] = useState(new Date()) // Get the current date

  // const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  // const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth())

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

  const today = new Date()

  useEffect(() => {
    if (dateSchedule) {
      setSelectedDate(removerHoraEUTC(dateSchedule))
    }
  }, [dateSchedule, currentMonth])

  function removerHoraEUTC(data) {
    // Converte a data para uma string
    const dataString = data.toString()

    // Extrai apenas a parte da data, removendo a hora e o fuso horário
    const dataSemHoraEUTC = dataString.split(' ').slice(0, 4).join(' ')

    return dataSemHoraEUTC
  }

  const RenderDays = () => {
    const days = []

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day)
      let isPastDate = false

      if (
        date.getDate() < today.getDate() &&
        date.getFullYear() <= today.getFullYear() &&
        date.getMonth() <= today.getMonth()
      ) {
        isPastDate = true
      } else if (
        date.getFullYear() <= today.getFullYear() &&
        date.getMonth() < today.getMonth()
      ) {
        isPastDate = true
      }

      const isCurrentDate = date.toDateString() === today.toDateString()

      //compare uma data
      const isSelected = date.toDateString() == selectedDate

      // compare muitas datas

      // const isSelected = selectedDates.some(
      //   (selectedDate) => {
      //     return  new Date(selectedDate).toDateString() === date.toDateString()
      //   }
      // )

      days.push(
        <div
          className={`${styles.day_box} ${
            isCurrentDate && isSelected
              ? styles.selected
              : isCurrentDate
              ? styles.current_day
              : isSelected && isPastDate
              ? styles.selectedDisabled
              : isSelected
              ? styles.selected
              : isPastDate
              ? styles.disabled
              : ''
          }`}
          key={day}
          onClick={() => handleDateClick(date)}
        >
          <div
            style={{
              width: '60%',
              display: 'flex',

              justifyContent: 'end',
            }}
          >
            <div>
              {findDate(
                date.getMonth() + 1,
                day,
                date.getFullYear(),
                scheduledDays?.find(
                  (dateData) =>
                    dateData ===
                    `${day}/${date.getMonth() + 1}/${date.getFullYear()}`
                )
              ) && (
                <div
                  style={{
                    width: '13px',
                    height: '13px',
                    background: 'orange',
                    borderRadius: '50%',
                  }}
                />
              )}
            </div>
          </div>
          <div className={`${styles.day} ${isSelected ? styles.selected : ''}`}>
            {day}
          </div>
        </div>
      )
    }

    return days
  }

  function handleNextMonth() {
    if (currentMonth === 11) {
      setCurrentMonth(0)
      setCurrentYear(currentYear + 1)
    } else {
      setCurrentMonth(currentMonth + 1)
    }
  }

  function handlePreviusMonth() {
    if (currentMonth === 0) {
      setCurrentMonth(11)
      setCurrentYear(currentYear - 1)
    } else {
      setCurrentMonth(currentMonth - 1)
    }
  }

  function handleDateClick(date) {
    setSelectedDate(date?.toDateString())
  }

  const findDate = (mes, dia, ano, verifyDate) => {
    // let date = dia + '/' + mes + '/' + ano

    // let verifyDate = scheduledDays?.find((dateData) => dateData === date)

    if (verifyDate) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={styles.calendar_wrapper}>
      <div className={styles.calendar_header}>
        <div
          className={styles.calendar_button_navigate}
          onClick={handlePreviusMonth}
        >
          <NavigateArrowLeft width={12} height={12} color={'#000'} />
        </div>
        <h3>{monthNames[currentMonth] + ' ' + currentYear}</h3>
        <div
          className={styles.calendar_button_navigate}
          onClick={handleNextMonth}
          style={{ rotate: '180deg' }}
        >
          <NavigateArrowLeft width={12} height={12} color={'#000'} />
        </div>
      </div>
      <div className={styles.calendar}>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>D</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>S</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>T</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>Q</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>Q</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>S</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>S</b>
        </div>

        {new Array(new Date(currentYear, currentMonth, 1).getDay())
          .fill(null)
          .map((_, index) => (
            <div
              key={`empty-${index}`}
              className={`${styles.empty_day} ${styles.disabled} `}
            ></div>
          ))}

        <RenderDays />

        {new Array(
          6 - new Date(currentYear, currentMonth, daysInMonth).getDay()
        )
          .fill(null)
          .map((_, index) => (
            <div
              key={`empty-${daysInMonth + index}`}
              className={`${styles.empty_day} ${styles.disabled} `}
            ></div>
          ))}
      </div>
    </div>
  )
}

export default Calendar
