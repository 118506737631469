import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
// import { useState } from 'react'

import { CheckBadge } from '../../../../assets/svgs/icons'
// import { ButtonNavigateStep } from '../../../../components/ButtonNavigateStep'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
  STEP_COMPLETED: 'completed',
  STEP_CAMPAIGN_LIST: 'CampaignList',
}

function StepCompleted({ setSteps, campaignItem }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  function handleNavigate() {
    // setSteps(STEPS.STEP_CAMPAIGN_LIST)

    navigate('/NewPublicationPageCampaign', { state: { campaignItem } })
  }

  return (
    <div className={styles.container_parameterizations}>
      <div className={styles.box_parameterization_content}>
        <div className={styles.container_parameterizations_content}>
          <CheckBadge color={'#0FBE00'} size="48" />
          <p className={styles.title_for_completed_configurations}>
            {t('campaign_step_completed_title')}
          </p>
          <div className={styles.description_text}>
            <p className={styles.content_text}>
              {t('campaign_step_completed_description_text_1')}
            </p>
            <p className={styles.content_text}>
              {t('campaign_step_completed_description_text_2')}
            </p>
          </div>

          <button
            className={styles.button_finish_parameterizations}
            onClick={() => handleNavigate()}
          >
            {t('campaign_step_completed_button')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default StepCompleted
