/* eslint-disable react/prop-types */
import { CheckBadgeCircle } from '../../assets/svgs/icons'
import style from './styles.module.css'
import { useTranslation } from 'react-i18next'

const Stepper = ({
  size,
  completedStepParametrization,
  completedStepAddClients,
  completedStepAddTeamMembers,
  completedStepCreateCampaign,
  completedStepAddNewPost,
}) => {
  const { t } = useTranslation()

  return (
    <div style={{}}>
      <div className={style.container_stepper}>
        <div className={style.box_stepper}>
          <div className={style.box_checked}>
            <div className={style.fixing_bar}></div>
            {completedStepParametrization ? (
              <div className={style.validator_stepper}>
                <CheckBadgeCircle size={size} />
              </div>
            ) : (
              <div className={style.validator_stepper_off}>
                <p className={style.number_of_steppe}>{'1'}</p>
              </div>
            )}
          </div>
          <div className={style.box_title_steppe}>
            <p className={style.title_steppe}>
              {t('settings.settings_stepper.title')}
            </p>
            <p className={style.label_steppe}>
              {t('settings.settings_stepper.description')}
            </p>
          </div>
        </div>
      </div>

      <div className={style.box_stepper} style={{ marginTop: 16 }}>
        <div className={style.box_checked}>
          <div className={style.fixing_bar}></div>
          {completedStepAddClients ? (
            <div className={style.validator_stepper}>
              <CheckBadgeCircle size={size} />
            </div>
          ) : (
            <div className={style.validator_stepper_off}>
              <p className={style.number_of_steppe}>{'2'}</p>
            </div>
          )}
        </div>
        <div className={style.box_title_steppe}>
          <p className={style.title_steppe}>
            {t('settings.settings_stepper.clients.title')}
          </p>
          <p className={style.label_steppe}>
            {t('settings.settings_stepper.clients.description')}
          </p>
        </div>
      </div>

      <div className={style.box_stepper} style={{ marginTop: 16 }}>
        <div className={style.box_checked}>
          <div className={style.fixing_bar}></div>
          {completedStepAddTeamMembers ? (
            <div className={style.validator_stepper}>
              <CheckBadgeCircle size={size} />
            </div>
          ) : (
            <div className={style.validator_stepper_off}>
              <p className={style.number_of_steppe}>{'3'}</p>
            </div>
          )}
        </div>
        <div className={style.box_title_steppe}>
          <p className={style.title_steppe}>
            {t('settings.settings_stepper.team_members.title')}
          </p>
          <p className={style.label_steppe}>
            {t('settings.settings_stepper.team_members.description')}
          </p>
        </div>
      </div>

      <div className={style.box_stepper} style={{ marginTop: 16 }}>
        <div className={style.box_checked}>
          <div className={style.fixing_bar}></div>
          {completedStepCreateCampaign ? (
            <div className={style.validator_stepper}>
              <CheckBadgeCircle size={size} />
            </div>
          ) : (
            <div className={style.validator_stepper_off}>
              <p className={style.number_of_steppe}>{'4'}</p>
            </div>
          )}
        </div>
        <div className={style.box_title_steppe}>
          <p className={style.title_steppe}>
            {t('settings.settings_stepper.create_campaign.title')}
          </p>
          <p className={style.label_steppe}>
            {t('settings.settings_stepper.create_campaign.description')}
          </p>
        </div>
      </div>

      <div className={style.box_stepper} style={{ marginTop: 16 }}>
        <div className={style.box_checked}>
          <div className={style.fixing_bar}></div>
          {completedStepAddNewPost ? (
            <div className={style.validator_stepper}>
              <CheckBadgeCircle size={size} />
            </div>
          ) : (
            <div className={style.validator_stepper_off}>
              <p className={style.number_of_steppe}>{'5'}</p>
            </div>
          )}
        </div>
        <div className={style.box_title_steppe}>
          <p className={style.title_steppe}>
            {t('settings.settings_stepper.add_new_post.title')}
          </p>
          <p className={style.label_steppe}>
            {t('settings.settings_stepper.add_new_post.description')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Stepper
