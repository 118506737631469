import { useState } from 'react'
import styles from './styles.module.css'
import { Eye, Interrogation } from '../../../assets/svgs/icons'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import { motion } from 'framer-motion'

import { UserService } from '../../../services'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'

function StepPassword({
  setPass,
  setConfirmPass,
  pass,
  confirmPass,
  setStep,
  wordsArray,
  phone,
  email,
  setUserData,
  setQuickRegistration,
}) {
  const [showPass, setShowPass] = useState(false)
  const [showPassConfirm, setShowPassConfirm] = useState(false)

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nome e Sobrenome',
      current: true
    },
    {
      pass: '2º Passo',
      title: 'Email',
      current: true
    },
    {
      pass: '3º Passo',
      title: 'Telefone',
      current: true
    },
    {
      pass: '4º Passo',
      title: 'Senha',
      current: true
    },
    {
      pass: '5º Passo',
      title: 'CPF',
      current: false
    },
    {
      pass: '6º Passo',
      title: 'Endereço',
      current: false
    },
  ];

  const handlePass = (type) => {
    if (type === 'pass') {
      setShowPass(!showPass)
    } else {
      setShowPassConfirm(!showPassConfirm)
    }
  }

  const STEPS = {
    STEP_PASS_PHONE: 'stepPhone',
    STEP_PASS_CPF: 'stepCPF',
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (pass === '' || confirmPass === '') {
        notifyErrorCustom(t('fast_publishing_pass_enter_your_password_alert'))
        return
      }
      if (pass.length < 9 || confirmPass.length < 9) {
        notifyErrorCustom(t('fast_publishing_pass_short_password_alert'))
        return
      }
      if (pass != confirmPass) {
        notifyErrorCustom(t('fast_publishing_pass_confirmation_password_alert'))
        return
      }

      setStep(STEPS.STEP_PASS_CPF)
    } else {
      setStep(STEPS.STEP_PASS_PHONE)
    }
  }

  const {t} = useTranslation();

  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        {/* <PreviousPass steps={stepsArray}/> */}
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>{t("fast_publishing_register_title")}</p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>{t("fast_publishing_create_account")}</h1>

          <div className={styles.box_input_password}>
            <p>{t("fast_publishing_pass_subtitle")}</p>
            <div className={styles.container_input_password}>
              <div
                className={styles.custom_input}
                type="text"
                placeholder="Senha"
              >
                <input
                  className={styles.input_password}
                  type={showPass ? 'text' : 'password'}
                  placeholder="Senha"
                  value={pass}
                  onChange={(e) => setPass(e.currentTarget.value)}
                />
                <div
                  className={styles.icon_input}
                  onClick={() => handlePass('pass')}
                >
                  <Eye />
                </div>
              </div>

              <div
                className={styles.custom_input}
                type="text"
                placeholder="Senha"
              >
                <input
                  className={styles.input_password}
                  type={showPassConfirm ? 'text' : 'password'}
                  placeholder="Confirmar senha"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.currentTarget.value)}
                />
                <div
                  className={styles.icon_input}
                  onClick={() => handlePass('passConfirm')}
                >
                  <Eye />
                </div>
              </div>
            </div>

            <div className={styles.box_information}>
              <Interrogation color={'#FF8B00'} width={20} height={20} />
              <p>{t("fast_publishing_pass_tip")}</p>
            </div>
          </div>
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
           {t("back_buttom")}
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
           {t("next_buttom")}
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepPassword
