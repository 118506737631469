import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'
import Logo from '../../../assets/logos/postei-logo-color.png'
import { motion } from 'framer-motion'
import { UserService } from '../../../services'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'

function StepSubject({ setSubject, subject, setStep }) {
  const STEPS = {
    STEP_VOICE_TONE: 'stepVoiceTone',
    STEP_PASS_LOGO: 'stepLogo',
  }

  const {t} = useTranslation();

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: true
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: true
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: true
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: true
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: true
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: false
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: false
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: false
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: false
    },
  ];

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (subject === '') {
        notifyErrorCustom(t('fast_publishing_enter_your_subject'))
        return
      }

      setStep(STEPS.STEP_PASS_LOGO)
    } else {
      setStep(STEPS.STEP_VOICE_TONE)
    }
  }
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        {/* <PreviousPass steps={stepsArray}/> */}
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>{t("fast_publishing_subject_text")}</p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>{t("fast_publishing_voice_tone")}</h1>

          <div className={styles.box_input_email}>
            <p
              style={{
                'font-family': 'Poppins',
                'font-size': '16px',
                'font-weight': '500',
                'line-height': '24px',
                'text-align': 'left',
              }}
            >
              {t("fast_publishing_subject_subtitle")}
            </p>

            <div className={styles.container_imput_component}>
              <div className={styles.box_logo_imput}>
                <img src={Logo} className={styles.logo_imput} />
              </div>
              <input
                className={styles.input_email}
                type="text"
                value={subject}
                placeholder={t("fast_publishing_subject_placeholder")}
                onChange={(e) => setSubject(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            {t("back_buttom")}
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            {t("next_buttom")}
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepSubject
