import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Clipboard, Clock, Cross, Trash } from '../../assets/svgs/icons'

export const PopUp = ({
  setSelectObjectCalendar,
  isOpen,
  setIsOpen,
  contentPublication,
}) => {
  function hadleShowPubication(item) {
    setSelectObjectCalendar(item)

    togglePopup()
  }

  const togglePopup = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.popup}>
      <div className={styles.popup_content}>
        <div className={styles.popup_header}>
          <h2 style={{ color: '#000' }}>Agendamentos</h2>
          <button
            onClick={togglePopup}
            style={{
              color: '#000',
              background: 'transparent',
              outline: 'none',
              border: 'none',
            }}
          >
            <Cross />
          </button>
        </div>

        <div className={styles.popup_content_data}>
          {contentPublication.map((item, key) => (
            <div
              key={key}
              className={styles.schedule_of_the_day}
              style={{
                background:
                  item.status === 'Agendado'
                    ? '#FF8B001A'
                    : item.status === 'Publicado'
                    ? '#3CBC001A'
                    : '#BC00001A',
              }}
              onClick={() => hadleShowPubication(item)}
            >
              <p
                key={item.id}
                style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                  cursor: 'pointer',
                  color:
                    item.status === 'Agendado'
                      ? '#FF8B00'
                      : item.status === 'Publicado'
                      ? '#3CBC00'
                      : '#FF2F00',
                }}
              >
                <div>
                  {item.status === 'Publicado' ? (
                    <Clipboard color={'#3CBC00'} />
                  ) : item.status === 'Agendado' ? (
                    <Clock color={'#FF8B00'} />
                  ) : (
                    <Trash color={'#FF2F00'} />
                  )}
                </div>
                {item?.title
                  ? item?.title
                  : item?.videoUrl
                  ? `Vídeo Próprio - ${item.hour}`
                  : `Publicação Própria - ${item.hour}`}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
