import styles from './styles.module.css'

import { useNavigate } from 'react-router-dom'
import {
  Accept,
  Interrogation,
  MultiplePhotos,
  Picture,
  UploadIcon,
} from '../../assets/svgs/icons'
import {
  PublicationService,
  SubscriptionService,
  TimelineActionsService,
} from '../../services'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../utils/notify'
import { useState } from 'react'
import Loading from '../../components/Loading'
import { motion } from 'framer-motion'

import { ProductFruits } from 'react-product-fruits'
import { useTranslation } from 'react-i18next'




function NewPostPage() {
  const navigation = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleNavigate = async (route) => {
    setLoading(true)
    const publicationCount = await PublicationService.getAllPublicationCount()
    const schedulingCount =
      await TimelineActionsService.getAllTimelineCountPublication()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    if (
      userSubscription.data.length <= 0 &&
      (publicationCount.data.count >= 8 || schedulingCount.data.count >= 60)
    ) {
      notifyWarningCustom(
        'Você atingiu o limite para criar novas postagens, consulte nossos planos para mais informações!'
      )
      setLoading(false)
      return
    } else if (userSubscription.data.length > 0) {
      const indexPost = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'post_quantity'
      )
      const quantityItemPost =
        userSubscription.data[0].Plan.PlanItems[indexPost].quantity
      const indexSchedulling =
        await userSubscription.data[0].Plan.PlanItems.findIndex(
          (item) => item.type === 'scheduling_quantity'
        )
      const quantityItemSchedulling =
        userSubscription.data[0].Plan.PlanItems[indexSchedulling].quantity

      if (
        publicationCount.data.length >= quantityItemPost &&
        quantityItemPost != null
      ) {
        notifyWarningCustom(
          'Você atingiu o limite para criar novas postagens, consulte nossos planos para mais informações!'
        )
        setLoading(false)
        return
      }

      if (
        schedulingCount.data.count >= quantityItemSchedulling &&
        quantityItemSchedulling != null
      ) {
        notifyWarningCustom(
          'Você atingiu o limite para criar novos agendamentos, consulte nossos planos para mais informações!'
        )
        setLoading(false)
        return
      }
    }

    navigation(`${route}`)
    setLoading(false)
  }
  const storage = JSON.parse(window.localStorage.getItem('userData')).userEmail
  const userInfo = {
    username: storage, // REQUIRED, must be unique
  }

  const {t} = useTranslation();

  return (
    <motion.div
      // key={expanded}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%' }}
    >
      <div className={styles.page}>
        <Loading enable={loading} />
        <div className={styles.container_new_posts}>
          <div className={styles.title_new_posts}>
            <p className={styles.label_page}>{t("new_post_page_new")}</p>
            <p className={styles.title_page}>{t("new_post_page_post")}</p>
          </div>
          <div className={styles.container_card}>
            <div
              className={styles.card_post}
              style={{
                backgroundImage:
                  'linear-gradient(to bottom right, #A6A6A6,#808080 , #595959)',
              }}
            >
              <div className={styles.card_post_header}>
                <div
                  className={styles.card_post_icon}
                  style={{ background: '#a1a1a1' }}
                >
                  <UploadIcon />
                </div>
                <Interrogation color="#fff" />
              </div>
              <div className={styles.card_post_body}>
                <h2>{t("new_post_page_first_card_title")}</h2>
                <p
                  style={{
                    width: '100%',
                  }}
                >
                  {t("new_post_page_first_card_subtitle")}
                </p>
              </div>
              <div className={styles.card_post_footer}>
                <button
                  className={styles.button_select}
                  style={{ borderRadius: 4 }}
                  onClick={() => {
                    // handleNavigate('/NewPostFormPage')
                    handleNavigate('/NewPublicationPageMyPublication')
                  }}
                >
                  {t("new_post_page_button_select")} <Accept color={'#00B828'} />
                </button>
              </div>
            </div>

            <div className={styles.card_post}>
              <div className={styles.card_post_header}>
                <div
                  className={styles.card_post_icon}
                  style={{ background: '#841bb2' }}
                >
                  <Picture />
                </div>
                <Interrogation color="#fff" />
              </div>
              <div className={styles.card_post_body}>
                <h2>{t("new_post_page_second_card_title")}</h2>
                <p>{t("new_post_page_second_card_subtitle")}</p>
              </div>
              <div className={styles.card_post_footer}>
                <button
                  className={styles.button_select}
                  style={{ borderRadius: 4 }}
                  onClick={() => {
                    // handleNavigate('/NewPostFormPage')
                    handleNavigate('/NewPublicationPage')
                  }}
                >
                  {t("new_post_page_button_select")} <Accept color={'#00B828'} />
                </button>
              </div>
            </div>

            <div className={styles.card_campaigns}>
              <div className={styles.card_post_header}>
                <div
                  className={styles.card_post_icon}
                  style={{ background: '#EE951A' }}
                >
                  <MultiplePhotos color="#fff" size={4000} />
                </div>
                <Interrogation color="#fff" />
              </div>
              <div className={styles.card_post_body}>
                <h2>{t("new_post_page_third_card_title")}</h2>
                <p>{t("new_post_page_third_card_subtitle")} </p>
              </div>
              <div className={styles.card_post_footer}>
                <button
                  className={styles.button_select}
                  style={{ borderRadius: 4 }}
                  onClick={() => {
                    // handleNavigate('/NewCampaingFormPage')
                    handleNavigate('/NewPublicationPageCampaign')
                  }}
                >
                  {t("new_post_page_button_select")} <Accept color={'#00B828'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default NewPostPage
