import styles from './styles.module.css'
import { useState, useRef, useEffect } from 'react'

// import { useLocation } from 'react-router-dom'

import { useClient } from '../../hooks/useClient'

import StepFirstPass from './StepfirstPass'
import NewPostFormSecond from './StepSecondPass'
import StepThirdPass from './StepThirdPass'

import StepImageBank from './StepSecondPass/StepImageBank'
import StepFourthPass from './StepFourthPass'
import StepFinish from './StepFinish'
import StepFifthPass from './StepFifthPass'

import StepPublicationSuccess from './StepPublicationSuccess'
import { SubscriptionService } from '../../services'

const STEPS = {
  STEP_FIRST_PASS: 'stepFirstPass',
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_THIRD_PASS_PUBLICATION: 'stepArts',
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',

  STEP_FINISH: 'stepFinish',
  STEP_PUBLICATION_SUCCESS: 'stepPublicationSuccess',

  STEP_IMAGE_BANK: 'stepImageBank',
  STEP_FIFTH_PASS_SCHEDULE: 'stepSchedule',
}

export default function NewPublicationPage() {
  const { clientData } = useClient()

  const campaignRef = useRef(undefined)

  // const location = useLocation()

  const [step, setStep] = useState(STEPS.STEP_FIRST_PASS)

  // const productRef = useRef(undefined)
  // const targetAudienceRef = useRef(undefined)
  const publicationDescriptionRef = useRef('')
  const funelOfSaleRef = useRef(undefined)
  const detailRef = useRef(undefined)

  const [imageGaleryArray, setImageGaleryArray] = useState([])
  const [imageArray, setImageArray] = useState([])
  const [imageAIArray, setImageAIArray] = useState([])
  const [imageUploadArray, setImageUploadArray] = useState([])
  const [imageProductsArray, setImageProductsArray] = useState([])
  const [selectFunnel, setSelectFunnel] = useState(null)
  const [headlineGpt, setHeadlineGpt] = useState({})
  const [generateArtIa, setGenerateArtIa] = useState(false)

  // const [iaData, setIaData] = useState([])

  const [iaData, setIaData] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ])
  const [qtdLoadTemplates, setQtdLoadTemplates] = useState(0)
  const qtdTemplatesLimit = 15

  const [imageUrl, setImageUrl] = useState('')
  const [objectItem, setObjectItem] = useState({})
  const [imageLayers, setImageLayers] = useState([])
  const [headlineText, setHeadlineText] = useState('')

  const [headlineList, setHeadlineList] = useState([])
  const [legendList, setLegendList] = useState([])

  const [stateLegend, setStateLegend] = useState([])

  const [legend, setLegend] = useState(false)

  const [publicationDraft, setPublicationDraft] = useState({})
  const [draft, setDraft] = useState(false)

  const [repost, setRepost] = useState(false)
  const [schedule, setSchedule] = useState(false)

  const [selectedArrayImage, setSelectedArrayImage] = useState([])
  const [selectedArrayImageObject, setSelectedArrayImageObject] = useState([])

  const [selectedObjectItemObject, setSelectedObjectItemObject] = useState([])

  const [publicationArray, setPublicationArray] = useState([])
  const [selectedImagesLogo, setSelectedImagesLogo] = useState([])

  //--------------------
  const [imagesSelectedsArrayObj, setImagesSelectedsArrayObj] = useState([])
  const imagesSelectedId = useRef(undefined)
  const [hasSignature, setHasSignature] = useState(false)

  const publicationIdRef = useRef([])

  useEffect(() => {
    handleCheckSubscription()
  }, [])

  const handleCheckSubscription = async () => {
    const subscription = await SubscriptionService.getSubscriptionByUser()
    if (subscription.data.length <= 0) {
      setHasSignature(false)
    } else {
      setHasSignature(true)
    }
  }

  useEffect(() => {
    // productRef.current = undefined
    // targetAudienceRef.current = undefined
    publicationDescriptionRef.current = ''
    funelOfSaleRef.current = undefined
    detailRef.current = undefined

    setStep(STEPS.STEP_FIRST_PASS)
  }, [clientData])

  return (
    <div className={styles.page}>
      {step === STEPS.STEP_FIRST_PASS && (
        <StepFirstPass
          setStep={setStep}
          clientData={clientData}
          // productRef={productRef}
          // targetAudienceRef={targetAudienceRef}
          publicationDescriptionRef={publicationDescriptionRef}
          funelOfSaleRef={funelOfSaleRef}
          detailRef={detailRef}
          campaignRef={campaignRef}
          setSelectFunnel={setSelectFunnel}
        />
      )}

      {step === STEPS.STEP_IMAGE_BANK && (
        <StepImageBank setStep={setStep} setImageArray={setImageArray} />
      )}

      {step === STEPS.STEP_SECOND_PASS_IMAGE && (
        <NewPostFormSecond
          setStep={setStep}
          imageArray={imageArray}
          setImageArray={setImageArray}
          imageGaleryArray={imageGaleryArray}
          setImageGaleryArray={setImageGaleryArray}
          imageAIArray={imageAIArray}
          setImageAIArray={setImageAIArray}
          imageUploadArray={imageUploadArray}
          setImageUploadArray={setImageUploadArray}
          imageProductsArray={imageProductsArray}
          setImageProductsArray={setImageProductsArray}
          generateArtIa={generateArtIa}
          setGenerateArtIa={setGenerateArtIa}
          // productRef={productRef}
          // targetAudienceRef={targetAudienceRef}
        />
      )}

      {step === STEPS.STEP_THIRD_PASS_PUBLICATION && (
        <StepThirdPass
          setStep={setStep}
          setIaData={setIaData}
          iaData={iaData}
          //
          qtdLoadTemplates={qtdLoadTemplates}
          setQtdLoadTemplates={setQtdLoadTemplates}
          qtdTemplatesLimit={qtdTemplatesLimit}
          //
          clientData={clientData}
          // productRef={productRef}
          // targetAudienceRef={targetAudienceRef}
          detailRef={detailRef}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          imageLayers={imageLayers}
          setImageLayers={setImageLayers}
          setHeadlineText={setHeadlineText}
          headlineText={headlineText}
          imageArray={imageArray}
          imageGaleryArray={imageGaleryArray}
          imageAIArray={imageAIArray}
          imageUploadArray={imageUploadArray}
          imageProductsArray={imageProductsArray}
          setHeadlineList={setHeadlineList}
          headlineList={headlineList}
          setLegendList={setLegendList}
          legendList={legendList}
          //------
          selectedCampaignOption={campaignRef.current}
          selectedArrayImage={selectedArrayImage}
          setSelectedArrayImage={setSelectedArrayImage}
          selectedArrayImageObject={selectedArrayImageObject}
          setSelectedArrayImageObject={setSelectedArrayImageObject}
          setSelectedObjectItemObject={setSelectedObjectItemObject}
          selectedObjectItemObject={selectedObjectItemObject}
          selectFunnel={selectFunnel}
          setHeadlineGpt={setHeadlineGpt}
          headlineGpt={headlineGpt}
          objectItem={objectItem}
          setObjectItem={setObjectItem}
          hasSignature={hasSignature}
          generateArtIa={generateArtIa}
          setGenerateArtIa={setGenerateArtIa}
        />
      )}

      {step === STEPS.STEP_FOURTH_PASS_SUBTITLE && (
        <StepFourthPass
          setStep={setStep}
          // productRef={productRef}
          // targetAudienceRef={targetAudienceRef}
          imageUrl={imageUrl}
          imageLayers={imageLayers}
          setImageUrl={setImageUrl}
          setHeadlineText={setHeadlineText}
          headlineText={headlineText}
          legend={legend}
          setLegend={setLegend}
          // dados da tela anterior
          draftData={publicationDraft}
          publicationRet={location.state?.dataPublication}
          draft={draft}
          repost={repost}
          schedule={schedule}
          setHeadlineList={setHeadlineList}
          headlineList={headlineList}
          setLegendList={setLegendList}
          legendList={legendList}
          selectedArrayImage={selectedArrayImage}
          headlineGpt={headlineGpt}
          campaignRef={campaignRef.current}
          selectFunnel={selectFunnel}
          stateLegend={stateLegend}
          setStateLegend={setStateLegend}
          selectedArrayImageObject={selectedArrayImageObject}
        />
      )}

      {step === STEPS.STEP_FIFTH_PASS_SCHEDULE && (
        <StepFifthPass
          imageLayers={imageLayers}
          setStep={setStep}
          setPublicationArray={setPublicationArray}
          selectedArrayImage={selectedArrayImage}
          selectedArrayImageObject={selectedArrayImageObject}
          selectedCampaignOption={campaignRef.current}
          headlineText={headlineText}
          legend={legend}
          setLegendList={setLegendList}
          legendList={legendList}
          headlineList={headlineList}
          stateLegend={stateLegend}
          selectedImagesLogo={selectedImagesLogo}
          setSelectedImagesLogo={setSelectedImagesLogo}
          //--------------------
          imagesSelectedsArrayObj={imagesSelectedsArrayObj}
          setImagesSelectedsArrayObj={setImagesSelectedsArrayObj}
          imagesSelectedId={imagesSelectedId}
          publicationIdRef={publicationIdRef}
          headlineGpt={headlineGpt}
          // setTiktokMusic={setTiktokMusic}
          // tiktokMusic={tiktokMusic}
          // setTiktokPrivacyLevel={setTiktokPrivacyLevel}
          // tiktokPrivacyLevel={tiktokPrivacyLevel}
          // setTiktokComment={setTiktokComment}
          // tiktokComment={tiktokComment}
        />
      )}

      {step === STEPS.STEP_FINISH && (
        <StepFinish
          setStep={setStep}
          imageUrl={imageUrl}
          imagesSelectedsArrayObj={imagesSelectedsArrayObj}
          setImagesSelectedsArrayObj={setImagesSelectedsArrayObj}
          imagesSelectedId={imagesSelectedId}
          legendList={legendList}
          // setTiktokMusic={setTiktokMusic}
          // tiktokMusic={tiktokMusic}
          // setTiktokPrivacyLevel={setTiktokPrivacyLevel}
          // tiktokPrivacyLevel={tiktokPrivacyLevel}
          // setTiktokComment={setTiktokComment}
          // tiktokComment={tiktokComment}
        />
      )}

      {step === STEPS.STEP_PUBLICATION_SUCCESS && (
        <StepPublicationSuccess
          imageUrl={imagesSelectedsArrayObj}
          publicationIdRef={publicationIdRef}
        />
      )}
    </div>
  )
}
