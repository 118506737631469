import PlanCard from '../../components/PlanCard'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import {
  PagarMeService,
  PlanService,
  SubscriptionService,
  UserAccountsService,
} from '../../services/'
import React, { useEffect, useState } from 'react'
import {
  CheckBadge,
  Clock,
  CreditCard,
  Cross,
  PencilUploadIcon,
} from '../../assets/svgs/icons'
import { SelectRound } from '../../components/Select/SelectRound'
import Proptypes from 'prop-types'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../utils/notify'
import { useAuth } from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

const AddCard = ({ setModalIsOpen, setTierPlan, tierCard }) => {
  const { t } = useTranslation()
  const [userAcount, setUserAccount] = useState()
  const [nameCredCard, setNameCredCard] = useState('')
  const [numberCredCard, setNumberCredCard] = useState('')
  const [numberCredCardValue, setNumberCredCardValue] = useState('')
  const [validateCredCard, setValidateCredCard] = useState('')
  const [cvvCredCard, setCvvCredCard] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    loadCardData()
    setTierPlan(tierCard)
  }, [])

  const handleSavePaymentData = async () => {
    console.log('Entrou  aqui', numberCredCard)
    // return

    if(numberCredCard === '' || numberCredCard === undefined){
      notifyErrorCustom(t('addCard.fillNumberField'))
      return
    }
    const data = await PagarMeService.getCardByUser()

    const body = {
      uuid: data?.data[0]?.uuid,
      userAccountId: userAcount?.data?.uuid,
      cardToken: `${cvvCredCard}`,
      cardName: `${nameCredCard}/${userAcount?.data?.cnpj}`,
      flag: 'VISA',
      number: `${numberCredCardValue}`,
      expirationDate: validateCredCard,
      type: 'CREDIT',
    }

    // const ret = await PagarMeService.postCard(body)
    // console.log('ret',ret)
    // if (ret.responseStatus == 200) {
    //   setModalIsOpen(false)
    //   notifySuccessCustom('Método de pagamento adicionado com sucesso')
    // } else {
    //   notifyErrorCustom('Erro ao cadastrar o método de pagamento. Por favor, tente novamente mais tarde.')

    // }
    const ret = await PagarMeService.postCard(body)
    
    
    
    if (ret.responseStatus == 200) {
      setModalIsOpen(false)
      
      notifySuccessCustom(t('addCard.paymentMethodAddedSuccessfully'))
    } else {
      notifyErrorCustom(t('addCard.paymentMethodAddedError'))
    }

    // if (data.data.length > 0) {
      //   const ret = await PagarMeService.postCard(body)
      
      //   if (ret.responseStatus == 200) {
    //     setModalIsOpen(false)

    //     notifySuccessCustom('Método de pagamento adicionado com sucesso')
    //   } else {
      //     notifyErrorCustom(
        //       'Erro ao cadastrar o método de pagamento. Por favor, tente novamente mais tarde.'
        //     )
        //   }
        // } else {
    //   const { uuid, ...rest } = body
    //   const ret = await PagarMeService.postCard(rest)

    //   if (ret.responseStatus == 200) {
      //     setModalIsOpen(false)
    //     notifySuccessCustom('Método de pagamento adicionado com sucesso')
    //   } else {
      //     notifyErrorCustom(
        //       'Erro ao cadastrar o método de pagamento. Por favor, tente novamente mais tarde.'
        //     )
        //   }
        // }
        
        //setUserPlan(true)

    //     console.log('ret', ret)

    //     const subscriptionBody = {
    //       planId: uuid,
    //       accountId: userAcount?.data.uuid,
    //       cardId: ret.data.uuid,
    //       paymentMethod: 'credit_card',
    //     }
        
    //     const retSubscription = await SubscriptionService.postSubscription(subscriptionBody)
        
    //     return
    // navigate('/HomePage')
  }
  
  const handleInputChange = (e, type) => {
    if (type === 'credCard') {
      formatCreditCardNumber(e.target.value)
    }
    if (type === 'validate') {
      formatValidateNumber(e.target.value)
    }
    if (type === 'cvv') {
      formatCcvNumber(e.target.value)
    }
  }

  const formatCcvNumber = (inputValue) => {
    if (inputValue.length > 3) {
      return
    }
    const numericValue = inputValue.replace(/\D/g, '')

    setCvvCredCard(numericValue)
  }

  const formatValidateNumber = (inputValue) => {
    // Remove qualquer caractere que não seja número

    if (inputValue.length === 7) {
      setValidateCredCard(inputValue)
    } else {
      const numericValue = inputValue.replace(/\D/g, '')

      // Limita a 6 dígitos
      const limitedValue = numericValue.slice(0, 6)
      let formattedValue

      if (limitedValue.length >= 2) {
        // Verifica se os dois primeiros números são válidos (entre 1 e 12)
        const firstTwoDigits = parseInt(limitedValue.slice(0, 2), 10)
        if (firstTwoDigits < 1 || firstTwoDigits > 12) {
          // Trunca para dois dígitos válidos
          formattedValue = limitedValue.slice(0, 2)
        }
      }
      // Adiciona a máscara "00/0000"
      if (inputValue.length >= 6) {
        formattedValue = limitedValue
          .replace(/^(\d{2})/, '$1') // Adiciona a barra após os dois primeiros dígitos
          .replace(/(\d{2})(\d{0,4})/, '$1/$2') // Adiciona a barra após os próximos quatro dígitos
      }
      // Atualiza o estado com o valor formatado
      setValidateCredCard(formattedValue)
    }
  }

  const formatCreditCardNumber = (inputValue) => {
    if (inputValue.length > 19) {
      return
    }

    setNumberCredCardValue(inputValue)

    // Remove qualquer caractere que não seja número
    const numericValue = inputValue.replace(/\D/g, '')

    // Divide o número em grupos de 4 dígitos
    const formattedValue = numericValue.replace(/(\d{4})/g, '$1 ')

    // Atualiza o estado com o valor formatado
    setNumberCredCard(formattedValue.trim())
  }

  const loadCardData = async () => {
    const dataCard = await PagarMeService.getCardByUser()
    setUserAccount(await UserAccountsService.getUserAccountsByLogin())

    if (dataCard.data) {
      setNumberCredCard(dataCard?.data[0]?.number)
      setNumberCredCardValue(dataCard?.data[0]?.number)
      setNameCredCard(dataCard?.data[0]?.cardName)
      setValidateCredCard(dataCard?.data[0]?.expirationDate)
    }
  }

  // const navigate = useNavigate()
  return (
    <div className={styles.container_field}>
      <div className={styles.container}>
        <div className={styles.plans_content}>
          {/* {plansData.map((plan) => (
            <PlanCard
              userAcount={userAcount}
              userCard={userCard}
              key={plan.id}
              uuid={plan.uuid}
              title={plan.description}
              previusPrice={plan.priceMonth}
              price={plan.priceReal}
              description={plan.resume}
              featureTitle={PLANS_PROPS.FEATURE_TITLE}
              featureDescription={PLANS_PROPS.FEATURE_DESCRIPTION}
              featureItem={plan.PlanItems}
              premium={plan.tier == 4 ? true : false}
              tier={plan.tier}
            />
              
          ))} */}

          {/* Dados De Pagamento */}

          <div className={styles.box_company_card}>
            <div className={styles.box_title_card}>
              <p className={styles.title_card}>{t('addCard.paymentData')}</p>

              {/* <div className={styles.box_select}>
                <SelectRound
                  htmlFor="select"
                  placeholder="Selecione"
                  defaultSelected={{
                    label: 'Comercial',
                    value: '',
                  }}
                  options={[
                    { label: 'Teste', value: 1 },
                    { label: 'Teste2', value: 2 },
                  ]}
                />
              </div> */}
            </div>

            <div className={styles.container_data_payment}>
              <div>
                <div className={styles.container_cred_card}>
                  <div className={styles.content_cred_card}>
                    <div>
                      <p className={styles.name_cred_card}>{nameCredCard}</p>
                    </div>
                    <div className={styles.content_separate_cred_card}>
                      <p className={styles.number_cred_card}>
                        {numberCredCard}
                      </p>

                      <p className={styles.validate_cred_card}>
                        {validateCredCard}
                      </p>
                    </div>
                  </div>
                  <CreditCard />
                </div>
              </div>

              <div className={styles.container_input}>
                <div className={styles.box_input}>
                  <label className={styles.input_label}>{t('addCard.number')}</label>
                  <input
                    className={styles.input_payment}
                    placeholder="**** **** **** 4578"
                    value={numberCredCard}
                    onChange={(e) => handleInputChange(e, 'credCard')}
                  />
                </div>

                <div className={styles.box_input}>
                  <label className={styles.input_label}>
                    {t('addCard.ownerName')}
                  </label>
                  <input
                    className={styles.input_payment}
                    placeholder={t('addCard.ownerNamePlaceholder')}
                    value={nameCredCard}
                    onChange={(e) => setNameCredCard(e.target.value)}
                  />
                </div>
                <div className={styles.box_input_separate}>
                  <div className={styles.box_input}>
                    <label className={styles.input_label}>
                      {t('addCard.expirationDate')}
                    </label>

                    <input
                      className={styles.input_payment}
                      placeholder="00/0000"
                      value={validateCredCard}
                      onChange={(e) => handleInputChange(e, 'validate')}
                    />
                  </div>
                  <div className={styles.box_input}>
                    <label className={styles.input_label}>{t('addCard.cvv')}</label>
                    <input
                      className={styles.input_payment}
                      placeholder="***"
                      value={cvvCredCard}
                      onChange={(e) => handleInputChange(e, 'cvv')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.button_save}>
              <button
                className={styles.button_save_payment_data}
                onClick={() => handleSavePaymentData()}
              >
                {t('addCard.savePaymentData')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCard

AddCard.propTypes = {
  setModalIsOpen: Proptypes.func,
  setTierPlan: Proptypes.func,
  tierCard: Proptypes.any,
}
