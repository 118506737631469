/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import { LeftBackArrow } from '../../../assets/svgs/icons'

import { Input } from '../../../components'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import {
  CampaignService,
  ProductService,
  TargetAudienceService,
  BusinessService,
} from '../../../services'
// import { getBusinessByCampaign } from '../../../services/business'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../../utils/notify'

import { Switch } from 'antd'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_CAMPAIGN_LIST: 'CampaignList',
  STEP_NEW_CAMPAIGN_SUCCESS: 'StepNewCampaignSuccess',
  STEP_PAGE_PARAMETERS: 'StepPageParameters',
}

const funnelOfSaleOptions = [
  {
    label: 'Topo',
    value: 1,
  },
  {
    label: 'Meio',
    value: 2,
  },
  {
    label: 'Fim',
    value: 3,
  },
]

const detailOptions = [
  {
    value: 'Simples',
    label: 'Simples',
  },
  {
    value: 'Mediana',
    label: 'Mediana',
  },
  {
    value: 'Avançado',
    label: 'Avançado',
  },
]

function NewCampaignStep({
  setStep,
  state,
  setCampaignItem,
  registrationData,
  setRegistrationData,
  clientData,
}) {
  const { t } = useTranslation()
  const [expandCampaign, setexpandCampaign] = useState(false)
  const [expandProduct, setexpandProduct] = useState(false)
  const [expandTarget, setexpandTarget] = useState(false)
  const [expandObjective, setexpandObjective] = useState(false)

  // const [expandDetails, setexpandDetails] = useState(false)
  // const [expandFunnel, setexpandFunnel] = useState(false)
  // const [expandDescription, setexpandDescription] = useState(false)
  // const [isParam, setIsParam] = useState(false)

  // const [uuidNewCampaign, setUuidNewCampaign] = useState('')

  const [productsOptions, setProductsOptions] = useState([])
  const [targetOptions, setTargetOptions] = useState([])
  const [campaignTypeOptions, setCampaignTypeOptions] = useState([])

  const [productToaggle, setProductToaggle] = useState(true)
  // const [campaignType, setCampaignType] = useState([])
  // const [registrationData, setRegistrationData] = useState({})

  // const [funnelOfSale, setFunnelOfSale] = useState(undefined)
  // const [publicationDescription, setPublicationDescription] = useState('')

  // const [detailsValue, setDetailsValue] = useState(undefined)

  const updateEnableForm = () => {
    if (state) {
      setexpandCampaign(true)
      setexpandProduct(true)
      setexpandTarget(true)
      setexpandObjective(true)

      loadTargetData()
      loadProductData()
    }
  }

  // REVISAR VALIDAÇÃO DOS CAMPOS, ESTOU INDO ADIANTE NESTE MOMENTO PARA LIBERAR
  //IMPEDIMENTOS EM OUTRAS ATIVIDADES
  const validateFields = async () => {
    const isValid = true

    if (!registrationData?.name) {
      notifyErrorCustom(t('newCampaignStep.nameRequired'))
      return false
    }

    if (!registrationData?.campaignTypeId) {
      notifyErrorCustom(t('newCampaignStep.campaignTypeRequired'))
      return false
    }

    // if (!registrationData?.productId) {
    //   notifyErrorCustom('Produto é obrigatório')
    //   return false
    // }

    // if (!registrationData?.targetAudienceId) {
    //   notifyErrorCustom('Publico alvo é obrigatório')
    //   return false
    // }

    if (!registrationData?.objective) {
      notifyErrorCustom(t('newCampaignStep.objectiveRequired'))
      return false
    }

    // console.log('detailsValue', detailsValue)

    // if (!detailsValue) {
    //   notifyErrorCustom('Detalhamento é obrigatório')
    //   return false
    // }

    // if (!funnelOfSale) {
    //   notifyErrorCustom('Estágio do funil é obrigatório')
    //   return false
    // }

    // if (!publicationDescription) {
    //   notifyErrorCustom('Descrição é obrigatório')
    //   return false
    // }

    return isValid
  }

  const handleRegistrationDataChange = (id, value) => {
    setRegistrationData((prevState) => ({ ...prevState, [id]: value }))
  }
  const handleRegistrationDataChangeCampaignType = (id, value) => {
    setRegistrationData((prevState) => ({
      ...prevState,
      [id]: value.toString(),
    }))
  }

  const loadProductData = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await ProductService.getProductByNotBusinessClientList()

      const nothingProduct = {
        label: 'Nenhum',
        value: null,
      }

      if (responseStatus === 200) {
        let newData = data.map((item) => ({
          label: item.name,
          value: item.uuid,
        }))
        const productTypeOptions = [nothingProduct, ...newData]
        setProductsOptions(productTypeOptions)
      }
    } else {
      const { data, responseStatus } =
        await ProductService.getProductByBusinessClientIdList(clientData?.uuid)

      const nothingProduct = {
        label: 'Nenhum',
        value: null,
      }
      if (responseStatus === 200) {
        let newData = data.map((item) => ({
          label: item.name,
          value: item.uuid,
        }))
        const productTypeOptions = [nothingProduct, ...newData]
        setProductsOptions(productTypeOptions)
      }
    }
  }

  const loadTargetData = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByNotClientId()

      const nothingTargetAudience = {
        label: 'Nenhum',
        value: null,
      }
      if (responseStatus === 200) {
        let newData = data.map((item) => ({
          label: item.name,
          value: item.uuid,
        }))
        const productTypeOptions = [nothingTargetAudience, ...newData]
        setTargetOptions(productTypeOptions)
      }
    } else {
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByClientId(
          clientData?.uuid
        )
      const nothingTargetAudience = {
        label: 'Nenhum',
        value: null,
      }
      if (responseStatus === 200) {
        let newData = data.map((item) => ({
          label: item.name,
          value: item.uuid,
        }))
        const productTypeOptions = [nothingTargetAudience, ...newData]
        setTargetOptions(productTypeOptions)
      }
    }
  }
  const loadCampaignType = async () => {
    const { data, responseStatus } = await CampaignService.getCampaignType()

    if (responseStatus === 200) {
      let newData = data.map((item) => ({
        label: item.name,
        value: item.id,
      }))

      setCampaignTypeOptions(newData)

      if (registrationData.uuid) {
        handleRegistrationDataChangeCampaignType(
          'campaignTypeId',
          registrationData.campaignTypeId
        )
      }
    }
  }

  useEffect(() => {
    if (state) {
      // console.log('state   ========> ', state)

      // if (state?.details) {
      //   setexpandDetails(true)
      // }

      // if (state?.funnelOfSale) {
      //   setFunnelOfSale(
      //     funnelOfSaleOptions.find((e) => e.label === state.funnelOfSale).value
      //   )
      //   setexpandFunnel(true)
      // }

      // if (state?.publicationDescription) {
      //   setPublicationDescription(state.publicationDescription)
      //   setexpandDescription(true)
      // }

      // if (state?.objective) {
      //   setexpandDetails(true)
      // }

      setRegistrationData(state)
    }

    updateEnableForm()
    loadCampaignType()
  }, [])

  const handleCheckCampaign = (event) => {
    handleRegistrationDataChange('name', event.target.value)
  }

  const handleCheckCampaignType = (event) => {
    if (event && !expandProduct) {
      loadProductData()
      setexpandProduct(true)
    }
    handleRegistrationDataChangeCampaignType('campaignTypeId', event.value)
  }

  const handleTarget = (event) => {
    if (event && !expandTarget) {
      loadTargetData()
      setexpandTarget(true)
    }
    handleRegistrationDataChange('productId', event.value)
  }

  const handleObjective = (event) => {
    if (event) {
      handleRegistrationDataChange('targetAudienceId', event.value)
      setexpandObjective(true)
    }
  }

  const handleNavigate = () => {
    setStep(STEPS.STEP_CAMPAIGN_LIST)
  }

  const createCampaign = async () => {
    if (state) {
      const newRegistrationData = JSON.parse(JSON.stringify(registrationData))
      delete newRegistrationData.uuid
      delete newRegistrationData.createdAt
      delete newRegistrationData.updatedAt
      delete newRegistrationData.deletedAt
      delete newRegistrationData.userAccountId
      delete newRegistrationData.enable
      delete newRegistrationData.businessClientId
      delete newRegistrationData.isParameterized

      if (JSON.parse(window.localStorage.getItem('userData')).guestUserId) {
        newRegistrationData.guestId = JSON.parse(
          window.localStorage.getItem('userData')
        ).guestUserId
      } else {
        delete newRegistrationData.guestId
      }
      if (!newRegistrationData.productId) {
        delete newRegistrationData.productId
      }

      if (!newRegistrationData.targetAudienceId) {
        delete newRegistrationData.targetAudienceId
      }

      const { responseStatus } = await CampaignService.putCampaign(state.uuid, {
        ...newRegistrationData,
      })

      if (responseStatus === 204) {
        notifySuccessCustom('Campanha editada com sucesso')
        setStep(STEPS.STEP_PAGE_PARAMETERS)
      }
    } else {
      const newRegistrationData = JSON.parse(JSON.stringify(registrationData))
      delete newRegistrationData.uuid
      delete newRegistrationData.createdAt
      delete newRegistrationData.updatedAt
      delete newRegistrationData.deletedAt
      delete newRegistrationData.enable
      delete newRegistrationData.isParameterized
      if (!newRegistrationData.productId) {
        delete newRegistrationData.productId
      }
      if (!newRegistrationData.targetAudienceId) {
        delete newRegistrationData.targetAudienceId
      }
      let newBody = { ...newRegistrationData }

      let guestId = JSON.parse(
        window.localStorage.getItem('userData')
      ).guestUserId

      // console.log('detailsValue', detailsValue)
      // console.log(
      //   'funnelOfSale',
      //   funnelOfSaleOptions.find((e) => e.value === funnelOfSale).label
      // )
      // console.log('publicationDescription', publicationDescription)

      if (clientData?.uuid) {
        newBody = {
          ...newRegistrationData,
          businessClientId: clientData?.uuid,
          guestId,
        }
      }

      if (guestId) {
        newBody = {
          ...newBody,
          guestId,
        }
      }

      newBody = {
        ...newBody,
        // details: detailsValue,
        // funnelOfSale: funnelOfSaleOptions.find((e) => e.value === funnelOfSale)
        //   .label,
        // publicationDescription,
      }

      const { data, responseStatus } = await CampaignService.postCampaign({
        ...newBody,
      })

      if (clientData.businessId && !productToaggle) {
        const completBusiness = await BusinessService.getBusinessByLogin()

        let bodyNewBusiness = {
          businessNicheId: completBusiness.data[0].businessNicheId,
          campaignId: data?.uuid,
          subNiche: completBusiness.data[0].subNiche,
          description: completBusiness.data[0].subNiche,
          guestId: JSON.parse(window.localStorage.getItem('userData'))
            .guestUserId,
        }

        await BusinessService.postBusiness(bodyNewBusiness)
      } else if (!clientData.businessId && !productToaggle) {
        const completeBusinessClient =
          await BusinessService.getBusinessByClientId(clientData.uuid)

        if (completeBusinessClient.data[0]) {
          let bodyNewBusiness = {
            businessNicheId: completeBusinessClient.data[0].businessNicheId,
            campaignId: data?.uuid,
            subNiche: completeBusinessClient.data[0].subNiche,
            description: completeBusinessClient.data[0].subNiche,
            businessClientId: clientData.uuid,
            guestId: JSON.parse(window.localStorage.getItem('userData'))
              .guestUserId,
          }
          await BusinessService.postBusiness(bodyNewBusiness)
        } else {
          notifyWarningCustom(
            'É preciso ter iniciado as parametrizações do cliente para fazer esta ação'
          )
          return false
        }
      }

      // let bodyNewBusiness = {
      //   businessNicheId: businessByLoginData.businessNicheId,
      //   campaignId: newState?.uuid,
      //   subNiche: businessByLoginData.subNiche,
      //   description: businessByLoginData.description,
      //   // businessClientId: clientData.uuid,
      // }

      // if (clientData?.uuid) {
      //   bodyNewBusiness = {
      //     ...bodyNewBusiness,
      //     businessClientId: clientData.uuid,
      //   }
      // }

      // const postBusinessResponse = await postBusiness(bodyNewBusiness)

      setCampaignItem(data)

      if (responseStatus === 200 && data) {
        notifySuccessCustom('Campanha criada com sucesso')

        if (productToaggle) {
          setStep(STEPS.STEP_NEW_CAMPAIGN_SUCCESS)
        } else {
          setStep(STEPS.STEP_PAGE_PARAMETERS)
        }
      }

      return data
    }
  }

  const nextButton = async (navigation) => {
    if (navigation === 'next') {
      const isValid = await validateFields()
      if (!isValid) return

      createCampaign()
      return

      // if (state?.uuid) {
      //   setStep(STEPS.STEP_PAGE_PARAMETERS)
      // } else {
      //   setStep(STEPS.STEP_NEW_CAMPAIGN_SUCCESS)
      // }
    }

    if (navigation === 'back') {
      setStep(STEPS.STEP_CAMPAIGN_LIST)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      className={styles.container_header}
    >
      <div className={styles.container_title_new_posts}>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>{t('campaignList.campaignSearch')}</p>
          <p className={styles.title_new_posts_title}>{t('campaignList.newCampaign')}</p>
        </div>
        <button
          className={styles.button_new_user}
          onClick={() => handleNavigate()}
        >
          {t('newCampaignStep.cancel_button')}
        </button>
      </div>

      <div className={styles.container_form}>
        <form className={styles.form_campaign}>
          <div className={styles.container_form_first_options}>
            <div className={styles.container_form_first_options_header}>
              <div
                className={styles.box_button_back}
                onClick={() => {
                  nextButton('back')
                }}
              >
                <LeftBackArrow /> {t('campaignList.back_button')}
              </div>
            </div>
            <div className={styles.form_container_input}>
              <div className={styles.form_container_label_input_container}>
                <label className={styles.form_container_input_label}>
                  {t('newCampaignStep.nameRequired_title')}
                </label>
                <Input
                  fontSize={12}
                  style={{ height: 38, fontWeight: 300 }}
                  placeholder={t('newCampaignStep.nameRequired_placeholder')}
                  value={registrationData?.name}
                  id="name"
                  onChange={(event) => handleCheckCampaign(event)}
                />
              </div>
            </div>

            <motion.div
              initial={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              animate={{ opacity: 1 }}
              className={styles.form_container_input}
            >
              {registrationData?.name && (
                <div className={styles.form_container_label_input_container}>
                  <label className={styles.form_container_input_label}>
                    {t('newCampaignStep.campaignTypeRequired_title')}
                  </label>
                  <SelectSquare
                    backgroundColor={'rgba(224, 224, 224, 1)'}
                    arrowDirection="down"
                    placeholder={t('newCampaignStep.campaignTypeRequired_placeholder')}
                    id="campaignTypeId"
                    defaultSelected={campaignTypeOptions.find(
                      (e) => e.value == registrationData?.campaignTypeId
                    )}
                    options={campaignTypeOptions}
                    onClickItem={(event) => handleCheckCampaignType(event)}
                  />
                </div>
              )}

              {expandProduct && registrationData?.name && (
                <div className={styles.form_container_label_input_container}>
                  <label className={styles.form_container_input_label}>
                    {t('newCampaignStep.productRequired_title')}
                  </label>
                  <SelectSquare
                    backgroundColor={'rgba(224, 224, 224, 1)'}
                    arrowDirection="down"
                    placeholder={t('newCampaignStep.productRequired_placeholder')}
                    defaultSelected={productsOptions.find(
                      (e) => e.value === registrationData?.productId
                    )}
                    options={productsOptions}
                    id="productId"
                    onClickItem={(event) => handleTarget(event)}
                  />
                </div>
              )}
              {expandTarget && (
                <div className={styles.container_box_checkbox}>
                  <div className={styles.form_container_label_input_container}>
                    <label className={styles.form_container_input_label}>
                      {t('newCampaignStep.targetRequired_title')}
                    </label>
                    <SelectSquare
                      backgroundColor={'rgba(224, 224, 224, 1)'}
                      arrowDirection="down"
                      placeholder={t('newCampaignStep.targetRequired_placeholder')}
                      defaultSelected={targetOptions.find(
                        (e) => e.value === registrationData?.targetAudienceId
                      )}
                      options={targetOptions}
                      onClickItem={(event) => handleObjective(event)}
                    />
                  </div>
                </div>
              )}

              {expandObjective && registrationData?.name && (
                <div className={styles.form_container_label_input_container}>
                  <label className={styles.form_container_input_label}>
                    {t('newCampaignStep.objectiveRequired_title')}
                  </label>
                  <Input
                    fontSize={12}
                    style={{ height: 38, fontWeight: 300 }}
                    placeholder={t('newCampaignStep.objectiveRequired_placeholder')}
                    id="objective"
                    value={registrationData?.objective}
                    onChange={({ target: { id, value } }) => {
                      handleRegistrationDataChange(id, value)
                    }}
                  />
                </div>
              )}

              {/* {expandDetails && (
                <div className={styles.container_box_checkbox}>
                  <div className={styles.form_container_label_input_container}>
                    <label className={styles.form_container_input_label}>
                      Escolha o detalhamento:
                    </label>
                    <SelectSquare
                      backgroundColor={'rgba(224, 224, 224, 1)'}
                      arrowDirection="down"
                      placeholder={'Selecione'}
                      defaultSelected={detailOptions?.find(
                        (e) => e.label === state?.details
                      )}
                      options={detailOptions}
                      onClickItem={({ value }) => {
                        setexpandFunnel(true)
                        setDetailsValue(value)
                      }}
                    />
                  </div>
                </div>
              )} */}

              {/* {expandFunnel && (
                <div
                  className={styles.container_box_checkbox}
                  style={
                    {
                      // marginTop: '20px',
                    }
                  }
                >
                  <label className={styles.title_select}>
                    Estágio do funil da publicação
                  </label>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                    }}
                  >
                    {funnelOfSaleOptions.map((item) => (
                      <div
                        key={item.value}
                        className={styles.button_funnel}
                        style={{
                          cursor: 'pointer',

                          border: '1px solid #1900271A',
                          padding: '4px 8px',
                          borderRadius: '100px',

                          backgroundColor:
                            funnelOfSale === item.value ? '#1900271A' : '',
                          userSelect: 'none',
                        }}
                        onClick={() => {
                          setexpandDescription(true)
                          if (item.value === funnelOfSale) {
                            return setFunnelOfSale(undefined)
                          }

                          setFunnelOfSale(item.value)
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
              )} */}

              {/* {expandDescription && (
                <div
                  className={styles.container_box_checkbox}
                  style={
                    {
                      // marginTop: '20px',
                    }
                  }
                >
                  <label className={styles.title_select}>Descrição</label>
                  <textarea
                    className={styles.textarea}
                    placeholder="Digite aqui a descrição da publicação..."
                    value={publicationDescription}
                    onChange={(e) => {
                      // publicationDescriptionRef.current = e.target.value

                      setPublicationDescription(e.target.value)
                    }}
                  ></textarea>
                </div>
              )} */}

              {/* {expandObjective &&
                registrationData?.name &&
                expandDetails &&
                expandFunnel &&
                expandDescription && (
                  <div className={styles.form_container_label_input_container}>
                    {!state && (
                      <>
                        <div style={{ marginTop: 20 }}>
                          <label className={styles.form_container_input_label}>
                            Parametrizações
                          </label>
                        </div>

                        <div>
                          <Switch
                            className={styles.switch}
                            size="small"
                            style={{
                              backgroundColor: productToaggle ? '#f09135' : '',
                              outline: 'none',
                            }}
                            checked={productToaggle}
                            onClick={() =>
                              setProductToaggle((current) => !current)
                            }
                          />

                          <label style={{ fontWeight: 300, color: '#3E3E3E' }}>
                            Manter parametrizações do cadastro inicial
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                )} */}
            </motion.div>
          </div>
        </form>
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            justifyContent: 'end',
          }}
        >
          <div className={styles.button_next}>
            <button
              onClick={() => {
                nextButton('next')
              }}
            >
              {t('campaignList.finish_button')}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default NewCampaignStep

NewCampaignStep.propTypes = {
  setStep: PropTypes.string,
}
