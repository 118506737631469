import { useState, useRef } from 'react'
import styles from './styles.module.css'
import { InterrogationCircleIcon } from '../../../../assets/svgs/icons'
import { InformationCircle } from '../../../../assets/svgs/icons'
import { useTranslation } from 'react-i18next'

import {
  PythonService,
  VoiceToneService,
  ProductService,
  BusinessService,
} from '../../../../services'

import { Loading } from '../../../../components/Loading'
import { useEffect } from 'react'
import { isObjEqual } from '../../../../utils/verifyObject'
import { ButtonNavigateStep } from '../../ButtonNavigateStep'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

// eslint-disable-next-line react/prop-types
function StepVoiceTone({ setStep, campaignItem }) {
  const { t } = useTranslation()
  const [campaign, setCampaign] = useState('')
  const [tones, setTones] = useState([])
  // const [tonesToCompare, setTonesToCompre] = useState([])

  const [toneSelectedId, setToneSelectedId] = useState(undefined)

  const [voiceToneSelected, setVoiceToneSelected] = useState(undefined)

  const [send, setSend] = useState(false)

  const [loading, setLoading] = useState(false)

  const businessId = useRef(undefined)

  useEffect(() => {
    // carregue os valores de tons vindo do banco de dados caso a resposta seja 200
    ;(async () => {
      let businessByCampaignReturn =
        await BusinessService.getBusinessByCampaign(campaignItem?.uuid)

      businessByCampaignReturn = businessByCampaignReturn?.data[0]

      businessId.current = businessByCampaignReturn?.uuid

      // console.log('businessByCampaignReturn', businessByCampaignReturn)

      const VoiceToneData = await VoiceToneService.getAllVoiceToneByBusinessId(
        businessByCampaignReturn?.uuid
      )

      if (VoiceToneData.responseStatus === 200) {
        if (VoiceToneData.data) {
          setTones(
            VoiceToneData.data.map((item) => ({
              name: item.tone,
              description: item.description,
            }))
          )
          // setTonesToCompre(VoiceToneData.data)
          setSend(true)
        }

        if (VoiceToneData.data.length > 0) {
          setToneSelectedId(VoiceToneData.data[0].uuid)
        }
      }
    })()
  }, [])

  function transform(obj) {
    let result = []

    for (let key in obj) {
      result.push({
        name: key,
        description: obj[key],
      })
    }

    return result
  }

  const handleVoiceCampaim = () => {
    setLoading(true)
    loadVoiceCampaim()
  }

  const loadVoiceCampaim = async () => {
    const { responseStatus, data } = await ProductService.getProductById(campaignItem.productId)

    console.log('produto',data)
    if (responseStatus !== 200) {
      setLoading(false)
      return
    }
    console.log('campanha',campaignItem)
    let body = {
      campaign: campaignItem.name,
      product: data.name,
    }

    let response = await PythonService.postVoiceCampaign(body)

    if (response) {
      setTones(transform(response?.data?.data))
      setSend(true)
    }

    setLoading(false)
  }

  const handleChangeCampaim = (e) => {
    setCampaign(e.target.value)
  }

  const saveVoiceTone = async (data) => {
    let body = {
      tone: data.name,
      description: data.description,
      businessId: businessId.current,
    }

    setVoiceToneSelected(body)

    if (!toneSelectedId) {
      await VoiceToneService.postVoiceToneByLogin(body)
    } else {
      const id = toneSelectedId
      await VoiceToneService.putVoiceTone(id, body)
    }

    setLoading(false)

    // setStep(STEPS.STEP_EFFECTS)
  }

  return (
    <div className={styles.page}>
      <Loading enable={loading} />

      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>{t('campaign_step_voice_tone_title')}</p>
        <InterrogationCircleIcon width={22} height={22} />
      </div>

      <p className={styles.subtitle_text}>
        {t('campaign_step_voice_tone_subtitle')}
      </p>

      <div className={styles.step_data_container}>
        <p
          style={{
            margin: '0px',
            color: '#898989',
            fontSize: 12,
            fontWeight: 300,
          }}
        >
          {t('campaign_step_voice_tone_subtitle_text')}
        </p>

        <div style={{ display: 'flex', width: '100%' }}>
          <textarea
            style={{
              width: '100%',
              height: 100,
              marginTop: 4,
              borderRadius: 4,
              color: '#373737',
              fontFamily: 'Poppins',

              background: '#F4F4F4',
              padding: 8,

              border: '1px solid #28003F',
            }}
            type="text"
            placeholder={t('campaign_step_voice_tone_subtitle_text_placeholder')}
            value={campaign}
            onChange={handleChangeCampaim}
          />
        </div>

        {/*<div style={{ margin: '20px 0px', display: 'flex', gap: 8 }}>
       
          <InformationCircle />
          <p
            style={{
              margin: 0,
              color: '#FFF',
              fontSize: 12,
              fontWeight: 300,
            }}
          >
            Exemplo: Somos uma empresa que usa do poder da inteligência
            artificial para gerar posts alinhados com o negócio e a preferência
            de nossos clientes!
          </p>
        </div>*/}

        {/* {!send && ( */}
        <button
          style={{
            fontSize: 12,
            background: '#fff',
            border: '1px solid #28003F',
            color: '#190027',
            borderRadius: 4,
            padding: '10px 60px',
            marginBottom: 30,
            marginTop: 10,
          }}
          onClick={() => {
            handleVoiceCampaim()
          }}
        >
          {t('campaign_step_voice_tone_send_button')}
        </button>
        {/* )} */}

        {send && (
          <div style={{ display: 'flex', gap: 8 }}>
            {tones.map((item, index) => (
              <div
                key={index}
                style={{
                  width: '100%',
                  backgroundColor: '#28003F',
                  border: '1px solid #B900FE',
                  borderRadius: 16,
                  padding: 12,
                }}
              >
                <div>
                  <p
                    style={{
                      color: '#FFF',
                      fontSize: 12,
                      fontWeight: 300,
                      margin: 0,
                    }}
                  >
                    {t('campaign_step_voice_tone_tone')}:
                    <b>{' ' + item.name}</b>
                  </p>

                  <p
                    style={{
                      color: '#FFF',
                      fontSize: 12,
                      fontWeight: 300,
                      margin: '20px 0px',
                    }}
                  >
                    {item.description}
                  </p>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    background: '#6A0098',
                    padding: '4px 8px',
                    borderRadius: 4,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    saveVoiceTone(item)
                    // setStep(STEPS.STEP_EFFECTS)
                  }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 12,
                      fontWeight: 500,
                      margin: 0,
                    }}
                  >
                    {t('campaign_step_voice_tone_select_button')}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_LOGOS)}
          variant={true}
        >
          {t('campaign_step_voice_tone_back_button')}
        </ButtonNavigateStep>

        {voiceToneSelected && (
          <ButtonNavigateStep
            onClick={() => setStep(STEPS.STEP_BRAND_ARCHETYPE)}
            variant={'next'}
          >
            {t('campaign_step_voice_tone_next_button')}
          </ButtonNavigateStep>
        )}
      </div>
    </div>
  )
}

export default StepVoiceTone
