/* eslint-disable react/prop-types */
import CarouselHours from '../CarouselHours'
import SchedulerCalendar from '../SchedulerCalendar'
import {
  BoxChecket,
  BoxNotChecket,
  CircleFacebook,
  Clock,
  DatepikerWhite,
  Instagram,
  Linkedin,
  Pinterest,
  TikTok,
  Twitter,
} from '../../assets/svgs/icons'

import styles from './styles.module.css'
// import { SelectSquare } from '../Select/SelectSquare/SelectSquare'
import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import {
  BusinessService,
  PublicationService,
  HeadlineHasPublicationService,
  MyUploadsService,
  SocialMediaConnectionService,
  LogosService,
  PythonService,
  PlanService,
} from '../../services'
import { Loading } from '../Loading'

import { notifyErrorCustom, notifySuccessCustom } from '../../utils/notify'

import { useClient } from '../../hooks/useClient'
import { getBusinessByClientId } from '../../services/business'
import dayjs from 'dayjs'

// NewPostPage
function CalendarModal({
  handleCloseModal,
  item,
  iWantPublicationRef,
  targetAudienceRef,
  productRef,

  purposeOfPublicationRef,
  legend,
  imageUrl,
  imageLayers,

  headlineText,

  draftModalData,
  multiplePublication,
  handleSelectDate,
  repost,

  // setSocialMediaEvent,
  // socialMediaEvent,

  //dados da tela anterior
  draftData,
}) {
  const { clientData } = useClient()

  const navigate = useNavigate()

  const [valueHour, setValueHour] = useState('')
  const [valueMinute, setValueMinute] = useState('')
  const [addLogo, setAddLogo] = useState(false)

  const [selectedDate, setSelectedDate] = useState(new Date().toDateString())

  const [selectedValues, setSelectedValues] = useState({})
  const selectedValuesString = Object.keys(selectedValues).toString() || ''

  const [ImageLogoSrc, setImageLogoSrc] = useState({})

  const hours = [
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00 ',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00 ',
    '00:00',
  ]

  const [expandMediaArea, setExpandMediaArea] = useState(false)

  const [loading, setLoading] = useState(false)
  const [socialmediaLoged, setSocialmediaLoged] = useState(false)

  // const handleOrientationImage = (media) => {}

  useEffect(() => {
    socialMediaByLogin()
  }, [])

  const socialMediaByLogin = async () => {
    let businessId

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid
    }

    const { data } =
      await SocialMediaConnectionService.getSocialMediaConnections(businessId)

    setSocialmediaLoged(data)
  }

  function formatDate2(data) {
    data = new Date(data)

    let dia = data.getDate()
    let mes = data.getMonth() + 1 // getMonth() retorna mês de 0-11
    let ano = data.getFullYear()

    dia = dia < 10 ? '0' + dia : dia
    mes = mes < 10 ? '0' + mes : mes

    return dia + '/' + mes + '/' + ano
  }

  const handleAddLogo = async () => {
    setAddLogo(!addLogo)
    let businessId

    if (addLogo === false) {
      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)

        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()
        businessId = data[0]?.uuid
      }

      const { data: dataLogo, responseStatus: responseStatusLogo } =
        await LogosService.getLogosById(businessId)

      const { data: dataLogoBase64, responseStatus: responseStatusLogoBase64 } =
        await LogosService.getLogosByBase64(dataLogo[0].uuid)

      let imagePublication

      if (repost) {
        const {
          data: dataPublicationBase64,
          responseStatus: responseStatusPublicationBase64,
        } = await PublicationService.getPublicationBase64ById(
          draftModalData.uuid
        )

        imagePublication = dataPublicationBase64
      }

      // console.log('168', dataLogoBase64)
      const parts = imageUrl?.split(',')

      let base64Data

      if (repost) {
        base64Data = imagePublication[0]?.fileBase64
      } else {
        base64Data = parts[1]
      }

      const bodyToLogo = {
        art_width: 1080,
        art_height: 1080,
        logo_base64: dataLogoBase64,
        images: [base64Data],
      }

      let generateLogoToImagesReturn = await PythonService.postAddLogoToImage(
        bodyToLogo
      )

      setImageLogoSrc(generateLogoToImagesReturn?.data?.data[0])
    } else {
      setImageLogoSrc({})
    }

    // const { data: dataLogo, responseStatus: responseStatusLogo } =
    //   await LogosService.getLogosByBusinessClientId(businessId)
  }

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString)
      if (!isNaN(date)) {
        return date.toLocaleDateString('pt-BR', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      } else {
        return '' // Retorna uma string vazia se a data for inválida
      }
    } catch (error) {
      return '' // Retorna uma string vazia em caso de erro
    }
  }

  const formatDatePost = (dateHour) => {
    const dateObject = new Date(dateHour)
    const formattedDate = dateObject.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric', // Removido '2-digit'
      day: 'numeric', // Removido '2-digit'
    })

    const formattedDateWithHyphens = formattedDate.replace(/\//g, '-')
    return formattedDateWithHyphens
  }

  const returnUploadImageBucket = async (data) => {
    function extractImageData(base64String) {
      // Dividir a string base64 para separar o cabeçalho da imagem
      const parts = base64String.split(',')
      const header = parts[0]
      const base64Data = parts[1]

      // Extrair o formato do arquivo e o tipo de conteúdo
      // const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)
      const matches = header.match(/^data:([A-Za-z-+\/]+)$/)
      const fileContentType = matches[0]
      const fileFormat = fileContentType.split('/')[1]

      // Retornar os dados no formato desejado
      return {
        fileName: 'publication',
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
      }
    }

    let body = extractImageData(data)

    const publicationImageBucket =
      await PublicationService.postPublicationImage(body, selectedValuesString)

    if (publicationImageBucket?.responseStatus === 200) {
      return publicationImageBucket?.data
    } else {
      console.log(publicationImageBucket?.responseStatus)
    }
  }

  const handleSavePublication = async () => {
    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!')
      return
    }

    if (multiplePublication) {
      handleSelectDate({
        ...item,
        valueHour,
        valueMinute,
        selectedDate,
        sketch: false,
        socialTypes: selectedValuesString,
        formatDate: formatDate2(selectedDate),
      })

      handleCloseModal()
      return
    }

    setLoading(true)

    if (draftModalData?.sketch) {
      const dateSelected = dayjs(selectedDate)
        .hour(valueHour)
        .minute(valueMinute)

      // dateSelected.setHours(valueHour)
      // dateSelected.setMinutes(valueMinute)

      const publicationBody = {
        headline: headlineText ? headlineText : '',
        legend: legend,
        publicationImageUrl: draftModalData?.publicationImageUrl,
        publicationImageUrlName: draftModalData?.publicationImageUrlName,
        sketch: false,
        scheduleDate: new Date(),
        publicationDate: dateSelected,
        status: 'Agendado',
        isCanceled: false,
        guestId: JSON.parse(window.localStorage.getItem('userData'))
          .guestUserId,
      }

      let businessId

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()
        businessId = data[0]?.uuid
      }

      const publicationResponse = await PublicationService.putPublication(
        draftModalData?.uuid,
        businessId,
        selectedValuesString,
        publicationBody
      )

      if (
        publicationResponse?.responseStatus === 200 ||
        publicationResponse?.responseStatus === 204
      ) {
        notifySuccessCustom('Publicação agendada com sucesso!')
        navigate('/Draft')
      } else {
        notifyErrorCustom('Erro ao agendar publicação!')
      }
    } else {
      // const dateSelected = new Date(selectedDate)

      // dateSelected.setUTCHours(valueHour)
      // dateSelected.setUTCMinutes(valueMinute)
      const dateSelected = dayjs(selectedDate)
        .hour(valueHour)
        .minute(valueMinute)

      if (draftModalData) {
        let publicationBody = {
          headline: headlineText ? headlineText : '',
          legend: legend || '',
          publicationImageUrl: draftModalData?.publicationImageUrl
            ? draftModalData?.publicationImageUrl
            : draftModalData?.images[0]?.url,
          publicationImageUrlName: draftModalData?.publicationImageUrlName
            ? draftModalData?.publicationImageUrlName
            : draftModalData?.images[0]?.id,
          sketch: false,
          scheduleDate: dayjs(),
          publicationDate: dateSelected,
          objective: purposeOfPublicationRef?.current,
          status: 'Agendado',
          isCanceled: false,
          guestId: JSON.parse(window.localStorage.getItem('userData'))
            .guestUserId,
        }
        let businessId

        if (clientData?.uuid) {
          const { data } = await getBusinessByClientId(clientData?.uuid)
          businessId = data[0]?.uuid
        } else {
          const { data } = await BusinessService.getBusinessByLogin()
          businessId = data[0]?.uuid
        }
        const publicationResponse = await PublicationService.putPublication(
          draftModalData?.uuid,
          businessId,
          selectedValuesString,
          publicationBody
        )

        if (
          publicationResponse?.responseStatus === 200 ||
          publicationResponse?.responseStatus === 204
        ) {
          notifySuccessCustom('Publicação agendada com sucesso!')
          navigate('/SchedulingPage', {
            state: {
              date: dateSelected,
            },
          })
        } else {
          notifyErrorCustom('Erro ao agendar publicação!')
        }
      } else {
        const imageUrlBuket = ImageLogoSrc?.src
          ? `data:image/png;base64,${ImageLogoSrc?.src}`
          : imageUrl
        let publicationImageBucket = await returnUploadImageBucket(
          imageUrlBuket
        )

        if (publicationImageBucket) {
          let publicationBody = {
            headline: headlineText ? headlineText : '',
            legend: legend,
            publicationImageUrl: publicationImageBucket?.fileUrl,
            publicationImageUrlName: publicationImageBucket?.fileName,
            sketch: false,
            publicationDate: dateSelected,
            scheduleDate: dateSelected,
            objective: purposeOfPublicationRef?.current,
            businessClientId: clientData?.uuid ? clientData?.uuid : undefined,
            socialTypes: selectedValuesString,
            guestId: JSON.parse(window.localStorage.getItem('userData'))
              .guestUserId,
          }

          let businessId

          if (clientData?.uuid) {
            const { data } = await getBusinessByClientId(clientData?.uuid)
            businessId = data[0]?.uuid
          } else {
            const { data } = await BusinessService.getBusinessByLogin()

            businessId = data[0]?.uuid
          }

          //return

          const publicationResponse = await PublicationService.postPublication(
            businessId,
            selectedValuesString,
            publicationBody
          )

          const imagePrincipalLayer = imageLayers?.camadas.find(
            (image) => image.tipo === 'imagem_principal'
          )

          if (imagePrincipalLayer) {
            const imagePrincipalLayerBody = {
              publicationId: publicationResponse?.data?.uuid,
              fileName: 'ImagePrincipal',
              fileFormat: 'jpeg',
              fileContentType: 'image/jpeg',
              fileBase64: imagePrincipalLayer?.srcSemTratativa,
            }

            await MyUploadsService.postMyUploads(imagePrincipalLayerBody)
          }

          let headlineHasPublicationBody = {
            publicationId: publicationResponse.data.uuid,
            purposePublication: purposeOfPublicationRef.current,
            publicationDescription: iWantPublicationRef.current,
            productId: productRef.current ? productRef.current.uuid : null,
            targetAudience: targetAudienceRef.current
              ? targetAudienceRef.current.uuid
              : null,
          }

          const headlinePublicationRetur =
            await HeadlineHasPublicationService.postHeadlineHasPublication(
              headlineHasPublicationBody
            )
          if (
            publicationResponse?.responseStatus === 200 ||
            publicationResponse?.responseStatus === 204
          ) {
            notifySuccessCustom('Publicação agendada com sucesso!')
            //return
            navigate('/SchedulingPage', {
              state: {
                dateSchedule: dateSelected,
              },
            })
          } else {
            notifyErrorCustom('Erro ao agendar publicação!')
          }
        } else {
          notifyErrorCustom('Erro ao salvar arte!')
          return
        }
      }
    }

    setLoading(false)
  }

  const handleSavePublicationSketch = async () => {
    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!')
      return
    }
    // let publicationImageBucket = await returnUploadImageBucket(imageUrl)

    // if (multiplePublication) {
    //   handleSelectDate(item)
    //   return
    // }

    if (multiplePublication) {
      // return
      handleSelectDate({
        ...item,
        valueHour,
        valueMinute,
        selectedDate,
        sketch: true,
        socialTypes: selectedValuesString,
        formatDate: formatDate2(selectedDate),
      })

      handleCloseModal()
      return
    }

    if (draftModalData?.sketch) {
      // if (publicationImageBucket) {

      // const dateSelected = new Date(selectedDate)
      // const date = new Date(dateSelected)

      // dateSelected.setHours(valueHour)
      // dateSelected.setMinutes(valueMinute)
      const dateSelected = dayjs(selectedDate)
        .hour(valueHour)
        .minute(valueMinute)

      const publicationBody = {
        headline: headlineText ? headlineText : '',
        legend: legend || '',
        publicationImageUrl: draftModalData?.publicationImageUrl,
        publicationImageUrlName: draftModalData?.publicationImageUrlName,
        sketch: true,
        scheduleDate: dayjs(),
        publicationDate: dateSelected,
        status: 'Agendado',
        isCanceled: false,
        guestId: JSON.parse(window.localStorage.getItem('userData'))
          .guestUserId,
      }

      let businessId

      if (clientData?.uuid) {
        //businessId = clientData?.uuid
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()

        businessId = data[0]?.uuid
      }

      const publicationResponse = await PublicationService.putPublication(
        draftModalData?.uuid,
        businessId,
        selectedValuesString,
        publicationBody
      )

      if (
        publicationResponse?.responseStatus === 200 ||
        publicationResponse?.responseStatus === 204
      ) {
        notifySuccessCustom('Publicação agendada com sucesso!')
        navigate('/Draft')
      } else {
        notifyErrorCustom('Erro ao agendar publicação!')
      }
      //  }
    } else {
      // const dateSelected = new Date(selectedDate)
      // const date = new Date(dateSelected)

      // dateSelected.setHours(valueHour)
      // dateSelected.setMinutes(valueMinute)

      const dateSelected = dayjs(selectedDate)
        .hour(valueHour)
        .minute(valueMinute)

      if (draftModalData) {
        let publicationBody = {
          headline: headlineText ? headlineText : '',
          legend: legend || '',
          publicationImageUrl: draftModalData?.publicationImageUrl,
          publicationImageUrlName: draftModalData?.publicationImageUrlName,
          sketch: true,
          scheduleDate: dayjs(),
          publicationDate: dateSelected,
          objective: purposeOfPublicationRef?.current,
          socialTypes: selectedValuesString,
          guestId: JSON.parse(window.localStorage.getItem('userData'))
            .guestUserId,
        }

        let businessId

        if (clientData?.uuid) {
          const { data } = await getBusinessByClientId(clientData?.uuid)
          businessId = data[0]?.uuid
        } else {
          const { data } = await BusinessService.getBusinessByLogin()

          businessId = data[0]?.uuid
        }

        const publicationResponse = await PublicationService.postPublication(
          businessId,
          selectedValuesString,
          publicationBody
        )
        // return

        if (
          publicationResponse?.responseStatus === 200 ||
          publicationResponse?.responseStatus === 204
        ) {
          notifySuccessCustom('Publicação agendada com sucesso!')
          navigate('/Draft')
        } else {
          notifyErrorCustom('Erro ao agendar publicação!')
        }
      } else {
        // let publicationImageBucket = await returnUploadImageBucket(imageUrl)

        const imageUrlBuket = ImageLogoSrc?.src
          ? `data:image/png;base64,${ImageLogoSrc?.src}`
          : imageUrl
        let publicationImageBucket = await returnUploadImageBucket(
          imageUrlBuket
        )

        if (publicationImageBucket) {
          let publicationBody = {
            headline: headlineText ? headlineText : '',
            legend: legend || '',
            publicationImageUrl: publicationImageBucket?.fileUrl,
            publicationImageUrlName: publicationImageBucket?.fileName,
            sketch: true,
            scheduleDate: date,
            publicationDate: dateSelected,
            objective: purposeOfPublicationRef?.current,
            socialTypes: selectedValuesString,
            guestId: JSON.parse(window.localStorage.getItem('userData'))
              .guestUserId,
          }

          let businessId

          if (clientData?.uuid) {
            const { data } = await getBusinessByClientId(clientData?.uuid)
            businessId = data[0]?.uuid
          } else {
            const { data } = await BusinessService.getBusinessByLogin()

            businessId = data[0]?.uuid
          }

          const publicationResponse = await PublicationService.postPublication(
            businessId,
            selectedValuesString,
            publicationBody
          )

          if (
            publicationResponse?.responseStatus === 200 ||
            publicationResponse?.responseStatus === 204
          ) {
            notifySuccessCustom('Publicação agendada com sucesso!')
            navigate('/Draft')
          } else {
            notifyErrorCustom('Erro ao agendar publicação!')
          }
        } else {
          notifyErrorCustom('Erro ao salvar arte!')
        }
      }
    }
    setLoading(false)
  }

  const handleExpandOrientationArea = (media) => {
    setExpandMediaArea(true)

    renderButtonMedias(media)
  }

  const handleClick = (value) => {
    // Verifica se o valor já está no array
    if (selectedValues[value]) {
      // Se já estiver no objeto, remove o valor
      const newValues = { ...selectedValues }
      delete newValues[value]
      setSelectedValues(newValues)
    } else {
      // Se não estiver no objeto, adiciona o valor
      setSelectedValues({ ...selectedValues, [value]: true })
    }
  }

  const renderButtonMedias = (media) => {
    return (
      <div className={styles.box_button_orientation}>
        <button
          className={styles.button_orientation}
          //onClick={() => handleOrientationImage('pinterest', 1)}
        >
          Vertical
        </button>
        <button
          className={
            media === null
              ? styles.button_orientation_select
              : styles.button_orientation
          }
        >
          Horizontal
        </button>
        <button className={styles.button_orientation}>Quadrado</button>
      </div>
    )
  }
  return (
    <div className={styles.container_calendar_modal}>
      <Loading enable={loading} />
      <div className={styles.box_calendar_header}>
        <p>Realize seu agendamento</p>

        <button
          className={styles.save_draft}
          onClick={() => {
            handleSavePublicationSketch()
          }}
        >
          <p className={styles.save_draft_title}>Salvar como rascunho</p>
        </button>
      </div>
      <div className={styles.box_calendar_body}>
        <div className={styles.box_calendar_body_container}>
          <div className={styles.box_calendar}>
            <SchedulerCalendar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>

          <div className={styles.box_funnel}>
            <div className={styles.container_hour_carousel}>
              <p className={styles.container_hour_carousel_title}>Horário</p>
              <CarouselHours
                items={hours}
                setValueMinute={setValueMinute}
                valueMinute={valueMinute}
                setValueHour={setValueHour}
                valueHour={valueHour}
              />
            </div>
          </div>

          <div className={styles.box_schedule}>
            <p className={styles.box_schedule_title}>Agendamento</p>
            <div className={styles.box_schedule_platforms}>
              {socialmediaLoged.pinterest && (
                <div className={styles.box_social_media}>
                  <Pinterest
                    width={25}
                    height={25}
                    color={item.pinterest.active ? '#6A0098' : '#E6E6E6'}
                  />
                </div>
              )}

              {socialmediaLoged.linkedin && (
                <div
                  className={styles.box_social_media}
                  onClick={() => handleClick(1)}
                >
                  <Linkedin
                    width={25}
                    height={25}
                    color={selectedValues[1] ? '#6A0098' : '#E6E6E6'}
                  />
                </div>
              )}

              {socialmediaLoged.facebook && (
                <div className={styles.box_social_media}>
                  <CircleFacebook
                    width={25}
                    height={25}
                    color={item.facebook.active ? '#6A0098' : '#E6E6E6'}
                  />
                </div>
              )}

              {socialmediaLoged.instagram && (
                <div className={styles.box_social_media}>
                  <Instagram
                    width={25}
                    height={25}
                    color={item.instagram.active ? '#6A0098' : '#E6E6E6'}
                  />
                </div>
              )}

              {socialmediaLoged.tiktok && (
                <div className={styles.box_social_media}>
                  <TikTok
                    width={25}
                    height={25}
                    color={item.tiktok.active ? '#6A0098' : '#E6E6E6'}
                  />
                </div>
              )}

              {socialmediaLoged.twitter && (
                <div
                  onClick={() => handleClick(2)}
                  className={styles.box_social_media}
                >
                  <Twitter
                    width={25}
                    height={25}
                    color={selectedValues[2] ? '#6A0098' : '#E6E6E6'}
                  />
                </div>
              )}
            </div>
            {!multiplePublication && (
              <div className={styles.area_add_logo}>
                <div
                  className={styles.box_area_add_logo}
                  onClick={handleAddLogo}
                >
                  {addLogo ? (
                    <BoxChecket />
                  ) : (
                    <BoxNotChecket borderColor={'#000'} />
                  )}
                </div>
                <p>Aplicar logo da marca?</p>
              </div>
            )}
            {/* <div className={styles.container_button_orientation}>
              {expandMediaArea && <div>{renderButtonMedias()} </div>}
            </div> */}
            <div className={styles.box_schedule_image_publication}>
              <img
                src={
                  ImageLogoSrc?.src
                    ? `data:image/png;base64,${ImageLogoSrc?.src}`
                    : imageUrl
                }
                className={styles.schedule_image_publication}
              />
            </div>

            <div className={styles.box_schedule_date_hour}>
              <div className={styles.box_schedule_hour}>
                <div className={styles.box_schedule_svg}>
                  <Clock width={30} height={30} color={'#000'} />
                </div>

                <div className={styles.box_schedule_hour_title}>
                  <p className={styles.box_schedule_hour_label}>Horário</p>

                  <p
                    className={styles.box_schedule_hour_text}
                  >{`${valueHour}:${valueMinute}`}</p>
                </div>
              </div>
              <div className={styles.box_schedule_date}>
                <div className={styles.box_schedule_svg}>
                  <DatepikerWhite color={'#000'} />{' '}
                </div>
                <div className={styles.box_schedule_date_title}>
                  <p className={styles.box_schedule_date_label}>Data</p>
                  {/* <p className={styles.box_schedule_date_text}>14 de Junho</p> */}

                  <p className={styles.box_schedule_date_text}>
                    {formatDate(selectedDate)}
                  </p>
                </div>
              </div>
              <div className={styles.box_butons}>
                <button
                  className={styles.button_cancel}
                  onClick={handleCloseModal}
                >
                  Cancelar
                </button>
                {}
                {Object.keys(selectedValues).length > 0 && (
                  <button
                    className={styles.button_save_publi}
                    onClick={() => {
                      handleSavePublication()
                    }}
                  >
                    Salvar Publicação
                  </button>
                )}
                {Object.keys(selectedValues).length <= 0 && (
                  <button
                    className={styles.button_save_publi_disabled}
                    // onClick={() => {
                    //   handleSavePublication()
                    // }}
                    disabled={true}
                  >
                    Salvar Publicação
                  </button>
                )}
                {/* <button
                  className={styles.button_save_publi}
                  onClick={() => {
                    handleSavePublication()
                  }}
                  disabled={true}
                >
                  Salvar Publicação
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarModal
