import PlanCard from '../../components/PlanCard'
import styles from './styles.module.css'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import xamaLogoBranco from '../../assets/logos/xama-logo-branco.png'
import { useTranslation } from 'react-i18next'


function PrivacyPolicies() {
  const { t } = useTranslation()
  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
        <img src={xamaLogoBranco} alt="posteiLogoText" style={{ height: 34 }} />
      </div>
      <div className={styles.container}>
        <h1>{t('privacyPolicies.title')}</h1>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.paragraph1')}
        </p>

        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.paragraph2')}
        </p>

        <h3>{t('privacyPolicies.definitions')}</h3>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.definitions_1')}
        </p>

        <div className={styles.definition}>
          <p className={styles.paragraphPrivacy}>{t('privacyPolicies.definition_1')}</p>
          <p className={styles.paragraphPrivacy}>
            {t('privacyPolicies.definition_1_1')}
          </p>
        </div>

        <div className={styles.definition}>
          <p className={styles.paragraphPrivacy}>
            {t('privacyPolicies.definition_2')}
          </p>
          <p className={styles.paragraphPrivacy}>
            {t('privacyPolicies.definition_2_1')}
          </p>
        </div>

        <div className={styles.definition}>
          <p className={styles.paragraphPrivacy}>{t('privacyPolicies.definition_3')}</p>
          <p className={styles.paragraphPrivacy}>
            {t('privacyPolicies.definition_3_1')}
          </p>
        </div>

        <div className={styles.definition}>
          <p className={styles.paragraphPrivacy}>{t('privacyPolicies.definition_4')}</p>
          <p className={styles.paragraphPrivacy}>
            {t('privacyPolicies.definition_4_1')}
          </p>
        </div>

        <div className={styles.definition}>
          <p className={styles.paragraphPrivacy}>{t('privacyPolicies.definition_5')}</p>
          <p className={styles.paragraphPrivacy}>
            {t('privacyPolicies.definition_5_1')}
          </p>
        </div>

        <div className={styles.definition}>
          <p className={styles.paragraphPrivacy}>{t('privacyPolicies.definition_6')}</p>
          <p className={styles.paragraphPrivacy}>
            {t('privacyPolicies.definition_6_1')}
          </p>
        </div>
        <br />
        <h3>{t('privacyPolicies.introduction')}</h3>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.introduction_1')}
        </p>

        <h3>{t('privacyPolicies.dataTreatment')}</h3>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataTreatment_1')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataTreatment_2')}
        </p>
        <h3>{t('privacyPolicies.dataTreatmentPurpose')}</h3>
        <ul>
          <li>
            <strong>{t('privacyPolicies.dataTreatmentPurpose_1')}</strong> {t('privacyPolicies.dataTreatmentPurpose_1_1')}
          </li>
          <li>
            <strong>{t('privacyPolicies.dataTreatmentPurpose_2')}</strong> {t('privacyPolicies.dataTreatmentPurpose_2_1')}
          </li>
          <li>
            <strong>{t('privacyPolicies.dataTreatmentPurpose_3')}</strong> {t('privacyPolicies.dataTreatmentPurpose_3_1')}
          </li>
          <li>
            <strong>{t('privacyPolicies.dataTreatmentPurpose_4')}</strong> {t('privacyPolicies.dataTreatmentPurpose_4_1')}
          </li>
          <li>
            <strong>{t('privacyPolicies.dataTreatmentPurpose_5')}</strong> {t('privacyPolicies.dataTreatmentPurpose_5_1')}
          </li>
          <li>
            <strong>{t('privacyPolicies.dataTreatmentPurpose_6')}</strong> {t('privacyPolicies.dataTreatmentPurpose_6_1')}
          </li>
          <li>
            <strong>{t('privacyPolicies.dataTreatmentPurpose_7')}</strong> {t('privacyPolicies.dataTreatmentPurpose_7_1')}
          </li>
        </ul>

        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataTreatmentPurpose_8')}
        </p>

        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataTreatmentPurpose_9')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataTreatmentPurpose_10')}
        </p>

        <h3>{t('privacyPolicies.shareData')}</h3>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.shareData_1')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.shareData_1_1')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.shareData_1_2')}
        </p>

        <h3>{t('privacyPolicies.dataRetention')}</h3>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataRetention_1')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataRetention_2')}
        </p>

        <h3>{t('privacyPolicies.dataSecurity')}</h3>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataSecurity_1')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataSecurity_2')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataSecurity_3')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataSecurity_4')}
        </p>
        <p className={styles.important}>
          {t('privacyPolicies.dataSecurity_5')}
        </p>

        <h3>{t('privacyPolicies.dataRights')}</h3>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataRights_1')}
         
          <a href="mailto:contato@posteiapp.com">{t('privacyPolicies.dataRights_2')}</a>.
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataRights_3')}
        </p>

        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataRights_4')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.dataRights_5')}
        </p>

        <h3>{t('privacyPolicies.generalProvisions')}</h3>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.generalProvisions_1')}
        </p>
        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.generalProvisions_2')}
        </p>

        <p className={styles.paragraphPrivacy}>
          {t('privacyPolicies.generalProvisions_3')}
        </p>

        <div className={styles.contact}>
          <p className={styles.paragraphPrivacy}>
            <strong>{t('privacyPolicies.contact')}</strong>{' '}
            <a href="mailto:contato@posteiapp.com">{t('privacyPolicies.contact_1')}</a>
          </p>
        </div>
      </div>
      {/* <div className={styles.container_field}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
            <img
              src={posteiLogoText}
              alt="posteiLogoText"
              style={{ height: 34 }}
            />
          </div>

          <div className={styles.header_container}>
            <h1>Políticas de Privacidade</h1>
            
            <div className={styles.plans_content}>
              <p className={styles.paragraphPrivacy}>
              “Dados Pessoais”: qualquer informação obtida em razão do presente instrumento, relacionada a pessoa natural identificada ou identificável, como por exemplo: nome, CPF, RG, endereço residencial ou comercial, número de telefone fixo ou móvel, endereço de e-mail, informações de geolocalização, entre outros. 
              </p>
              <p className={styles.paragraphPrivacy}>
              “Dados Pessoais Sensíveis”: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;
              </p>
              <p className={styles.paragraphPrivacy}>
              “Titular dos dados”:  pessoa natural a quem se referem os dados pessoais que são objeto de tratamento;
              </p>
              <p className={styles.paragraphPrivacy}>
              “Tratamento”: qualquer operação ou conjunto de operações efetuadas com dados pessoais ou sobre conjuntos de dados pessoais, por meios automatizados ou não automatizados, tais como a coleta, o registro, a organização, a estruturação, a conservação, a adaptação ou alteração, a recuperação, a consulta, a utilização, a divulgação por transmissão, difusão ou qualquer outra forma de disponibilização, a comparação ou interconexão, a limitação, a eliminação ou a destruição. 
              </p>
              <p className={styles.paragraphPrivacy}>
              “LGPD”: É a Lei Geral de Proteção de Dados, Lei nº 13.709;2018.
              </p>
              <p className={styles.paragraphPrivacy}>
              “USUÁRIOS”: Qualquer pessoa que contrate e utilize os serviços da POSTEI.
              </p>
              <h2>
              INTRODUÇÃO
              </h2>
              <p className={styles.paragraphPrivacy}>
              A POSTEI, realiza tratamento de dados pessoais dos usuários que contratam seus serviços e produtos, e não medimos esforços para que estes dados estejam protegidos e tratados em conformidade com a Lei Geral de Proteção de Dados e demais normas correlatas que regem o tema.  
              </p>
              <h2>
              DADOS PESSOAIS TRATADOS E FINALIDADE
              </h2>
              <p className={styles.paragraphPrivacy}>
              O tratamento dos Dados Pessoais está atrelado ao relacionamento do titular com a POSTEI. Os dados utilizados serão aqueles solicitados no cadastro do usuário para contratação dos nossos serviços.
              </p>
              <p className={styles.paragraphPrivacy}>
              A POSTEI, em respeito ao princípio da transparência, detalha abaixo como que ocorre essa situação:
              </p>
              <p className={styles.paragraphPrivacy}>
              Para saber mais sobre o que são cookies e como são tratados na POSTEI, bem como, as suas finalidades, você pode consultar a nossa Política/Aviso de Cookies.
              </p>
              <p className={styles.paragraphPrivacy}>
              A POSTEI em algumas outras situações também poderá realizar o tratamento de Dados Pessoais, como por exemplo, para validar a identidade dos usuários, personalizar a experiência, gerar análises e relatórios estatísticos, realizar pesquisas e análises científicas, manter o bom funcionamento e aprimorar as funcionalidades das plataformas, oferecer descontos e realizar campanhas promocionais e compor o preço dos serviços.
              </p>
              <p className={styles.paragraphPrivacy}>
              Caso o usuário não forneça informações essenciais ao funcionamento e realização dos nossos serviços no nosso aplicativo e website, ficará impossibilitado de utilizar os nossos serviços.
              </p>
              <h2>
              COM QUEM A POSTEI PODERÁ COMPARTILHAR OS SEUS DADOS
              </h2>
              <p className={styles.paragraphPrivacy}>
              Para fornecer os serviços e desenvolver as atividades, a POSTEI poderá operar em parceria com outras organizações. Assim, em certos casos, é necessário compartilhar Dados Pessoais que são confiados com alguns parceiros e fornecedores, bem como com autoridades públicas para cumprir exigências legais, conforme indicado abaixo:
              </p>
              <p className={styles.paragraphPrivacy}>
              Fornecedores e Parceiros: Para viabilizar nossa operação e nossos serviços, a POSTEI busca apoio de fornecedores e parceiros. E, quando verificada a real necessidade para a prestação dos nossos serviços, a POSTEI poderá compartilhar os Dados Pessoais coletados dos usuários com estes parceiros e fornecedores. Um exemplo disso, são aqueles parceiros que utilizamos para processar o pagamento dos serviços contratados. A POSTEI fará a devida avaliação dos parceiros e fornecedores, bem como firmará obrigações contratuais de Segurança da Informação e Proteção de Dados Pessoais com o objetivo de minimizar os riscos envolvidos no tratamento de Dados.
              </p>
              <p className={styles.paragraphPrivacy}>
              Autoridades policiais, judiciais e governamentais: A POSTEI tem a postura de sempre cumprir a lei. Logo, quando houver alguma obrigação legal ou regulatória, exigência de alguma autoridade judicial ou policial com a devida competência, que determine o fornecimento de Dados Pessoas, a POSTEI será obrigada a cumprir e cumprirá. Em caso de ordem abusiva ou excessiva a POSTEI defenderá o direito à privacidade e à proteção de Dados Pessoais dos seus usuários. 
              </p>
              <h2>
              RETENÇÃO DE DADOS PESSOAIS
              </h2>
              <p className={styles.paragraphPrivacy}>
              A POSTEI fará uma análise sobre as eventuais necessidades de retenção para cada tipo de Dado Pessoal. Para tanto, irá considerar a sua natureza, necessidade de coleta, a sua finalidade e a necessidade de retenção para cumprimento de obrigações e resguardar direitos e prevenir responsabilidades. 
              </p>
              <p className={styles.paragraphPrivacy}>
              Assim, os Dados Pessoais são retidos exclusivamente pelo tempo que for necessário para cumprir com as finalidades para as quais foram coletados, salvo se houver outra razão para sua manutenção como, por exemplo, cumprimento de obrigações legais, regulatórias ou contratuais, exercício regular de direitos em processo administrativo, judicial ou arbitral, entre outras permitidas pela LGPD.
              </p>
              <h2>
              COMO SEUS DADOS SERÃO PROTEGIDOS
              </h2>
              <p className={styles.paragraphPrivacy}>
              Temos como prioridade a segurança dos Dados Pessoais e a preservação da privacidade dos nossos usuários. Por isso, a POSTEI não mede esforços para cuidar dos Dados Pessoais e utilizá-los somente para as finalidades descritas nesta Política.
              </p>
              <p className={styles.paragraphPrivacy}>
              Para garantir a privacidade e proteger os Dados Pessoais, a POSTEI emprega recursos tecnológicos avançados e diversas medidas como, por exemplo, rigorosos controles de acesso a sistemas e ambientes de tratamento de Dados.
              </p>
              <p className={styles.paragraphPrivacy}>
              Além dos esforços que a POSTEI busca para proteger os seus Dados Pessoais, é importante que o usuário também adote algumas medidas para manter um ambiente de maior segurança, como (i) manter os dados sempre atualizados; (ii) acessar o aplicativo a partir de redes confiáveis; (iii) não confiar em e-mails estranhos; (iv) não acessar sites suspeitos; (v) manter mecanismos de proteção ativos e atualizados, como antivírus e anti malware; (vi) não instalar aplicativos ou programas de fontes estranhas ou ilegais; e (vii) não acessar “conteúdo promocional” proveniente de fontes desconhecidas.
              </p>
              <p className={styles.paragraphPrivacy}>
              E, em caso de identificação ou de conhecimento de qualquer fator que comprometa a segurança dos Dados tratados nas atividades dos aplicativos e serviços do POSTEI, por favor entre em contato através da Central de Atendimento contato@posteiapp.com .
              </p>
              <h2>
              DIREITOS DOS TITULARES DE DADOS PESSOAIS
              </h2>
              <p className={styles.paragraphPrivacy}>
              A Lei Geral de Proteção de Dados – LGPD garante aos titulares direitos relacionados aos seus Dados Pessoais e, para solicitar o exercício de qualquer um desses direitos garantidos pela lei é só entrar em contato conosco, através do canal contato@posteiapp.com .
              </p>
              <p className={styles.paragraphPrivacy}>
              Quando do envio do e-mail para contato, a depender do caso, a POSTEI poderá pedir informações (e até mesmo documentos) pertinentes que confirmem/comprovem se o requisitante é o próprio titular dos dados ou seu representante legal, como forma de garantir a proteção à privacidade dos titulares.
              </p>
              <p className={styles.paragraphPrivacy}>
              A POSTEI se compromete em atender as solicitações dos titulares dentro do prazo razoável e em conformidade com a legislação aplicável. 
              </p>
              <p className={styles.paragraphPrivacy}>
              O canal supracitado poderá também ser utilizado para tirar dúvidas, fazer comentários e sugestões a respeito a esta Política de Privacidade.
              </p>
              <h2>
              DISPOSIÇÕES GERAIS
              </h2>
              <p className={styles.paragraphPrivacy}>
              A POSTEI estará constantemente buscando melhorar os serviços prestados e a forma como realiza as suas operações. Por isso, esta Política de Privacidade pode passar por atualizações para refletir quaisquer melhorias realizadas. 
              </p>
              <p className={styles.paragraphPrivacy}>
              Por conta disso, a POSTEI recomenda que você visite periodicamente esta página para que se mantenha atualizado.  De qualquer forma, podemos enviar comunicação por e-mail ou indicar nas nossas plataformas sobre as atualizações desta Política de Privacidade para os nossos usuários.
              </p>
              <p className={styles.paragraphPrivacy}>
              Esta Política de Privacidade será utilizada em conjunto com os Termos de Uso e Demais Políticas ou Termos Adicionais aplicados ou que vierem a ser criados para melhor regular a relação da POSTEI com os usuários no que tange à proteção dos Dados Pessoais.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default PrivacyPolicies
