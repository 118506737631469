/* eslint-disable react/prop-types */
import { ListTable } from '../../../components/ListTable'
import { Row } from '../../../components/ListTable/Row'
import { TableItem } from '../../../components/ListTable/TableItem'
import styles from './styles.module.css'
import { Switch } from 'antd'
import {
  Picture,
  CheckBadge,
  Gear,
  Trash,
  MagnifyingGlass,
  Clock,
} from '../../../assets/svgs/icons'
import { useEffect, useState } from 'react'
import { CampaignService, BusinessClient } from '../../../services'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'

import Loading from '../../../components/Loading'

import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_NEW_CAMPAIGN: 'NewCampaign',
}

const CAMPANHAS = {
  1: 'Campanha 1',
  2: 'Campanha 2',
  3: 'Campanha 3',
}

function CampagnList({ step, setStep, setCampaignItem, clientData }) {
  const { t } = useTranslation()
  const headers = [t('campaignList.name'), t('campaignList.objective'), t('campaignList.campaignType'), t('campaignList.actions')]
  const [loading, setLoading] = useState(false)

  const [dataList, setDataList] = useState([])

  const [clientsOptions, setClientsOptions] = useState([])

  const [nameCampaign, setCampaignName] = useState('')

  let timerId
  let current
  let newCurrent

  const handleKeyDown = async (event) => {
    setCampaignName(event.target.value)

    if (timerId) {
      clearTimeout(timerId)
    }

    timerId = setTimeout(async () => {
      current = event.target.value

      if (current.length > 0 && current.length > nameCampaign.length) {
        newCurrent = current.slice(0, -1)
      } else {
        newCurrent = nameCampaign
      }

      if (nameCampaign == newCurrent && current) {
        const { data, responseStatus } =
          await CampaignService.getCampaignByName(
            current,
            clientData?.uuid ? clientData?.uuid : null
          )

        if (responseStatus === 200) {
          setDataList(data)
        }
      } else if (!current) {
        //let businessId

        if (clientData?.uuid) {
          const { data, responseStatus } =
            await CampaignService.getCampaignByClient(clientData?.uuid)
          //businessId = data[0]?.uuid
          if (responseStatus === 200) {
            setDataList(data)
          }
        } else {
          const { data, responseStatus } =
            await CampaignService.getCampaignByNotClient()

          if (responseStatus === 200) {
            setDataList(data)
          }
          // businessId = data[0]?.uuid
        }

        // const { data, responseStatus } =
        //   await CampaignService.getCampaignByUserLogin()
      }
    }, 2000)
  }

  const loadClientData = async () => {
    const { data, responseStatus } = await BusinessClient.getAllBusinessClient()

    if (responseStatus === 200) {
      let newData = data.map((item) => ({
        label: item.companyName,
        value: item.uuid,
      }))
      newData.unshift({ label: 'Todos', value: 1 })
      setClientsOptions(newData)
    }
  }

  const handleNavigate = (campaign) => {
    setStep(STEPS.STEP_NEW_CAMPAIGN)
    setCampaignItem(campaign)
  }
  const handleOpenModal = () => {}

  useEffect(() => {
    setCampaignName('')
    setDataList([])

    loadCampaignData()
    loadClientData()
  }, [step, clientData])

  const handleDeleteCustomer = async (id) => {
    try {
      setLoading(true)
      const { responseStatus } = await CampaignService.deleteCampaign(id)
      setLoading(false)

      if (responseStatus === 200 || responseStatus === 204) {
        notifySuccessCustom('Campanha removida com sucesso')
        // Atualize dataList após a exclusão
        setDataList((prevDataList) =>
          prevDataList.filter((item) => item.id !== id)
        )
        loadCampaignData()
      } else {
        notifyErrorCustom('Não foi possível remover a campanha!')
      }
    } catch (error) {
      console.error('Erro ao excluir campanha', error)
    }
  }

  const handleChecked = async (data) => {
    //return

    let uuid = data?.uuid
    let newData = data

    delete newData.uuid
    delete newData.createdAt
    delete newData.updatedAt
    delete newData.deletedAt
    delete newData.isParameterized

    delete newData.userAccountId
    delete newData.businessClientId

    if (!newData.productId) {
      delete newData.productId
    }
    if (!newData.targetAudienceId) {
      delete newData.targetAudienceId
    }

    newData.enabled = newData.enabled ? false : true

    newData.guestId = JSON.parse(window.localStorage.getItem('userData'))
      ?.guestUserId
      ? JSON.parse(window.localStorage.getItem('userData'))?.guestUserId
      : ''

    setLoading(true)
    const { responseStatus } = await CampaignService.putCampaign(uuid, {
      ...newData,
    })

    setLoading(false)

    if (responseStatus === 200 || responseStatus === 204) {
      loadCampaignData()
      notifySuccessCustom('Status alterado com sucesso!')
    } else {
      notifyErrorCustom('Não foi possível alterar o status!')
    }
  }

  const handleChangeClient = async (idClient) => {
    setLoading(true)

    //const { responseStatus } = await CampaignService.getCampaignByClient(idClient)
    const { data, responseStatus } =
      idClient == 1
        ? await CampaignService.getCampaignByUserLogin()
        : await CampaignService.getCampaignByClient(idClient)

    setLoading(false)

    if (responseStatus === 200 || responseStatus === 204) {
      //loadCampaignData()
      setDataList(data)
    }
  }

  const loadCampaignData = async () => {
    if (clientData?.uuid) {
      const { data, responseStatus } =
        await CampaignService.getCampaignByClient(clientData?.uuid)

      if (responseStatus === 200) {
        setDataList(data)
      }
    } else {
      const { data, responseStatus } =
        await CampaignService.getCampaignByNotClient()

      if (responseStatus === 200) {
        setDataList(data)
      }
    }
  }

  return (
    <>
      <Loading enable={loading} />
      <div className={styles.container_page}>
        <div className={styles.header_page}>
          <div className={styles.header_page_box}>
            <div className={styles.title_new_posts}>
              <p className={styles.title_new_posts_label}>Campanha</p>
              <p className={styles.title_new_posts_title}>Lista de Campanhas</p>
            </div>

            <div className={styles.bottomContainer}>
              {/* <div className={styles.topSelectClient}>
                <SelectSquare
                  htmlFor="select"
                  placeholder="Clientes"

                  options={clientsOptions}
                  onClickItem={({ value }) => {
                    handleChangeClient(value)
                  }}
                  round
                />
              </div> */}
              <div className={styles.topFindCampaign}>
                <input
                  className={styles.campaignSearch}
                  placeholder={t('campaignList.campaignSearch')}
                  onChange={(e) => handleKeyDown(e)}
                />
                <div className={styles.box_action_icon}>
                  <MagnifyingGlass color={'#000'} />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.header_page_box_button}>
            <button
              className={styles.button_new_user}
              // onClick={() => handleExpandForm()}
              onClick={() => handleNavigate()}
            >
              {t('campaignList.newCampaign')}
            </button>
          </div>
        </div>

        <div className={styles.container_content}>
          <ListTable headers={headers}>
            {dataList?.map((campaign, index) => (
              // eslint-disable-next-line react/jsx-key
              <Row key={index}>
                <TableItem>
                  <div className={styles.box_name_campaign}>
                    <div>
                      <p className={styles.name_campaign_text}>
                        {campaign?.name}
                      </p>
                    </div>
                  </div>
                </TableItem>
                <TableItem>{campaign?.objective}</TableItem>
                <TableItem>
                  <span className={styles.bold}>
                    {campaign?.campaignTypeId}
                  </span>
                </TableItem>
                <TableItem style={{ maxWidth: 170 }}>
                  <div className={styles.last_column}>
                    <div className={styles.icons_container}>
                      <div className={styles.icon}>
                        <div className={styles.box_action_icon}>
                          <Switch
                            size="small"
                            style={{
                              backgroundColor: campaign?.enabled ? 'black' : '',
                            }}
                            checked={campaign?.enabled}
                            onClick={() => handleChecked(campaign)}
                          />
                        </div>

                        {campaign?.isParameterized ? (
                          <div
                            onClick={handleOpenModal}
                            className={styles.icon}
                          >
                            <CheckBadge color="black" />
                          </div>
                        ) : (
                          <div
                            onClick={handleOpenModal}
                            className={styles.icon}
                          >
                            <Clock color={'black'} />
                          </div>
                        )}
                        <div
                          className={styles.box_action_icon}
                          onClick={() => handleNavigate(campaign)}
                        >
                          <Gear color={'#000'} />
                        </div>
                        <div
                          className={styles.box_action_icon}
                          onClick={() => handleDeleteCustomer(campaign.uuid)}
                        >
                          <Trash color={'#000'} />
                        </div>
                        <div className={styles.box_action_icon}>
                          <Picture color={'#000'} />
                        </div>
                      </div>
                    </div>
                  </div>
                </TableItem>
              </Row>
            ))}
          </ListTable>
        </div>
      </div>
    </>
  )
}

export default CampagnList
