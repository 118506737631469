import { Navigate, useOutlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export const PublicLayout = () => {
  const { userData, quickRegistration } = useAuth()

  const outlet = useOutlet()
  const location = useLocation()

  if (location.pathname === '/') {
    return <Navigate to="/SignIn" />
  }

  if (userData) {
    return <Navigate to="/HomePage" replace />
  }

  return <div style={{ height: '100%' }}>{outlet}</div>
}
