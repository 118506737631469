import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MyAccountStep } from './Steps/MyAccountStep'
import styles from './styles.module.css'
import { AccessData } from './Steps/AccessData'
import SideMenuMyAccount from '../../components/SideMenuMyAccount'
import { Parameterizations } from './Steps/Parameterizations'
import { LogOutStep } from './Steps/LogOutStep'
import { SocialMedia } from './Steps/SocialMedia'
import { NotificationsStep } from './Steps/NotificationsStep'
import { useLocation } from 'react-router-dom'

export const MyAccountPage = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const location = useLocation()

  useEffect(() => {
    if (location?.state?.queryCode || location?.state?.redirect) {
      setStep(4)
    }
  }, [])

  return (
    <div className={styles.container_my_account}>
      <div className={styles.title_my_data}>
        <p className={styles.title_my_data_label}>{t('my_account.title')}</p>
        <p className={styles.title_my_data_title}>{t('my_account.subtitle')}</p>
      </div>

      <div className={styles.container_page}>
        <SideMenuMyAccount setStep={setStep} step={step} />

        {step == 0 && <MyAccountStep />}
        {step == 1 && <AccessData setStep={setStep} />}
        {step == 2 && <NotificationsStep setStep={setStep} />}
        {step == 3 && <Parameterizations />}
        {step == 4 && <SocialMedia />}
        {step == 5 && <LogOutStep setStep={setStep} />}
      </div>
    </div>
  )
}
