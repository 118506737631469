
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css'
import {func, string} from 'prop-types'
export const HeaderProductPage = ({onClickRightBtn, onClickLeftBtn, btnLeftText, btnRightText, title}) => {
  const {t} = useTranslation();

  return (
    <div className={styles.header_page}>
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>{t("products_title")}</p>
        <p className={styles.title_new_posts_title}>{title}</p>
      </div>
      <div className={styles.button_container}>
      {btnRightText && (
          <button 
            className={styles.button_new_user} 
            onClick={onClickRightBtn}>
            {btnRightText}
          </button>

        )}
        {btnLeftText && (

          <button 
            className={styles.button_new_user} 
            onClick={onClickLeftBtn}>
            {btnLeftText}
          </button>
        )}
       
      </div>
    </div>
  )
}

HeaderProductPage.propTypes = {
  onClickRightBtn:func,
  onClickLeftBtn:func,
  btnLeftText:string,
  btnRightText:string,
  title:string
}