import { useEffect, useState } from 'react'
import {
  ProductService,
  PythonService,
  TypeOfProductsServices,
} from '../../services'
import { formatPrice } from '../../utils/format'
import { isEmpty } from '../../utils/verifyObject'
import { notifyErrorCustom, notifySuccessCustom } from '../../utils/notify'
import * as Yup from 'yup'

import { useClient } from '../../hooks/useClient'
import { useTranslation } from 'react-i18next'

export const useProductPage = () => {
  const {t} = useTranslation();

  const { clientData } = useClient()

  const headers = [
    `${t("products_table_name")}`,
    `${t("products_table_type")}`,
    `${t("products_table_price")}`,
    `${t("products_table_promotional_price")}`,
    `${t("products_table_action")}`,
  ]

  const [step, setStep] = useState(0)
  const [productOptions, setProductOptions] = useState([])
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState({})
  const [error, setError] = useState([])
  const [imagesToRender, setImagesToRender] = useState([])

  useEffect(() => {
    setStep(0)
    loadTypeOfProduct()
    loadAllProducts()
  }, [clientData])

  const formScheema = Yup.object().shape({
    name: Yup.string().required('Informe o nome do produto'),
    productTypeId: Yup.number().required('selecione um tipo de produto'),
    // promotionalValue:Yup.string().required('Informe o preço promocional'),
    value: Yup.string().required('Informe o preço do produto'),
    description: Yup.string().required('Informe a descrição do produto'),
  })

  // Valide os dados de acordo com formScheema
  const validateFormData = (data) => {
    if (data) {
      const isValid = formScheema
        .validate(data, { abortEarly: false })
        .then(() => {
          setError([])
          return true
        })
        .catch((err) => {
          setError(err.inner.map((e) => ({ id: e.path, message: e.message })))
          return false
        })

      return isValid
    }
  }
  // Faça o debrounce para evitar chamadas repetitivas no onChange
  useEffect(() => {
    // Função de debounce
    const debounceValidation = setTimeout(() => {
      if (!isEmpty(product)) validateFormData(product)
    }, 300)

    return () => {
      // Limpe o timeout anterior para evitar chamadas duplicadas
      clearTimeout(debounceValidation)
    }
  }, [product])

  const handleClearSelectedProduct = (step) => {
    if (step !== 0) {
      setProduct({})
      setImagesToRender([])
    }

    setStep(step === 0 ? 1 : 0)
  }

  const handleChangeProductData = (k, v) => {
    if (k === 'value' || k === 'promotionalValue') v = formatPrice(v)

    setProduct((prevState) => ({ ...prevState, [k]: v }))
  }

  const loadTypeOfProduct = async () => {
    const typeOfProductReturn = await TypeOfProductsServices.getTypeOfProducts()

    if (typeOfProductReturn.responseStatus === 200) {
      setProductOptions(
        typeOfProductReturn.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      )
    }
  }

  const loadAllProducts = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await ProductService.getProductByNotBusinessClient()

      if (responseStatus === 200) setProducts(data)
    } else {
      const { data, responseStatus } =
        await ProductService.getProductByBusinessClientId(clientData?.uuid)

      if (responseStatus === 200) setProducts(data)
    }
  }

  const loadProductImage = async (uuid) => {
    const { data, responseStatus } = await ProductService.getProductImages(uuid)

    console.log('121 ===> ', data)
    if (responseStatus === 200) {
      setImagesToRender(
        data.map((image) => {
          const {
            fileName,
            fileContentType,
            fileFormat,
            fileUrl,
            uuid,
            fileBase64,
          } = image
          return {
            uuid,
            fileName,
            fileContentType,
            fileFormat,
            fileUrl,
            isBase64: false,
            status: 'default',
            fileBase64,
          }
        })
      )
    }
  }

  const handleSubmit = async () => {
    if (!(await formScheema.isValid(product))) {
      return notifyErrorCustom('Preencha o formulario corretamente')
    }
    let guestId = JSON.parse(
      window.localStorage.getItem('userData')
    ).guestUserId

    console.log('154', product)

    if (product?.uuid) {
      const {
        uuid,
        promotionalValue,
        value,
        name,
        productTypeId,
        description,
      } = product

      let productData

      if (guestId) {
        productData = {
          promotionalValue,
          value,
          name,
          description,
          productTypeId,
          guestId,
        }
      } else {
        productData = {
          promotionalValue,
          value,
          name,
          description,
          productTypeId,
        }
      }
      console.log('186', uuid)
      //atualize o produto
      await ProductService.putProduct(uuid, productData)

      console.log('190', imagesToRender)

      //atualize as imagens do produto
      if (imagesToRender.length > 0 && uuid) {
        imagesToRender.map(async (image) => {
          console.log('184 Image', image)
          //return
          if (image.isBase64 && image.status != 'deleted') {
            const uploadImageBody = mountImageBodyUpload(
              image?.fileUrl,
              image?.fileName,
              image?.fileFormat,
              uuid
            )
            await ProductService.postUploadImageProduct(uploadImageBody)
          }
          //delete a imagem caso status seja 'deleted'
          if (image.status === 'deleted' && image?.uuid) {
            console.log('203 Image', image)
            //return
            await ProductService.deleteUploadImageProduct(image?.uuid)
          }
        })
      }
    }

    if (!product?.uuid) {
      let bodyProduct = {
        ...product,
      }

      if (clientData?.uuid) {
        bodyProduct = {
          ...bodyProduct,
          businessClientId: clientData?.uuid,
        }
      }

      if (guestId) {
        bodyProduct = {
          ...bodyProduct,
          guestId: guestId,
        }
      }

      const { data, responseStatus } = await ProductService.postProduct(
        bodyProduct
      )

      if (responseStatus === 200 && data?.uuid && imagesToRender.length > 0) {
        imagesToRender.map(async (image) => {
          if (image.isBase64 && image.status != 'deleted') {
            const uploadImageBody = mountImageBodyUpload(
              image?.fileUrl,
              image?.fileName,
              image?.fileFormat,
              data?.uuid
            )
            await ProductService.postUploadImageProduct(uploadImageBody)
          }
        })
      }

      notifySuccessCustom('Produto criado corretamente')
    }

    setImagesToRender([])
    setProduct({})
    loadAllProducts()
    setStep(0)
  }

  const mountImageBodyUpload = (base64Str, name, type, productId) => {
    const formats = ['jpg', 'png', 'jpeg', 'webp']
    const stringReference = 'base64,'
    const index = base64Str.indexOf(stringReference) + stringReference.length
    let fileFormat = ''
    const fileBase64 = base64Str.substr(index)
    formats.map((format) => {
      if (base64Str.includes(format)) fileFormat = format
    })

    return {
      productId,
      fileName: name,
      fileFormat,
      fileContentType: type,
      fileBase64,
    }
  }

  const handleChangeImageToRender = (e) => {
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result
        let fileDataReturn = {
          fileUrl: fileDataBase64,
          isBase64: true,
          status: 'create',
          fileName: file.name,
          fileFormat: file.type,
          fileContentType: 'image/' + file.type,
        }

        setImagesToRender((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  const handleExcludeImageRendered = (index) => {
    imagesToRender[index].status = 'deleted'
    setImagesToRender([...imagesToRender])
  }

  const handleRemoveBackground = async (index, base64) => {
    console.log('290', base64)

    const { data } = await PythonService.postRemoveBackground({ image: base64 })

    console.log('294', data)
    const noBgImage = data.data.images

    imagesToRender[index].fileUrl = noBgImage[1]?.image
    imagesToRender[index].isOriginal = noBgImage[1]?.isOriginal

    setImagesToRender([...imagesToRender])
  }

  const handleDeleteProduct = async (id) => {
    await ProductService.deleteProduct(id)
    await loadAllProducts()
    notifySuccessCustom('item excluido com sucesso', '')
  }

  const handleClickEdit = async (product) => {
    setProduct({ ...product })
    await loadProductImage(product?.uuid)
    setStep(1)
  }

  const handleClickVisualize = async (product) => {
    setStep(2)
    setProduct({ ...product })
    await loadProductImage(product?.uuid)
  }

  return {
    handleClickEdit,
    handleExcludeImageRendered,
    handleRemoveBackground,
    handleDeleteProduct,
    handleChangeImageToRender,
    handleSubmit,
    handleChangeProductData,
    handleClickVisualize,
    handleClearSelectedProduct,
    products,
    product,
    setProduct,
    productOptions,
    headers,
    step,
    setStep,
    error,
    imagesToRender,
  }
}
