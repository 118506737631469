import { useState } from 'react'
import styles from './styles.module.css'
import { NavigateArrowLeft } from '../../assets/svgs/icons'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export const SchedulerCalendar = ({ selectedDate, setSelectedDate }) => {
  const { t } = useTranslation()
  const monthNames = [
    t('monthNames.january'),
    t('monthNames.february'),
    t('monthNames.march'),
    t('monthNames.april'),
    t('monthNames.may'),
    t('monthNames.june'),
    t('monthNames.july'),
    t('monthNames.august'),
    t('monthNames.september'),
    t('monthNames.october'),
    t('monthNames.november'),
    t('monthNames.december'),
  ]

  const [currentDate] = useState(dayjs()) // Get the current date

  const [currentYear, setCurrentYear] = useState(currentDate.year())
  const [currentMonth, setCurrentMonth] = useState(currentDate.month())
  // const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()
  const daysInMonth = dayjs()
    .year(currentYear)
    .month(currentMonth)
    .daysInMonth()

  const today = new Date()

  const renderDays = () => {
    const days = []

    for (let day = 1; day <= daysInMonth; day++) {
      // const date = new Date(currentYear, currentMonth, day)

      // today.setHours(0, 0, 0, 0)
      // date.setHours(0, 0, 0, 0)

      // const isPastDate = date < today

      // const isCurrentDate = date.getTime() === today.getTime()

      // const isSelected = date.toDateString() == selectedDate
      const date = dayjs()
        .year(currentYear)
        .month(currentMonth)
        .date(day)
        .startOf('day')
      const today = dayjs().startOf('day')

      const isPastDate = date.isBefore(today)
      const isCurrentDate = date.isSame(today)
      const isSelected = date.isSame(dayjs(selectedDate), 'day')

      days.push(
        <div
          className={`${styles.day_box} ${
            isCurrentDate
              ? styles.current_day
              : isPastDate
              ? styles.disabled
              : ''
          }`}
          key={day}
          onClick={() => {
            if (!isPastDate) {
              handleDateClick(date)
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 40,
            }}
          >
            <div
              className={` ${styles.day} ${isSelected ? styles.selected : ''}`}
            >
              {day}
            </div>
          </div>
        </div>
      )
    }

    return days
  }

  function handleNextMonth() {
    if (currentMonth === 11) {
      setCurrentMonth(0)
      setCurrentYear(currentYear + 1)
    } else {
      setCurrentMonth(currentMonth + 1)
    }
  }

  function handlePreviusMonth() {
    if (currentMonth === 0) {
      setCurrentMonth(11)
      setCurrentYear(currentYear - 1)
    } else {
      setCurrentMonth(currentMonth - 1)
    }
  }

  function handleDateClick(date) {
    console.log('date', date)
    // setSelectedDate(date.toDateString())
    setSelectedDate(date)
  }

  // const findDate = (mes, dia, ano) => {
  //   let date = mes + '-' + dia + '-' + ano
  //   let verifyDate = datesData.find((dateData) => dateData === date)

  //   if (verifyDate) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  return (
    <div className={styles.calendar_wrapper}>
      <div className={styles.calendar_header}>
        <h3>{monthNames[currentMonth] + ' ' + currentYear}</h3>
        <div className={styles.box_calendar_button_navigate}>
          <div
            className={styles.calendar_button_navigate}
            onClick={handlePreviusMonth}
          >
            <NavigateArrowLeft />
          </div>
          <div
            className={styles.calendar_button_navigate_left}
            onClick={handleNextMonth}
          >
            <NavigateArrowLeft />
          </div>
        </div>
      </div>
      <div className={styles.calendar}>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>D</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>S</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>T</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>Q</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>Q</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>S</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>S</b>
        </div>

        {new Array(new Date(currentYear, currentMonth, 1).getDay())
          .fill(null)
          .map((_, index) => (
            <div
              key={`empty-${index}`}
              className={`${styles.empty_day} ${styles.disabled} `}
            ></div>
          ))}

        {renderDays()}

        {new Array(
          6 - new Date(currentYear, currentMonth, daysInMonth).getDay()
        )
          .fill(null)
          .map((_, index) => (
            <div
              key={`empty-${daysInMonth + index}`}
              className={`${styles.empty_day} ${styles.disabled} `}
            ></div>
          ))}
      </div>
    </div>
  )
}

export default SchedulerCalendar
