import styles from './styles.module.css'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  SubscriptionService,
  UserAccountBusinessService,
  UserAccountsService,
  UserService,
} from '../../services'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../utils/notify'
import { useAuth } from '../../hooks/useAuth'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { motion } from 'framer-motion'


function CancelPlan() {
  const navigation = useNavigate()
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleNavigate = (route) => {
    navigation(`${route}`)
  }
  const handleCancelPlanAccount = async (e) => {
    console.log('eeeee', e)
    if (!e) {
      notifyErrorCustom(<>Preencha o campo com o código enviado por e-mail.</>)
      return
    }
    const userAccount = await UserAccountsService.getUserAccountByUser(e)
    console.log('userAccount', userAccount)

    if (userAccount.data == null) {
      notifyErrorCustom(
        <>
          Código invalido. <br /> Por favor verifique o código e tente
          novamente.
        </>
      )
      return
    }

    const subscribtion = await SubscriptionService.getSubscriptionIdUserAccount(
      userAccount.data.uuid
    )
    console.log('subscribtion', subscribtion)

    if (subscribtion.data != null) {
      const retDisable = await UserService.disableUser(e)
      console.log('retDisable', retDisable)

      if (retDisable.data == 200) {
        try {
          await SubscriptionService.subscriptionsInative(
            subscribtion.data.uuid,
            subscribtion.data.pagarmeSubscriptionId
          )
          notifySuccessCustom('Procedimento concluido com sucesso.')
          handleNavigate('/SignIn')
        } catch (error) {
          notifyErrorCustom(
            'Ocorreu um erro inesperado favor entrar em contato com o suporte.'
          )
        }
      }
    } else {
      const retDisable = await UserService.disableUser(e)
      console.log('retDisable', retDisable)
      if (retDisable.data == 200) {
        notifySuccessCustom('Procedimento concluido com sucesso.')
        handleNavigate('/SignIn')
      }
    }

    // navigation(`${route}`)
  }

  return (
    <motion.div
      // key={expanded}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%', height: '100%' }}
    >
      <div className={styles.page}>
        <div className={styles.container_form}>
          <form>
            <div className={styles.form_header}>
              <div className={styles.logo}>
                <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
                <img
                  src={posteiLogoText}
                  alt="posteiLogoText"
                  style={{ height: 34 }}
                />
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.form_body}>
              <h1>Cancelamento.</h1>
              <div className={styles.form_body_title}>
                <p>
                  Atenção: Ao prosseguir, sua conta será desativada
                  permanentemente.
                </p>
                <p>
                  Utilize o código recebido por e-mail e clique em prosseguir.
                </p>
              </div>

              <div className={styles.form_body_content}>
                <input
                  className={styles.input}
                  placeholder="Preencha com o código recebido por e-mail"
                  value={inputValue}
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>

            <div className={styles.form_footer}>
              <button
                className={styles.button_cancel}
                onClick={() => handleNavigate('/')}
              >
                Cancelar
              </button>
              <button
                className={styles.button_register}
                onClick={(e) => {
                  e.preventDefault()
                  handleCancelPlanAccount(inputValue)
                }}
              >
                Prosseguir
              </button>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  )
}

export default CancelPlan
