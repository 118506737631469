/* eslint-disable react/prop-types */
import styles from './styles.module.css'
// import { useNavigate } from 'react-router-dom'
import {
  Accept,
  // CircleX,
  Cross,
  NumberOne,
  NumberThree,
  NumberTwo,
  Pen,
  Pen2,
  // Refresh,
  WhiteRefresh,
} from '../../../assets/svgs/icons'
import { useEffect, useState } from 'react'
import { SelectRound } from '../../../components/Select/SelectRound'
import { motion } from 'framer-motion'
import { useClient } from '../../../hooks/useClient'
import { getBusinessByClientId } from '../../../services/business'
import {
  BusinessService,
  EditorialLineService,
  ProductService,
  PythonService,
  TargetAudienceService,
  VoiceToneService,
} from '../../../services'
import { getLegendsService } from '../../../services/postsServices/getLegendsService'
import Loading from '../../../components/Loading'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_THIRD_PASS_PUBLICATION: 'stepPublication',
  STEP_FIFTH_PASS_SCHEDULE: 'stepSchedule',
}

function StepFourtPass({
  setStep,
  imageArray,
  selectedArrayImage,
  headlineText,
  legend,
  legendList,
  setLegendList,
  setLegend,
  campaignRef,
  headlineList,
  selectFunnel,
  headlineGpt,
  imagelayers,
  // stateLegend,
  // setStateLegend,
  // selectedObjectItem,
  imageLayers,
  selectedArrayImageObject,
}) {
  const { t } = useTranslation()
  // const navigation = useNavigate()
  const { clientData } = useClient()

  const [loading, setLoading] = useState(false)
  const [loop, setloop] = useState(selectedArrayImage.length)
  // const [editableItems, setEditableItems] = useState([])

  useEffect(() => {
    if (legendList.length === 0) {
      legendGenerate()
    }
  }, [])

  const deleteLegend = async (index) => {
    const legendListCopy = { ...legendList }

    if (index in legendListCopy) {
      delete legendListCopy[index]
      setLegendList(legendListCopy)
    } else console.log(t('stepFourthPass.deleteLegend.indexNotFound'))

    setLegendList(legendListCopy)
    const copyState = { ...legendList }
    copyState[index] = false
    // setStateLegend(copyState)
    return
  }

  const confirmLegend = async (index) => {
    const copyState = { ...stateLegend }
    copyState[index] = false
    // setStateLegend(copyState)
  }

  const legendGenerate = async (indexLegend, regenerate) => {
    setLoading(true)

    let businessId
    let descriptionBusines

    let getEditorialLine
    let editorialLine

    for (let index = 0; index < loop; index++) {
      if (clientData?.uuid) {
        getEditorialLine = await EditorialLineService.getEditorialLineByClient(
          clientData?.uuid
        )
        editorialLine = getEditorialLine.data[0].bodyJson
      } else {
        getEditorialLine = await EditorialLineService.getEditorialLinebyLogin()
        editorialLine = getEditorialLine.data[0].bodyJson
      }

      const targetAudiencesInfos =
        await TargetAudienceService.getAllTargetAudienceById(
          campaignRef?.targetAudienceId
        )

      const productInfos = campaignRef?.productId
        ? await ProductService.getProductById(campaignRef?.productId)
        : undefined

      const retGpt = {
        flow: 'multiples_publications',
        prompt: 'legend',
        post_description: campaignRef.objective, //editorialLine.dados_da_marca.descricao_negocio,
        brand: editorialLine.dados_da_marca.nicho,
        funil: selectFunnel,
        niche: editorialLine.dados_da_marca.nicho,
        subniche: editorialLine.dados_da_marca.subnicho,
        campaign_description: campaignRef.objective,
        target_public: {
          name: targetAudiencesInfos.data[0].name || '',
          gender: targetAudiencesInfos.data[0].genderValue || '',
          social_class:
            targetAudiencesInfos.data[0].socialClassValue.join(', ') || '',
          profession: targetAudiencesInfos.data[0].profession || '',
          region: 'Nacional, com foco em grandes centros urbanos',
        },
        voice_tone: editorialLine.tom_de_voz,
        product: {
          name: productInfos?.data?.name || '',
          type: productInfos?.data?.productTypeValue || '',
          description: productInfos?.data?.description || '',
          value: productInfos?.data?.value || '',
          promotional_value: productInfos?.data?.promotionalValue || '',
        },
        headlines: headlineGpt.data.data.headlines[0],
      }

      let retEditorialLine = await PythonService.postPromptGPT(retGpt)

      // if (clientData?.uuid) {
      //   const { data } = await getBusinessByClientId(clientData?.uuid)

      //   businessId = data[0]?.uuid
      //   descriptionBusines = data[0]?.description
      // } else {
      //   const { data } = await BusinessService.getBusinessByLogin()

      //   businessId = data[0]?.uuid
      //   descriptionBusines = data[0]?.description
      // }

      // const productInfos = await ProductService.getProductById(
      //   campaignRef?.current?.productId
      // )

      // const voiceToneInfos = await VoiceToneService.getVoiceToneByBusiness(
      //   businessId
      // )
      // // const businessInfos = await BusinessService.getBusinessByLogin()
      // const targetAudiencesInfos =
      //   await TargetAudienceService.getAllTargetAudienceById(
      //     campaignRef?.current?.targetAudienceId
      //   )

      // const bodyLegend = {
      //   headlineSelected: headlineText,
      //   publicationPurpose: campaignRef?.current?.objective,
      //   descriptionBusiness: descriptionBusines,
      //   voiceTone: voiceToneInfos?.data[0]?.tone,
      //   socialClass: targetAudiencesInfos?.data[0]?.educationLevelValue,
      //   gender: targetAudiencesInfos?.data[0]?.genderValue,
      //   profession: targetAudiencesInfos?.data[0]?.profession,
      //   nameProduct: productInfos?.data?.name,
      //   productType: productInfos?.data?.productTypeValue,
      //   productDescription: productInfos?.data?.description,
      //   value: productInfos?.data?.value,
      //   promotionalValue: productInfos?.data?.promotionalValue,
      // }

      // const data = await getLegendsService(bodyLegend)

      //setLegend(retEditorialLine?.data?.data[0]?.message)

      if (regenerate === 'regenerate') {
        if (indexLegend === index && indexLegend !== undefined) {
          // Atualiza a legenda na posição específica do array
          setLegendList((prevList) => {
            const newList = [...prevList]
            newList[indexLegend] = retEditorialLine.data.data[0]
            return newList
          })
        } else {
          setLegendList((prevList) => {
            const newList = [...prevList]
            newList[index] = legendList[index]
            return newList
          })
          // Atualiza a legenda normalmente
          // setLegend(retEditorialLine.data.data[0])
          // setStateLegend(retEditorialLine.data.data[0])
          // return
        }
      } else {
        setLegendList((prevList) => {
          const newList = [...prevList]
          newList[index] = retEditorialLine.data.data[0]
          return newList
        })
      }

      // if (
      //   regenerate === 'regenerate' &&
      //   indexLegend === index &&
      //   indexLegend !== undefined
      // ) {

      //   // Atualiza a legenda na posição específica do array
      //   setLegendList((prevList) => {
      //     const newList = [...prevList]
      //     newList[indexLegend] = retEditorialLine.data.data[0]
      //     return newList
      //   })
      // } else {

      //   setLegendList((prevList) => {
      //     const newList = [...prevList]
      //     newList[index] = retEditorialLine.data.data[0]
      //     return newList
      //   })
      //   // Atualiza a legenda normalmente
      //   // setLegend(retEditorialLine.data.data[0])
      //   // setStateLegend(retEditorialLine.data.data[0])
      //   // return
      // }

      // setStateLegend((prevState) => [
      //   ...prevState,
      //   retEditorialLine?.data?.data[0],
      // ])

      //legendList[0] = retEditorialLine?.data?.data[0]?.message

      // setLegend(retEditorialLine.data.data[0])
    }
    setLoading(false)
  }

  // const [activeImputLegend, setActiveImputLegend] = useState(true)
  // const [activeInputs, setActiveInputs] = useState(
  //   Array(selectedArrayImage.length).fill(false)
  // )

  // const [legendValue, setLegendValue] = useState(legendList)

  // const legendUpdate = (index) => {

  //   const findItemOpen = editableItems.find((item) => item === index)

  //   if (findItemOpen !== undefined) {
  //     const newEditableItems = editableItems.filter((item) => item !== index)

  //     setEditableItems(newEditableItems)
  //   } else {
  //     editableItems.push(index)
  //   }
  // }

  // const handleLegendChange = (e, index) => {
  //   const updatedLegendValue = [...legendValue] // Faz uma cópia do array original
  //   updatedLegendValue[index] = e.target.value // Atualiza o valor no índice especificado
  //   setLegendValue(updatedLegendValue) // Define o novo array como o estado
  //   setLegendList(updatedLegendValue)
  // }

  // const handleLegendChange = (e, index) => {
  //   const newLegend = [...stateLegend]
  //   newLegend[index] = e.target.value
  //   setStateLegend(newLegend)
  // }

  const handleLegendChange = (value, index) => {
    setLegendList((prevLegendList) => {
      const newLegendList = [...prevLegendList]
      newLegendList[index] = value
      return newLegendList
    })
  }

  const nextButton = async (navigation) => {
    if (navigation === 'back') {
      if (legendList.length > 0) {
        setLegendList([])
      }
      setStep('stepArts')
    }
    if (navigation === 'next') {
      setStep(STEPS.STEP_FIFTH_PASS_SCHEDULE)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container_header}
    >
      <Loading enable={loading} />
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>{t('stepFourthPass.title')}</p>
        <p className={styles.title_new_posts_title}>{t('stepFourthPass.subtitle')}</p>
      </div>
      <div className={styles.title_steps}>
        <div className={styles.first_pass}>
          <div>
            <NumberOne width={'40px'} height={'40px'} />
          </div>
          <div className={styles.first_pass_title}>
            <p className={styles.first_pass_title_text}>
              {t('stepFourthPass.pass1')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFourthPass.pass_subtitle1')}
            </p>
          </div>
        </div>

        <div className={styles.second_pass}>
          <div>
            <NumberTwo width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>
              {t('stepFourthPass.pass2')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFourthPass.pass_subtitle2')}
            </p>
          </div>
        </div>

        <div className={styles.third_pass}>
          <div>
            <NumberThree width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>
              {t('stepFourthPass.pass3')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFourthPass.pass_subtitle3')}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.container_content}>
        {selectedArrayImage.map((item, index) => {
          const [openEdit, setOpenEdit] = useState(true)

          const legendUpdate = (index) => {
            setOpenEdit((current) => !current)
          }

          return (
            <div key={index} className={styles.card_subtitle}>
              <div className={styles.box_image_publication}>
                <img src={item} className={styles.image_publication} />
              </div>
              <div className={styles.box_title_publication}>
                {/* <p className={styles.title_publication}>{headlineText}</p> */}
                <p className={styles.title_image}>
                  {/* {
                      selectedObjectItem[index]?.params.filter(
                        (item) => item.name === 'titulo'
                      )[0].value
                    } */}
                  {selectedArrayImageObject[
                    index
                  ]?.tempImageLayers?.params.filter(
                    (item) => item?.name === 'titulo'
                  )[0]?.value || ''}
                </p>
                <p className={styles.title_publication}>
                  {headlineList[index]}
                </p>
              </div>
              {legendList[index] && (
                <>
                  {/* <div>
                    <p className={styles.}>
                      {
                        selectedObjectItem[index].params.filter(
                          (item) => item.name === 'titulo'
                        )[0].value
                      }
                    </p>
                  </div> */}
                  <div>
                    {/* <SelectRound
                      className={styles.select_search}
                      htmlFor="select"
                      placeholder="Selecione"
                      defaultSelected={{ label: 'Orientação', value: '' }}
                      options={[
                        { label: 'Teste', value: 1 },
                        { label: 'Teste2', value: 2 },
                      ]}
                      variant={'right'}
                    /> */}
                  </div>
                  <div className={styles.box_buttons}>
                    {/* <div
                      className={styles.button_subtitle_remove}
                      onClick={() => deleteLegend(index)}
                    >
                      <Cross color={'#E40000'} width={'14px'} height={'14px'} />
                    </div> */}
                    <div
                      className={styles.button_subtitle_refresh}
                      onClick={() => legendUpdate(index)}
                    >
                      <Pen2 color="#000" width={'24px'} height={'24px'} />
                    </div>
                    <div
                      className={styles.button_subtitle_refresh}
                      onClick={() => legendGenerate(index, 'regenerate')}
                    >
                      <WhiteRefresh width={'24px'} height={'24px'} />
                    </div>
                    {/* <div
                    className={styles.button_subtitle_accept}
                    onClick={() => confirmLegend(index)}
                  >
                    <Accept color={'#0FBE00'} width={'16px'} height={'16px'} />
                  </div> */}
                  </div>
                </>
              )}
              <div className={styles.box_description_publication}>
                {/* <p className={styles.description_publication}>{legend}</p> */}

                <textarea
                  // className={
                  //   !activeImputLegend[index]
                  //     ? styles.input_legend_readOnly
                  //     : styles.input_legend
                  // }
                  // readOnly={!activeImputLegend[index]}
                  className={
                    openEdit
                      ? styles.input_legend_readOnly
                      : styles.input_legend
                  }
                  readOnly={openEdit}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  onChange={(e) => handleLegendChange(e.target.value, index)}
                  value={legendList[index] || ''}
                />

                {/* <p className={styles.description_publication}>
                {legendList[index]}
              </p> */}
              </div>
            </div>
          )
        })}
      </div>
      <div className={styles.button_nav}>
        <button
          className={styles.button_back}
          onClick={() => {
            nextButton('back')
          }}
        >
          <p className={styles.button_title}>{t('stepFourthPass.button.back')}</p>
        </button>

        <button
          className={styles.button_next}
          onClick={() => {
            nextButton('next')
          }}
        >
          <p className={styles.button_title}>
            {t('stepFourthPass.button.next')}
          </p>
        </button>
      </div>
    </motion.div>
  )
}

export default StepFourtPass
