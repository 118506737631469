/* ## Hooks ## */
import { useEffect, useRef, useState } from 'react'

/* ## Components ## */
import { SelectRound } from '../../../components/Select/SelectRound'
import InputSearch from '../../../components/InputSearch'
import NincheCard from '../../../components/NincheCard'

/* ## Styles ## */
import styles from './styles.module.css'

/* ## Services ## */
import { postGenerateMenu } from '../../../services/pythonService'

/* ## Mockups ## */
import { filterParams, generateMenuBody } from './mockups'

/* ## Libraries ## */
import PropTypes from 'prop-types'
import {
  EditorialLineService,
  ProductService,
  PythonService,
} from '../../../services'
import { useClient } from '../../../hooks/useClient'
import { useTranslation } from 'react-i18next'

function StepNinche({
  setStep,
  setNincheData,
  userNiche,
  setTemplateId,
  productRef,
}) {
  const { t } = useTranslation()
  const { clientData } = useClient()
  const [ninches, setNinches] = useState([])
  const [selectedFilterParams, setSelectedFilterParams] = useState()
  const [filteredNinches, setFilteredNinches] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ])
  const [currentQueueIndex, setCurrentQueueIndex] = useState(null)
  const isProcessingRef = useRef(false)

  const [editorialLineLocal, setEditorialLineLocal] = useState(null)

  const [idTemplateRemove, setIdTemplateRemove] = useState([])

  const searchNinches = (searchTerm) => {
    const filtered = ninches.filter(
      (ninche) =>
        (ninche.ProductName &&
          ninche.ProductName.toLowerCase().includes(
            searchTerm.toLowerCase()
          )) ||
        (ninche.VariantSelected &&
          ninche.VariantSelected.toLowerCase().includes(
            searchTerm.toLowerCase()
          )) ||
        (ninche.tipo &&
          ninche.tipo.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    setFilteredNinches(filtered)
  }

  const handleChangeFilterParams = (selectedFilters) => {
    const updatedFilterParams = {
      ...selectedFilterParams,
      ...selectedFilters,
    }
    // Remove filters where the value is 'all'
    Object.keys(updatedFilterParams).forEach((key) => {
      if (updatedFilterParams[key] === 'all') {
        delete updatedFilterParams[key]
      }
    })
    setSelectedFilterParams(updatedFilterParams)

    const filtered = ninches.filter((ninche) => {
      const checkFilter = (filterKey, nincheKey) => {
        const nincheValue = ninche[nincheKey]
        if (!updatedFilterParams[filterKey]) return true
        if (Array.isArray(nincheValue)) {
          return nincheValue.some((value) =>
            updatedFilterParams[filterKey]?.includes(value)
          )
        }
        return updatedFilterParams[filterKey].includes(nincheValue)
      }

      return (
        checkFilter('complexity', 'Complexidade') &&
        checkFilter('effects', 'Features Visuais') &&
        checkFilter('funil', 'Funil')
      )
    })

    setFilteredNinches(filtered)
  }

  const processQueue = async (index) => {
    if (index !== null && !isProcessingRef.current) {
      const { data: dataImageProduct } = await ProductService.getProductImage64(
        productRef?.current?.value
      )

      const products = await ProductService.getProductByNotBusinessClient()

      const productsValue = products?.data

      const imgBase64AllImages =
        productsValue[Math.floor(Math.random() * (productsValue.length - 1))]

      const { data } = await ProductService.getProductImage64(
        imgBase64AllImages?.uuid
      )

      const imgBase64 = []

      data.forEach((image) => {
        imgBase64.push({
          base64: image.fileBase64,
          imageCategory: 'Principal',
          productId: image.productId,
          productName: image.productName,
          productDescription: image.description,
          productValue: image.value,
          productPromotionValue: image.promotionalValue,
        })
      })

      const imageRandon = dataImageProduct[0]?.fileBase64
        ? dataImageProduct[0]?.fileBase64
        : imgBase64[0]?.base64

      isProcessingRef.current = true
      const generateMenuBodyResponse = await generateMenuBody(
        userNiche,
        imageRandon,
        idTemplateRemove,
        editorialLineLocal,
        clientData,
        index
      )

      postGenerateMenu(generateMenuBodyResponse)
        .then(({ data: { data } }) => {
          if (data.length === 0) {
            let newFilteredNinches = filteredNinches.filter(
              (item) => item?.id !== undefined
            )

            setFilteredNinches(newFilteredNinches)

            return
          }

          setNinches([...ninches, data[0]])
          setFilteredNinches((prevState) => {
            const newState = [...prevState]
            newState[index] = data[0]
            return newState
          })

          setIdTemplateRemove((prevState) => [
            ...prevState,
            data[0]?.templateId,
          ])
          isProcessingRef.current = false
          setCurrentQueueIndex(null) // Reset to allow the effect to re-trigger
        })
        .catch(() => {
          isProcessingRef.current = false
          setCurrentQueueIndex(null)
        })
    }
  }

  useEffect(() => {
    if (editorialLineLocal !== null && currentQueueIndex !== null) {
      processQueue(currentQueueIndex)
    } else {
      if (currentQueueIndex !== null) {
        processEditorialLine()
      }

      const emptyIndex = filteredNinches.findIndex(
        (obj) => Object.keys(obj).length === 0
      )
      if (emptyIndex !== -1) {
        setCurrentQueueIndex(emptyIndex)
      }
    }
  }, [editorialLineLocal, currentQueueIndex])
  // }, [filteredNinches, currentQueueIndex])

  const processEditorialLine = async () => {
    const getEditorialLine =
      await EditorialLineService.getEditorialLinebyLogin()
    const editorialLine = getEditorialLine?.data[0]?.bodyJson

    const bodyEditorial = {
      flow: 'editorial_line',
      prompt: 'calendary',
      brand: editorialLine?.dados_da_marca?.nicho,
      editorial_line: {
        primary: {
          titulo: editorialLine?.linha_conteudo_principal?.titulo,
          descricao: editorialLine?.linha_conteudo_principal?.descricao,
        },
        secondary: [
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[1]?.titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[1]?.descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1]?.exemplos_conteudo,
          },
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[2]?.titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[2]?.descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1]?.exemplos_conteudo,
          },
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[3]?.titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[3]?.descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1]?.exemplos_conteudo,
          },
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[4]?.titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[4]?.descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1]?.exemplos_conteudo,
          },
        ],
      },
      niche: editorialLine?.dados_da_marca.nicho,
      subniche: editorialLine?.dados_da_marca.subnicho,
      business_description: editorialLine?.dados_da_marca?.descricao_negocio,
      target_public: {
        name: editorialLine?.persona.nome,
        gender: editorialLine?.persona.genero,
        social_class: 'Classes A, Classe B',
        profession: editorialLine?.persona.cargo,
        region: 'Nacional, com foco em grandes centros urbanos',
      },
      product: {
        name: productRef?.current?.name || '',
        type: productRef?.current?.productTypeValue || '',
        description: productRef?.current?.description || '',
        value: productRef?.current?.price || '',
        promotional_value: productRef?.current?.promotionalValue || '',
      },
      voice_tone: editorialLine?.tom_de_voz,
      voice_tone_description: editorialLine?.tipo_post,
    }
    let retEditorialLine = await PythonService.postPromptGPT(bodyEditorial)
    setEditorialLineLocal(retEditorialLine)

    // editorialLineGenerate = retEditorialLine
    // setEditorialLineGenerate(retEditorialLine)
  }

  const loadMore = () => {
    setFilteredNinches((prevState) => [...prevState, ...Array(6).fill({})])
  }

  return (
    <div className={styles.page}>
      <div className={styles.container_ninches}>
        <div className={styles.title_ninches}>
          <p className={styles.label_page}>
            {t('new_publication_page_campaign_step_ninches_label_page')}
          </p>
          <p className={styles.title_page}>
            {t('new_publication_page_campaign_step_ninches_title_page')}
          </p>
        </div>
        <div className={styles.container_filter_form}>
          <InputSearch
            placeholder={t('new_publication_page_campaign_step_ninches_search_placeholder')}
            invertIconPosition={true}
            onChange={(e) => searchNinches(e.target.value)}
          />
          <div className={styles.select_group}>
            <div className={styles.filter_form_select}>
              <SelectRound
                changeBackgroundColor="#EDEDED"
                placeholder={t('new_publication_page_campaign_step_ninches_filter_funil_placeholder')}
                options={filterParams.funil}
                onSelectItem={(selected) => {
                  handleChangeFilterParams({ funil: selected.value })
                }}
              />
            </div>
            <div className={styles.filter_form_select}>
              <SelectRound
                changeBackgroundColor="#EDEDED"
                placeholder={t('new_publication_page_campaign_step_ninches_filter_complexity_placeholder')}
                options={filterParams.complexity}
                onSelectItem={(selected) => {
                  handleChangeFilterParams({ complexity: selected.value })
                }}
              />
            </div>
            <div className={styles.filter_form_select}>
              <SelectRound
                changeBackgroundColor="#EDEDED"
                placeholder={t('new_publication_page_campaign_step_ninches_filter_effects_placeholder')}
                options={filterParams.effects}
                onSelectItem={(selected) => {
                  handleChangeFilterParams({ effects: selected.value })
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.list_niches_container}>
          <div className={styles.list_niches_cards_container}>
            {console.log('326, ninche', filteredNinches)}
            {filteredNinches.map((ninche, index) => (
              <div
                key={index}
                onClick={() => {
                  if (!Object.keys(ninche).length) return
                  setNincheData(ninche)
                  setStep('stepImage')
                  setTemplateId(ninche.templateId)
                }}
              >
                <NincheCard
                  values={
                    !Object?.keys(ninche)?.length
                      ? {}
                      : {
                          category: ninche.Categoria ?? (
                            <s>Product name not found</s>
                          ),
                          modelName: ninche.VariantSelected,
                          description: ninche.Descricao,
                          imageUrl: ninche.srcComTratativa,
                        }
                  }
                />
              </div>
            ))}
          </div>
          {console.log('280 ===> ', filteredNinches)}
          {filteredNinches.length === 12 ? (
            <div className={styles.show_more_content}>
              <button
                className={styles.moreOptions}
                disabled={
                  !(
                    filteredNinches.findIndex(
                      (obj) => Object.keys(obj).length === 0
                    ) === -1
                  )
                }
                onClick={loadMore}
              >
                {t('new_publication_page_campaign_step_ninches_show_more_button')}
              </button>
            </div>
          ) : (
            <div className={styles.empty_list_warning}></div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StepNinche

StepNinche.propTypes = {
  setStep: PropTypes.func,
  setNincheData: PropTypes.func,
}
