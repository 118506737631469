/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import { useEffect, useState, useRef } from 'react'

import styles from './styles.module.css'

import { isObjEqual } from '../../utils/verifyObject'

import { InterrogationCircleIcon } from '../../assets/svgs/icons'

import { BrandingArchetypeService, BusinessService } from '../../services'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { ButtonNavigateStep } from '../../components/ButtonNavigateStep'
import { useNavigate } from 'react-router-dom'
import { notifyErrorCustom } from '../../utils/notify'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepBrandArchetype({ setStep, clientData }) {
  const { t } = useTranslation()
  const [sliderLayers, setSliderLayers] = useState([0, 20])
  const [brandingArchetypeData, setBrandingArchetypeData] = useState({})
  const [brandingArchetypeDataReference, setBrandingArchetypeDataReference] =
    useState({})

  const businessId = useRef(undefined)

  const navigation = useNavigate()

  const handleChange = (k, v) => {
    setBrandingArchetypeData((prevState) => ({ ...prevState, [k]: v }))
  }

  useEffect(() => {
    loadExistentBrandingArchetype()
  }, [])

  const handleSlider = (e) => {
    setSliderLayers(e)
  }

  const loadExistentBrandingArchetype = async () => {
    if (clientData?.uuid) {
      const { data: data2, responseStatus: responseStatus2 } =
        await BusinessService.getBusinessByClientId(clientData?.uuid)

      businessId.current = data2[0]?.uuid

      const { data, responseStatus } =
        await BrandingArchetypeService.getBrandingArchetypeByBusinessId(
          data2[0]?.uuid
        )

      if (responseStatus === 200 && data) {
        setBrandingArchetypeData(data)
        setBrandingArchetypeDataReference(data)
      }

      businessId.current = data2[0]?.uuid
    } else {
      const { data, responseStatus } =
        await BrandingArchetypeService.getBrandingArchetypeByBusiness()
      if (responseStatus === 200 && data) {
        setBrandingArchetypeData(data[0])
        setBrandingArchetypeDataReference(data[0])
      }
    }
  }

  const dataScheema = yup.object().shape({
    brandValuesAndMission: yup
      .string()
      .required(t('step_brand_archetype.brand_values_and_mission_required')),
    brandCompetitors: yup
      .string()
      .required(t('step_brand_archetype.brand_competitors_required')),
    brandCommunicationStyle: yup
      .string()
      .required(t('step_brand_archetype.brand_communication_style_required')),
    brandHistoryAndPurpose: yup
      .string()
      .required(t('step_brand_archetype.brand_history_and_purpose_required')),
  })

  const validateFields = async (dataToValidate) => {
    return await dataScheema
      .validate(dataToValidate)
      .then(() => {
        return true
      })
      .catch((error) => {
        notifyErrorCustom(error.message)
        return false
      })
  }

  const handleSubmit = async () => {
    const isValid = await validateFields(brandingArchetypeData)
    if (!isValid) return

    const body = {
      ...brandingArchetypeData,
    }

    if (
      body?.uuid &&
      !isObjEqual(brandingArchetypeDataReference, brandingArchetypeData)
    ) {
      const { uuid, ...rest } = body

      delete rest.businessId
      Object.keys(rest).map((e) => {
        if (
          e === 'updatedAt' ||
          e === 'createdAt' ||
          e === 'businessId' ||
          e === 'deletedAt'
        )
          delete rest[e]
      })

      rest.layerMax = 0
      rest.layerMin = 0
      await BrandingArchetypeService.putBrandingArchetype(uuid, rest)
    } else if (body && !body.uuid) {
      const bpsFemaleOrMale = body?.bpsFemaleOrMale || 0
      const bpsSimpleOrComplex = body?.bpsSimpleOrComplex || 0
      const bpsSubtleOrExtravagant = body?.bpsSubtleOrExtravagant || 0
      const bpsClassicOrModern = body?.bpsClassicOrModern || 0
      const bpsFriendlyOrDistant = body?.bpsFriendlyOrDistant || 0
      const bpsPlayfulOrSerious = body?.bpsPlayfulOrSerious || 0
      const bpsNaturalOrIndustrial = body?.bpsNaturalOrIndustrial || 0
      const bpsPopularOrElite = body?.bpsPopularOrElite || 0
      const bpsStrippedOrSophisticated = body?.bpsStrippedOrSophisticated || 0
      const bpsRusticOrRefined = body?.bpsRusticOrRefined || 0

      if (clientData?.uuid) {
        await BrandingArchetypeService.postArchetypeByLogin({
          ...body,

          bpsFemaleOrMale,
          bpsSimpleOrComplex,
          bpsSubtleOrExtravagant,
          bpsClassicOrModern,
          bpsFriendlyOrDistant,
          bpsPlayfulOrSerious,
          bpsNaturalOrIndustrial,
          bpsPopularOrElite,
          bpsStrippedOrSophisticated,
          bpsRusticOrRefined,

          businessId: businessId.current,
          layerMin: 0,
          layerMax: 0,
        })
      } else {
        await BrandingArchetypeService.postBrandingArchetype({
          ...body,

          bpsFemaleOrMale,
          bpsSimpleOrComplex,
          bpsSubtleOrExtravagant,
          bpsClassicOrModern,
          bpsFriendlyOrDistant,
          bpsPlayfulOrSerious,
          bpsNaturalOrIndustrial,
          bpsPopularOrElite,
          bpsStrippedOrSophisticated,
          bpsRusticOrRefined,

          layerMin: 0,
          layerMax: 0,
        })
      }
    }

    //setStep(STEPS.STEP_VISUAL_PREFERENCES)
    navigation('/LoginCompletion')
  }

  const SliderComponent = ({ value, onChange }) => {
    return (
      <div>
        <Slider
          value={value}
          onChange={onChange}
          min={-5}
          max={5}
          trackStyle={{ backgroundColor: '#28003F', height: 6 }}
          railStyle={{ backgroundColor: '#28003F', height: 6 }}
          handleStyle={{
            width: 15,
            height: 15,
            backgroundColor: '#B900FE',
            border: '2.5px solid #FFFF',
            boxShadow: 'none',
          }}
        />
      </div>
    )
  }

  const SliderValueComponent = ({
    leftText,
    rightText,
    sliderValue,
    setSliderValue,
  }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 20, width: 180 }}>
          <p
            style={{
              margin: 0,
              fontSize: 10,
              textAlign: 'end',
              color: '#FFFFFF',
            }}
          >
            {leftText}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 300,
          }}
        >
          {SliderComponent({
            value: sliderValue,
            onChange: setSliderValue,
          })}
        </div>

        <div style={{ marginLeft: 20, width: 180 }}>
          <p style={{ margin: 0, fontSize: 10, color: '#FFFFFF' }}>
            {rightText}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.page}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>{t('step_brand_archetype.title')}</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>
      <p className={styles.subtitle_text} style={{ fontSize: 12 }}>
        {t('step_brand_archetype.subtitle')}
      </p>
      <div className={styles.box_text_area}>
        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          {t('step_brand_archetype.brand_history_and_purpose')}
        </p>
        <textarea
          id="brandHistoryAndPurpose"
          style={{ height: 80, width: '100%' }}
          className={styles.input}
          placeholder={t('step_brand_archetype.brand_history_and_purpose_placeholder')}
          value={brandingArchetypeData?.brandHistoryAndPurpose}
          onChange={({ target: { id, value } }) => handleChange(id, value)}
        ></textarea>

        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          {t('step_brand_archetype.brand_communication_style')}
        </p>
        <textarea
          id="brandCommunicationStyle"
          style={{ height: 80, width: '100%' }}
          className={styles.input}
          placeholder={t('step_brand_archetype.brand_communication_style_placeholder')}
          value={brandingArchetypeData?.brandCommunicationStyle}
          onChange={({ target: { id, value } }) => handleChange(id, value)}
        ></textarea>

        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          {t('step_brand_archetype.brand_competitors')}
        </p>
        <textarea
          id="brandCompetitors"
          style={{ height: 80, width: '100%' }}
          className={styles.input}
          placeholder={t('step_brand_archetype.brand_competitors_placeholder')}
          value={brandingArchetypeData?.brandCompetitors}
          onChange={({ target: { id, value } }) => handleChange(id, value)}
        ></textarea>

        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          {t('step_brand_archetype.brand_values_and_mission')}
        </p>
        <textarea
          id="brandValuesAndMission"
          style={{ height: 80, width: '100%' }}
          className={styles.input}
          placeholder={t('step_brand_archetype.brand_values_and_mission_placeholder')}
          value={brandingArchetypeData?.brandValuesAndMission}
          onChange={({ target: { id, value } }) => handleChange(id, value)}
        ></textarea>
      </div>
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 6,
          marginTop: 20,
        }}
      >
        <p className={styles.title_text} style={{ fontSize: 16 }}>
          BPS (Brand Personality Slider)
        </p>
        <InterrogationCircleIcon color={'#fff'} width={20} height={20} />
      </div> */}

      {/* <p
        className={styles.subtitle_text}
        style={{ textAlign: 'center', padding: '0px 80px', fontSize: 12 }}
      >
        BPS é uma ferramenta gráfica que ajuda a definir a personalidade de uma
        marca de maneira simples e visualmente compreensível, permitindo uma
        comunicação consistente e eficaz com o público-alvo.
      </p> */}

      {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 330,
            gap: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <p className={styles.number_parameter}>5</p>
            <p className={styles.number_parameter}>4</p>
            <p className={styles.number_parameter}>5</p>
            <p className={styles.number_parameter}>2</p>
            <p className={styles.number_parameter}>1</p>

            <p className={styles.number_parameter}>0</p>

            <p className={styles.number_parameter}>1</p>
            <p className={styles.number_parameter}>2</p>
            <p className={styles.number_parameter}>3</p>
            <p className={styles.number_parameter}>4</p>
            <p className={styles.number_parameter}>5</p>
          </div>

          {SliderValueComponent({
            leftText: 'Feminino',
            rightText: 'Masculino',
            sliderValue: brandingArchetypeData?.bpsFemaleOrMale || 0,

            setSliderValue: (value) => handleChange('bpsFemaleOrMale', value),
          })}

          {SliderValueComponent({
            leftText: 'Simples',
            rightText: 'Complexo',
            sliderValue: brandingArchetypeData?.bpsSimpleOrComplex || 0,

            setSliderValue: (value) =>
              handleChange('bpsSimpleOrComplex', value),
          })}

          {SliderValueComponent({
            leftText: 'Sutil',
            rightText: 'Extravagante',
            sliderValue: brandingArchetypeData?.bpsSubtleOrExtravagant || 0,
            setSliderValue: (value) =>
              handleChange('bpsSubtleOrExtravagant', value),
          })}

          {SliderValueComponent({
            leftText: 'Clássico',
            rightText: 'Moderno',
            sliderValue: brandingArchetypeData?.bpsClassicOrModern || 0,
            setSliderValue: (value) =>
              handleChange('bpsClassicOrModern', value),
          })}

          {SliderValueComponent({
            leftText: 'Amigável',
            rightText: 'Distante',
            sliderValue: brandingArchetypeData?.bpsFriendlyOrDistant || 0,
            setSliderValue: (value) =>
              handleChange('bpsFriendlyOrDistant', value),
          })}

          {SliderValueComponent({
            leftText: 'Brincalhão',
            rightText: 'Sério',
            sliderValue: brandingArchetypeData?.bpsPlayfulOrSerious || 0,
            setSliderValue: (value) =>
              handleChange('bpsPlayfulOrSerious', value),
          })}

          {SliderValueComponent({
            leftText: 'Natural',
            rightText: 'Industrial',
            sliderValue: brandingArchetypeData?.bpsNaturalOrIndustrial || 0,
            setSliderValue: (value) =>
              handleChange('bpsNaturalOrIndustrial', value),
          })}

          {SliderValueComponent({
            leftText: 'Popular',
            rightText: 'Elitizado',
            sliderValue: brandingArchetypeData?.bpsPopularOrElite || 0,
            setSliderValue: (value) => handleChange('bpsPopularOrElite', value),
          })}

          {SliderValueComponent({
            leftText: 'Despojado',
            rightText: 'Sofisticado',
            sliderValue: brandingArchetypeData?.bpsStrippedOrSophisticated || 0,
            setSliderValue: (value) =>
              handleChange('bpsStrippedOrSophisticated', value),
          })}

          {SliderValueComponent({
            leftText: 'Rústico',
            rightText: 'Refinado',
            sliderValue: brandingArchetypeData?.bpsRusticOrRefined || 0,
            setSliderValue: (value) =>
              handleChange('bpsRusticOrRefined', value),
          })}
        </div>
      </div> */}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
          marginTop: 40,
          flex: 1,
        }}
      >
        {/* <p className={styles.title_text} style={{ fontSize: 16 }}>
          Camadas
        </p> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
            flex: 1,
            // background: 'blue',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {/* <p className={styles.subtitle_text} style={{ fontSize: 12 }}>
              As camadas desempenham um papel fundamental na definição do estilo
              e da quantidade de elementos visuais em um post. Os posts com
              poucas camadas possuem um estilo mais "minimalista", enquanto
              aqueles com várias camadas apresentam uma maior complexidade.
            </p>
            <div style={{ width: 260, marginTop: 30 }}>
              <Slider
                range
                allowCross={false}
                value={sliderLayers}
                onChange={(e) => handleSlider(e)}
                defaultValue={[0, 0]}
                min={1}
                max={32}
                trackStyle={{ backgroundColor: '#B900FE', height: 6 }}
                railStyle={{ backgroundColor: '#E6E6E6', height: 6 }}
                handleStyle={{
                  width: 15,
                  height: 15,
                  backgroundColor: '#B900FE',
                  border: '2.5px solid #FFFF',
                  boxShadow: 'none',
                }}
              />
              <div style={{ display: 'flex', marginTop: 20, gap: 10 }}>
                <input
                  className={styles.inputLayer}
                  value={brandingArchetypeData?.layerMin}
                  //     setSliderValue: (value) =>
                  // handleChange('bpsNaturalOrIndustrial', value)
                  onChange={(e) => console.log('teste 435', e)}
                />
                <p style={{ fontSize: 16, fontWeight: 500, color: '#8b8b8b' }}>
                  -
                </p>
                <input
                  className={styles.inputLayer}
                  value={brandingArchetypeData?.layerMax}
                  onChange={(value) => handleChange('layerMax', value)}
                />
              </div>
            </div> */}
          </div>

          {/* <div style={{ width: 240 }}>
            <img
              style={{ width: '100%', borderRadius: 20 }}
              src={
                'https://i.pinimg.com/564x/57/fc/eb/57fcebc8defbd9e1f49e9c4b09b26682.jpg'
              }
              alt="image"
            />
          </div> */}
        </div>
      </div>

      <div />

      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_PRODUCT)}
          variant={true}
        >
          {t('step_brand_archetype.back')}
        </ButtonNavigateStep>

        <ButtonNavigateStep onClick={() => handleSubmit()} variant={'next'}>
          {t('step_brand_archetype.next')}
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepBrandArchetype
