import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import {
  CalendarIcon,
  Clock,
  Clipboard,
  Trash,
  NavigateArrowLeft,
  NavigateArrowRigth2,
} from '../../assets/svgs/icons'
import { BusinessService, PublicationService } from '../../services'
import { useClient } from '../../hooks/useClient'
import { getBusinessByClientId } from '../../services/business'
import { useTranslation } from 'react-i18next'

export const CalendarPublication = ({
  setSelectObjectCalendar,
  dataPublication,
  isOpen,
  setIsOpen,
  setContentPublication,
  setLoading,
  loadData,
}) => {
  const { t } = useTranslation()

  const monthNames = [
    t('calendar.january'),
    'Fevereiro',
    t('calendar.march'),
    t('calendar.april'),
    t('calendar.may'),
    t('calendar.june'),
    t('calendar.july'),
    t('calendar.august'),
    t('calendar.september'),
    t('calendar.october'),
    t('calendar.november'),
    t('calendar.december'),
  ]

  const [currentDate, setCurrentDate] = useState(new Date()) // Get the current date
  const [selectedDates] = useState([]) // Store selected dates
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth())
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

  const today = new Date()

  function hadleShowPubication(item, date) {
    setSelectObjectCalendar(item)
    handleDateClick(date)
  }

  const togglePopup = (data, date) => {
    setIsOpen(!isOpen)
    setContentPublication(data)
    handleDateClick(date)
  }

  const truncateTitle = (title, maxLength) => {
    if (!title) {
      return ''
    }

    if (title.length > maxLength) {
      return title.slice(0, maxLength) + '...'
    }
    return title
  }

  const columnsRef = useRef([])

  useEffect(() => {
    const columns = columnsRef.current

    const handleDragStart = (e) => {
      e.target.classList.add('dragging')
    }

    const handleDragEnd = (e) => {
      e.target.classList.remove('dragging')
    }

    const handleDragOver = (e) => {
      e.preventDefault()
      const dragging = document.querySelector('.dragging')
      const applyAfter = getNewPosition(e.currentTarget, e.clientY)

      if (applyAfter) {
        applyAfter.insertAdjacentElement('afterend', dragging)
      } else {
        e.currentTarget.prepend(dragging)
      }
    }

    const getNewPosition = (column, posY) => {
      const cards = column.querySelectorAll('.item:not(.dragging)')
      let result

      for (let refer_card of cards) {
        const box = refer_card.getBoundingClientRect()
        const boxCenterY = box.y + box.height / 2

        if (posY >= boxCenterY) result = refer_card
      }

      return result
    }

    columns.forEach((column, index) => {
      column.addEventListener('dragover', handleDragOver)
      column.addEventListener('dragstart', handleDragStart)
      column.addEventListener('dragend', handleDragEnd)
      // column.addEventListener('drop', (e) => handleDrop(e, index)) // Passando o índice + 1 como a data do dia
    })
  }, [])

  const renderDays = () => {
    const days = []

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day)
      const isPastDate = date < today
      const isCurrentDate = date.getTime() === today.getTime()
      const isSelected = selectedDates.some(
        (selectedDate) => selectedDate.getTime() === date.getTime()
      )

      let contentReturn = findDate(date.getMonth() + 1, day, date.getFullYear())

      days.push(
        <div
          className={`${styles.day_box} ${
            isCurrentDate
              ? styles.current_day
              : date.getTime() === currentDate.getTime()
              ? styles.selected
              : isPastDate
              ? styles.disabled
              : ''
          }`}
          key={date.getTime()}
        >
          <div className={styles.container_day_box_date}>
            <div className={styles.day_box_date}>
              <p className={styles.day_box_date_day}>{day}</p>
            </div>
            <div
              className={styles.day_box_schedules}
              id={day}
              data-day={day}
              ref={(el) => (columnsRef.current[day] = el)}
            >
              {contentReturn &&
              contentReturn?.content &&
              contentReturn?.content?.length > 0 &&
              contentReturn?.content?.length < 3
                ? contentReturn?.content?.map((item, key) => (
                    <div
                      key={key}
                      className={styles.schedule_of_the_day}
                      style={{
                        backgroundColor:
                          item?.status === 'Sugestão de Publicação'
                            ? '#190027'
                            : item.status === 'Publicado'
                            ? '#3CBC00'
                            : '#ff8b00',
                      }}
                      onClick={() => hadleShowPubication(item, date)}
                    >
                      {item?.status === 'Publicado' ? (
                        <Clipboard width={'15px'} height={'15px'} />
                      ) : item?.status === 'Cancelado' ? (
                        <Trash width={'15px'} height={'15px'} />
                      ) : (
                        <Clock width={'15px'} height={'15px'} />
                      )}

                      <p
                        style={{
                          display: 'flex',
                          gap: 5,
                          cursor: 'pointer',
                          fontSize: 10,
                          margin: 0,
                          color:
                            item?.status === 'Sugestão de Publicação'
                              ? '#a1a0a0'
                              : '#fff',
                        }}
                      >
                        {truncateTitle(
                          item?.title
                            ? item?.title
                            : item?.videoUrl
                            ? 'Vídeo Próprio'
                            : 'Publicação Própria',
                          14
                        )}
                      </p>
                    </div>
                  ))
                : contentReturn &&
                  contentReturn?.content &&
                  contentReturn?.content?.length >= 3 && (
                    <div
                      className={styles.schedule_of_the_day}
                      style={{
                        cursor: 'pointer',
                        background: isSelected ? '#FF8B00' : '#fc036b',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      onClick={() => togglePopup(contentReturn?.content, date)}
                    >
                      <div className={styles.box_schedule_of_the_day}>
                        <CalendarIcon width={'12px'} height={'12px'} />
                        <p className={styles.title_schedule_of_the_day}>
                          Ver mais
                        </p>
                      </div>
                      <p className={styles.title_schedule_of_the_day_plus}>
                        +3
                      </p>
                    </div>
                  )}
            </div>
          </div>
        </div>
      )
    }

    return days
  }

  const renderDaysLastMonth = () => {
    const daysLast = Array.from({
      length: new Date(currentYear, currentMonth, 1).getDay(),
    })
      .map((_, index) => {
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1
        const lastYear = currentMonth === 0 ? currentYear - 1 : currentYear
        const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate()
        const dayOfMonth = lastDayOfMonth - index

        const isSelected = selectedDates.some(
          (selectedDate) => selectedDate.getTime() === date.getTime()
        )

        const date = new Date(lastYear, lastMonth, dayOfMonth)
        let contentReturn = findDate(lastMonth + 1, dayOfMonth, lastYear)

        return (
          <div
            key={`empty-${index}`}
            className={`${styles.empty_day} ${styles.disabled}`}
          >
            <div className={styles.container_day_box_date}>
              <div className={styles.day_box_date}>
                <p className={styles.day_box_date_day}>{dayOfMonth}</p>
              </div>
            </div>
            {contentReturn?.content &&
            contentReturn?.content?.length > 0 &&
            contentReturn?.content?.length < 3
              ? contentReturn?.content.map((item, key) => (
                  <div
                    key={key}
                    className={styles.schedule_of_the_day}
                    style={{
                      backgroundColor:
                        item?.status === 'Sugestão de Publicação'
                          ? '#190027'
                          : '#ff8b00',
                      opacity: '30%',
                    }}
                    onClick={() => hadleShowPubication(item, date)}
                  >
                    <Clock width={'15px'} height={'15px'} />
                    <p
                      style={{
                        display: 'flex',
                        gap: 5,
                        cursor: 'pointer',
                        fontSize: 10,
                        margin: 0,
                        color:
                          item?.status === 'Sugestão de Publicação'
                            ? '#a1a0a0'
                            : '#fff',
                      }}
                    >
                      {truncateTitle(
                        item?.title
                          ? item?.title
                          : item?.videoUrl
                          ? 'Vídeo Próprio'
                          : 'Publicação Própria',
                        14
                      )}
                    </p>
                  </div>
                ))
              : contentReturn?.content?.length >= 3 && (
                  <div
                    className={styles.schedule_of_the_day}
                    style={{
                      cursor: 'pointer',
                      background: isSelected ? '#FF8B00' : '#3CBC001A',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => togglePopup(contentReturn.content, date)}
                  >
                    <div className={styles.box_schedule_of_the_day}>
                      <CalendarIcon width={'12px'} height={'12px'} />
                      <p className={styles.title_schedule_of_the_day}>
                        Ver mais
                      </p>
                    </div>
                    <p className={styles.title_schedule_of_the_day_plus}>+3</p>
                  </div>
                )}
          </div>
        )
      })
      .reverse()

    return daysLast
  }

  const renderDaysNextMonth = () => {
    const daysNext = Array.from({
      length: 6 - new Date(currentYear, currentMonth, daysInMonth).getDay(),
    }).map((_, index) => {
      const dayOfMonth = index + 1
      const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1
      const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear
      const date = new Date(nextYear, nextMonth, dayOfMonth)
      let contentReturn = findDate(
        nextMonth + 1, // Adicione 1 ao mês atual para obter o próximo mês
        dayOfMonth,
        nextYear
      )

      return (
        <div
          key={`empty-${daysInMonth + index}`}
          className={`${styles.empty_day} ${styles.disabled}`}
        >
          <div className={styles.container_day_box_date}>
            <div className={styles.day_box_date}>
              <p className={styles.day_box_date_day}>{dayOfMonth}</p>
            </div>
          </div>

          {contentReturn?.content?.length > 0 &&
          contentReturn?.content?.length < 3
            ? contentReturn?.content.map((item, key) => (
                <div
                  key={key}
                  className={styles.schedule_of_the_day}
                  style={{
                    backgroundColor:
                      item?.status === 'Sugestão de Publicação'
                        ? '#190027'
                        : '#ff8b00',
                    opacity: '30%',
                  }}
                  onClick={() => hadleShowPubication(item, date)}
                >
                  <Clock width={'15px'} height={'15px'} />
                  <p
                    key={item?.id}
                    style={{
                      display: 'flex',
                      gap: 5,
                      cursor: 'pointer',
                      fontSize: 10,
                      margin: 0,
                      color:
                        item?.status === 'Sugestão de Publicação'
                          ? '#a1a0a0'
                          : '#fff',
                    }}
                  >
                    {truncateTitle(
                      item?.title
                        ? item?.title
                        : item?.videoUrl
                        ? 'Vídeo Próprio'
                        : 'Publicação Própria',
                      14
                    )}
                  </p>
                </div>
              ))
            : contentReturn?.content?.length >= 3 && (
                <div
                  className={styles.schedule_of_the_day}
                  style={{
                    cursor: 'pointer',
                    background: isSelected ? '#FF8B00' : '#3CBC001A',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => togglePopup(contentReturn?.content, date)}
                >
                  <div className={styles.box_schedule_of_the_day}>
                    <CalendarIcon width={'12px'} height={'12px'} />
                    <p className={styles.title_schedule_of_the_day}>Ver mais</p>
                  </div>
                  <p className={styles.title_schedule_of_the_day_plus}>+3</p>
                </div>
              )}
        </div>
      )
    })

    return daysNext
  }

  function handleNextMonth() {
    if (currentMonth === 11) {
      setCurrentMonth(0)
      setCurrentYear(currentYear + 1)

      setLoading(true)
      loadData(0, currentYear + 1)
    } else {
      setCurrentMonth(currentMonth + 1)

      setLoading(true)
      loadData(currentMonth + 1, currentYear)
    }
  }

  function handlePreviusMonth() {
    if (currentMonth === 0) {
      setCurrentMonth(11)
      setCurrentYear(currentYear - 1)

      setLoading(true)
      loadData(11, currentYear - 1)
    } else {
      setCurrentMonth(currentMonth - 1)

      setLoading(true)
      loadData(currentMonth - 1, currentYear)
    }
  }

  function handleDateClick(date) {
    if (date >= today) {
      setCurrentDate(date)
    }
  }

  const findDate = (mes, dia, ano) => {
    let date = mes + '-' + dia + '-' + ano

    let verifyDate = dataPublication?.find(
      (dateData) => dateData?.date === date
    )

    if (!verifyDate) {
      return []
    }

    return verifyDate
  }

  const HeaderCalendar = () => {
    return (
      <>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>D</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>S</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>T</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>Q</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>Q</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>S</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>S</p>
        </div>
      </>
    )
  }

  return (
    <div className={styles.calendar_wrapper}>
      <div className={styles.calendar_header}>
        <div>
          <h3 className={styles.calendar_header_name}>
            {monthNames[currentMonth] + ' ' + currentYear}
          </h3>
        </div>
        <div className={styles.calendar_button_navigate}>
          <div
            onClick={handlePreviusMonth}
            style={{ color: '#000', cursor: 'pointer' }}
          >
            <NavigateArrowLeft />
          </div>
          <div
            onClick={handleNextMonth}
            style={{ color: '#000', cursor: 'pointer' }}
          >
            <NavigateArrowRigth2 />
          </div>
        </div>
      </div>
      <div className={styles.calendar}>
        {HeaderCalendar()}

        {renderDaysLastMonth()}
        {renderDays()}
        {renderDaysNextMonth()}
      </div>
    </div>
  )
}
