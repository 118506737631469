import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ptBRJson from './translations/ptBR.json'
import enJson from './translations/en.json'


const resources = {
    en: enJson,
    ptBR: ptBRJson
  };
  
  // Verifica se existe uma linguagem salva no localStorage
  const savedLanguage = localStorage.getItem('i18nextLng');
  const defaultLanguage = savedLanguage || 'ptBR';
  
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: defaultLanguage, // Usa a linguagem salva ou o valor padrão
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
  
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
  
    export default i18n;
