import { MagnifyingGlass } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { useState } from 'react'

function InputSearch({
  placeholder,
  style,
  value,
  onChange,
  onSearch,
  invertIconPosition,
}) {
  return (
    <div
      className={styles.input_box}
      style={{ backgroundColor: style?.backgroundColor }}
    >
      <input
        style={style}
        className={!invertIconPosition ? styles.input : styles.inverted_input}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div
        className={
          !invertIconPosition
            ? styles.button_suggestions
            : styles.inverted_button_suggestions
        }
        onClick={onSearch}
      >
        <MagnifyingGlass color={'#E6E6E6'} />
      </div>
    </div>
  )
}

export default InputSearch
